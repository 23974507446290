import { useStyles } from '@archipro-design/aria';
import Block from './Block';
import BaseSection from '../base-section/BaseSection';
import EcommerceHeading from '../ecommerce-heading/EcommerceHeading';
import * as S from '../product-editing-module/ProductEditingModule.style';
import type { BusinessModuleProps } from '@modules/elemental-page/type';

const ProductEditingModule = ({
    intro,
    textBlocks,
    anchor,
}: BusinessModuleProps) => {
    const { css } = useStyles();

    return (
        <BaseSection style={S.Container} id={anchor}>
            {intro && (
                <EcommerceHeading
                    title={intro}
                    style={S.CustomHeading}
                    color={'black'}
                />
            )}
            {textBlocks && textBlocks.length > 0 && (
                <div className={css(S.BlocksContainer)}>
                    {textBlocks.map((block, index) => {
                        if (!block) {
                            return null;
                        }
                        return <Block key={index} {...block} />;
                    })}
                </div>
            )}
        </BaseSection>
    );
};

export default ProductEditingModule;

import type { StyleRule } from '@archipro-design/aria';
import { paragraphClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Container = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
};

export const DesktopParagraph: StyleRule = ({ theme }) => {
    return {
        fontSize: theme.siteVariables.fontSizes.subheading01,
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        lineHeight: pxToRem(44),
        whiteSpace: 'pre-wrap',
    };
};

export const DesktopImageStyle: StyleRule = ({ theme }) => {
    return {
        display: 'none',
        [theme.screen.tablet]: {
            display: 'block',
        },
    };
};

export const MobileImageStyle: StyleRule = ({ theme }) => {
    return {
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

export const DesktopParagraphContainer: StyleRule = ({ theme }) => {
    return {
        display: 'none',
        [theme.screen.laptop]: {
            display: 'block',
            marginBottom: pxToRem(40),
            maxWidth: pxToRem(768),
            '> p': {
                letterSpacing: theme.siteVariables.letterSpacingMedium,
                fontSize: theme.siteVariables.fontSizes.subheading01,
                lineHeight: pxToRem(44),
                fontWeight: 500,
            },
        },
    };
};

export const MobileParagraphContainer: StyleRule = ({ theme }) => {
    return {
        marginTop: pxToRem(11),
        maxWidth: pxToRem(339),

        [`& .${paragraphClassName}`]: {
            fontSize: theme.siteVariables.fontSizes.caption03,
            lineHeight: pxToRem(20),
            whiteSpace: 'pre-wrap',
        },

        [theme.screen.tablet]: {
            maxWidth: 'unset',
        },
        [theme.screen.laptop]: {
            display: 'none',
        },
    };
};

import WelcomeModule from '@modules/elemental-page/component/welcome-module/WelcomeModule';
import IntroModule from '@modules/elemental-page/component/intro-module/IntroModule';
import ProductEditingModule from '@modules/elemental-page/component/product-editing-module/ProductEditingModule';
import BusinessModule from '@modules/elemental-page/component/business-section/BusinessSection';
import FeatureShowcaseModule from '@modules/elemental-page/component/feature-showcase-module/FeatureShowcaseModule';
import FeaturesModule from '@modules/elemental-page/component/features-module/FeaturesModule';
import TestimonialModule from '@modules/elemental-page/component/testimonial-module/TestimonialModule';
import CallToActionModule from '@modules/elemental-page/component/call-to-action-module/CallToActionModule';
import SimpleCTAModule from '@modules/elemental-page/component/call-to-action-module/SimpleCTAModule';
import LogoCarouselModule from '@modules/elemental-page/component/logo-carousel-module/LogoCarouselModule';
import BannerModule from '@modules/elemental-page/component/banner-module/BannerModule';
import IframeEmbedModule from '@modules/elemental-page/component/iframe-embed-module/IframeEmbedModule';
import HeadingModule from '@modules/elemental-page/component/heading-module/HeadingModule';
import VideoModule from '@modules/elemental-page/component/video-module/VideoModule';
import PhotoCollageModule from '@modules/elemental-page/component/photo-collage-module/PhotoCollageModule';
import RichTextModule from '@modules/elemental-page/component/rich-text-module/RichTextModule';
import type { CommonModule } from '@modules/elemental-page/type';
import { ModulePageThemeEnum } from '../../../../../generated/graphql';
import FramedPictureContentModule from '@modules/elemental-page/component/framed-picture-content-module/FramedPictureContentModule';
import ImageCarouselModule from '@modules/elemental-page/component/image-carousel-module/ImageCarouselModule';
import AboutTestimonialModule from '@modules/elemental-page/component/about-tesimonial-module/AboutTestimonialModule';
import FramedPictureCarouselModule from '@modules/elemental-page/component/framed-picture-carousel-module/FramedPictureCarouselModule';
import DirectoryModule from '@modules/elemental-page/component/directory-module/DirectoryModule';
import { useStyles } from '@archipro-design/aria';
import { ModuleContainer } from './ElementalModule.style';
import ImageTextCarouselModule from '@modules/elemental-page/component/image-text-carousel-module/ImageTextCarouselModule';
import ProfessionalMembersModule from '@modules/elemental-page/component/professional-members-module/ProfessionalMembersModule';
import AccordionModule from '@modules/elemental-page/component/accordion-module/AccordionModule';
import ProjectFeaturesModule from '@modules/elemental-page/component/project-feature-module/ProjectFeaturesModule';
import GuestHomeHeroModule from '@modules/elemental-page/component/guest-home-hero-module/GuestHomeHeroModule';
import MilestoneModule from '@modules/elemental-page/component/milestone-module/MilestoneModule';
import CaptionCTAModule from '@modules/elemental-page/component/caption-cta-module/CaptionCTAModule';
import ContactUsMapModule from '@modules/elemental-page/component/contact-us-map-module/ContactUsMapModule';
import HostedVideoModule from '@modules/elemental-page/component/video-module/HostedVideoModule';
import CampaignHeaderModule from '../campaign-header-module/CampaignHeaderModule';
import CampaignFormFooterModule from '../campaign-form-footer-module/CampaignFormFooterModule';

const ElementalModule = ({
    item,
    pageTheme,
}: {
    item: CommonModule;
    pageTheme?: ModulePageThemeEnum;
}) => {
    const { css } = useStyles({
        spacingBelow: item.spacingBelow,
        spacingBelowMobile: item.spacingBelowMobile,
    });

    const moduleElement = () => {
        switch (item.type) {
            case 'EcomWelcomeModule':
                return <WelcomeModule {...item} key={item.id} />;
            case 'IntroModule':
                return <IntroModule {...item} key={item.id} />;
            case 'BusinessModule':
                if (item.textBlocks?.length === 4) {
                    return <ProductEditingModule {...item} key={item.id} />;
                }
                return <BusinessModule {...item} key={item.id} />;
            case 'FeatureShowcaseModule':
                return <FeatureShowcaseModule {...item} key={item.id} />;
            case 'FeaturesModule':
                return <FeaturesModule {...item} key={item.id} />;
            case 'EcomTestimonialModule':
                if (pageTheme === ModulePageThemeEnum.About) {
                    return <AboutTestimonialModule {...item} key={item.id} />;
                } else {
                    return <TestimonialModule {...item} key={item.id} />;
                }
            case 'EcomCallToActionModule':
                if (pageTheme === ModulePageThemeEnum.Home) {
                    return <SimpleCTAModule {...item} key={item.id} />;
                } else {
                    return <CallToActionModule {...item} key={item.id} />;
                }
            case 'LogoCarouselModule':
                return <LogoCarouselModule {...item} key={item.id} />;
            case 'BannerModule':
                return <BannerModule {...item} key={item.id} />;
            case 'IframeEmbedModule':
                return <IframeEmbedModule {...item} key={item.id} />;
            case 'HeadingTextModule':
                return <HeadingModule {...item} key={item.id} />;
            case 'VideoModule':
                return (
                    <VideoModule
                        {...item}
                        fullWidth={pageTheme === ModulePageThemeEnum.Home}
                        key={item.id}
                    />
                );
            case 'HostedVideoModule':
                return <HostedVideoModule {...item} key={item.id} />;
            case 'PhotoCollageModule':
                return <PhotoCollageModule {...item} key={item.id} />;
            case 'RichTextModule':
                return <RichTextModule {...item} key={item.id} />;
            case 'FramedPictureContentModule':
                return <FramedPictureContentModule {...item} key={item.id} />;
            case 'GalleryModule':
                return <ImageCarouselModule {...item} key={item.id} />;
            case 'FramedPictureCarouselModule':
                return <FramedPictureCarouselModule {...item} key={item.id} />;
            case 'DirectoryModule':
                return <DirectoryModule {...item} key={item.id} />;
            case 'ImageTextCarouselModule':
                return <ImageTextCarouselModule {...item} key={item.id} />;
            case 'ProfessionalMembersModule':
                return <ProfessionalMembersModule {...item} key={item.id} />;
            case 'AccordionModule':
                return <AccordionModule {...item} key={item.id} />;
            case 'ProjectFeaturesModule':
                return <ProjectFeaturesModule {...item} key={item.id} />;
            case 'GuestHomeHeroModule':
                return <GuestHomeHeroModule {...item} key={item.id} />;
            case 'MilestoneModule':
                return <MilestoneModule {...item} key={item.id} />;
            case 'CaptionCTAModule':
                return <CaptionCTAModule {...item} key={item.id} />;
            case 'ContactUsMapModule':
                return <ContactUsMapModule {...item} key={item.id} />;
            case 'CampaignHeaderModule':
                return <CampaignHeaderModule {...item} key={item.id} />;
            case 'CampaignFormFooterModule':
                return <CampaignFormFooterModule {...item} key={item.id} />;
            default:
                return <></>; // don't render unsupported module without erroring the page.
        }
    };

    return <div className={css(ModuleContainer)}>{moduleElement()}</div>;
};

export default ElementalModule;

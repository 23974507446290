import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem, imageClassName } from '@archipro-design/aria';

export const HeadingModuleContainer: StyleRule = ({ theme }) => {
    return {
        height: pxToRem(171),
        textAlign: 'center',
        padding: pxArrayToRem([24, 0]),
        '& h2': {
            margin: 'auto',
            textTransform: 'uppercase',
            ...theme.siteVariables.textStyles.Heading.Bold75,
            fontSize: pxToRem(60),
            lineHeight: pxToRem(60),
            letterSpacing: '-0.01em',
        },

        [theme.screen.laptop]: {
            height: pxToRem(260),
            padding: pxArrayToRem([64, 0]),
            '& h2': {
                fontSize: pxToRem(130),
                lineHeight: pxToRem(130),
                letterSpacing: '0.08em',
            },
        },

        [`& .${imageClassName}`]: {
            backgroundColor: 'transparent',
        },
    };
};

import * as S from './IntroModule.style';
import { Flex, useStyles, Image, VideoPlayer } from '@archipro-design/aria';
import BaseSection from '@modules/elemental-page/component/base-section/BaseSection';
import EcommerceHeading from '../ecommerce-heading/EcommerceHeading';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import type { IntroModuleProps } from '@modules/elemental-page/type';
import type { ThemedElementColourType } from '@modules/elemental-page/type';
import { useTracker } from '@archipro-website/tracker';

interface ThemedIntroModuleProps extends IntroModuleProps {
    theme?: ThemedElementColourType;
}

const IntroModule = ({
    title,
    content,
    playVideoInModal,
    videoUrl,
    videoCover,
    backgroundImage,
    theme,
    anchor,
}: ThemedIntroModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();
    const [shouldPlay, setShouldPlay] = useState(false);
    const [observerRef, inView] = useInView({
        rootMargin: '50% 0px',
    });

    const tracker = useTracker();

    const trackPlayEvent = () => {
        tracker.log('Video', {
            url: new URL(window.location.href),
        });
    };

    useEffect(() => {
        if (!inView) {
            setShouldPlay(false);
        }
    }, [inView]);
    const extraVideoProps = {
        playing: shouldPlay,
        muted: true,
        controls: true,
        playsInline: !playVideoInModal,
        onPlay: () => {
            setShouldPlay(true);
        },
        trackPlayEvent,
    };

    return (
        <BaseSection background={theme} style={S.BaseSection} id={anchor}>
            {backgroundImage?.desktopSrc && (
                <div className={css(S.ModuleBackgroundContainer)}>
                    {mobile ? (
                        <Image
                            className={css(S.MobileBackground)}
                            src={
                                backgroundImage?.mobileSrc ||
                                backgroundImage.desktopSrc
                            }
                            width={416}
                            height={939}
                            layout={'fill'}
                            objectFit={'cover'}
                            {...getCustomPlaceholderColor('light')}
                            alt={''}
                        />
                    ) : (
                        <Image
                            className={css(S.DesktopBackground)}
                            src={backgroundImage.desktopSrc}
                            width={1920}
                            height={2023}
                            layout={'fill'}
                            objectFit={'cover'}
                            {...getCustomPlaceholderColor('light')}
                            alt={''}
                        />
                    )}
                </div>
            )}
            <div className={css(S.ModuleContentContainer)}>
                {title && (
                    <EcommerceHeading title={title} style={S.ModuleHeader} />
                )}
                {content && (
                    <Flex fill={true} hAlign={'end'}>
                        <div
                            className={css(S.ModuleContent)}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </Flex>
                )}
            </div>
            {videoUrl && (
                <div ref={observerRef} className={css(S.VideoContainer)}>
                    <div className={css(S.AspectRatioBox)}>
                        <VideoPlayer
                            src={videoUrl}
                            coverImage={{
                                src: videoCover?.desktopSrc || '',
                                height: 850,
                                width: 1512,
                                layout: 'responsive',
                                objectFit: 'cover',
                                objectPosition: 'center',
                                alt: title || '',
                            }}
                            {...extraVideoProps}
                        />
                    </div>
                </div>
            )}
        </BaseSection>
    );
};

export default IntroModule;

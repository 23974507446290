import {
    Flex,
    Caption,
    useStyles,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import * as S from './CaptionCTAModule.style';
import { CTA } from '@modules/elemental-page/component/text-cta-content/TextContent.style';
import type { CaptionCTAModuleProps } from '@modules/elemental-page/type';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

import type { ThemedElementColourType } from '@modules/elemental-page/type';

interface ThemedCaptionCTAModuleProps extends CaptionCTAModuleProps {
    theme: ThemedElementColourType;
}

const CaptionCTAModule = ({
    title,
    subtitle,
    cta,
    secondaryCta,
    theme,
}: ThemedCaptionCTAModuleProps) => {
    const darkTheme = theme !== 'dark';
    const { desktop } = useAppDisplayModeContext();
    const { css } = useStyles({ darkTheme });

    return (
        <Flex space={'between'} className={css(S.SlimCTAContainer)}>
            <div>
                {cta ? (
                    <div className={css(S.HeadingLink)}>
                        <AboutCallToAction
                            {...cta}
                            title={title || cta.title}
                            buttonTheme={darkTheme ? 'light' : 'dark'}
                        />
                    </div>
                ) : (
                    <Caption className={css(S.Heading)}>{title}</Caption>
                )}
                {secondaryCta ? (
                    <div className={css(S.SubheadingLink)}>
                        <AboutCallToAction
                            {...secondaryCta}
                            title={subtitle || secondaryCta.title}
                            buttonTheme={darkTheme ? 'light' : 'dark'}
                        />
                    </div>
                ) : (
                    <Caption className={css(S.Subheading)}>{subtitle}</Caption>
                )}
            </div>
            {cta && desktop && (
                <div className={css(CTA, S.CTA)}>
                    <AboutCallToAction
                        {...cta}
                        buttonTheme={darkTheme ? 'light' : 'dark'}
                    />
                </div>
            )}
        </Flex>
    );
};

export default CaptionCTAModule;

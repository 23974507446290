import type { videoUrl } from '@modules/home/util/project-home-tile-view-model.server';
import { useMemo } from 'react';
import { useLatest } from 'ahooks';

export const useCompatibleVideoSources = (
    isLegacyDevice: boolean,
    sources?: videoUrl
): videoUrl | undefined => {
    const sourcesRef = useLatest(sources);

    return useMemo(() => {
        return Array.isArray(sourcesRef.current) && isLegacyDevice
            ? sourcesRef.current.slice().sort((source) => {
                  if (typeof source === 'string') {
                      return source.endsWith('.mp4') ? -1 : 1;
                  } else if ('src' in source) {
                      return source['src'].endsWith('.mp4') ? -1 : 1;
                  }

                  return 0;
              })
            : sourcesRef.current;
    }, [isLegacyDevice, sourcesRef]);
};

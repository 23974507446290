import {
    Caption,
    useStyles,
    useTheme,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import * as S from './DirectoryModule.style';
import type { DirectorySectionBlock } from '@modules/elemental-page/type';
import { Link } from '@remix-run/react';

const SectionBlock = ({ title, links }: DirectorySectionBlock) => {
    const { css } = useStyles();
    const theme = useTheme();
    const { mobile } = useAppDisplayModeContext();

    return (
        <div className={css(S.SectionBlock)}>
            <div className={css(S.RowHead)}>
                <Caption
                    variant={mobile ? '03' : '02'}
                    variables={{
                        captionColor:
                            theme.siteVariables.colors.primitive.white,
                    }}
                >
                    {title}
                </Caption>
            </div>
            {links &&
                !!links.length &&
                links.map((link,i) => (
                    <div className={css(S.RowLink)} key={`section-link-${link.url}-${i}`}>
                        {link.url && (
                            <Caption variant={mobile ? '04' : '03'}>
                                <Link to={link.url}>{link.title}</Link>
                            </Caption>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default SectionBlock;

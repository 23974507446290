import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem, captionClassName } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';
import { svgIconClassName } from '@archipro-design/icons';

export const SlimCTAContainer: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    padding: pxArrayToRem([18, 18]),
    [theme.screen.laptop]: {
        padding: pxArrayToRem([36, 80]),
    },
});

export const Heading: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    ...theme.siteVariables.textStyles.Heading.Roman55,
    fontSize: theme.siteVariables.fontSizes.caption04,
    color: guestHomeTheme(theme, darkTheme).main.text2,
    letterSpacing: pxToRem(-0.14),
    [theme.screen.laptop]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: theme.siteVariables.fontSizes.caption03s,
        letterSpacing: 0,
        textTransform: 'uppercase',
    },
});

export const Subheading: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    color: guestHomeTheme(theme, darkTheme).main.text2,
    [theme.screen.laptop]: {
        ...theme.siteVariables.textStyles.Heading.Roman55,
        fontSize: theme.siteVariables.fontSizes.caption03s,
        textTransform: 'uppercase',
    },
});

export const HeadingLink: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`& a`]: {
        margin: pxArrayToRem([2, 0]),
        padding: 0,
        backgroundColor: 'transparent',
        [`& .${captionClassName}`]: {
            ...theme.siteVariables.textStyles.Heading.Roman55,
            fontSize: theme.siteVariables.fontSizes.caption04,
            color: guestHomeTheme(theme, darkTheme).main.text2,
            letterSpacing: pxToRem(-0.14),
            textTransform: 'uppercase',
            [theme.screen.laptop]: {
                ...theme.siteVariables.textStyles.Heading.Medium65,
                fontSize: theme.siteVariables.fontSizes.caption03s,
                letterSpacing: 0,
            },
        },
        [`& .${svgIconClassName}`]: {
            display: 'none',
        },
    },
});

export const SubheadingLink: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`& a`]: {
        margin: pxArrayToRem([2, 0]),
        padding: 0,
        backgroundColor: 'transparent',
        [`& .${captionClassName}`]: {
            ...theme.siteVariables.textStyles.Text.Roman55,
            fontSize: pxToRem(13),
            color: guestHomeTheme(theme, darkTheme).main.text2,
            textDecoration: 'underline',
            textTransform: 'none',
        },
        [`& .${svgIconClassName}`]: {
            display: 'none',
        },
    },
});

export const CTA: StyleRule = ({ theme }) => ({
    [theme.screen.max.laptop]: {
        [`& a`]: {
            padding: pxArrayToRem([24, 32]),
            margin: pxArrayToRem([24, 0]),
            borderRadius: 0,
            textTransform: 'uppercase',
            [`& .${captionClassName}`]: {
                letterSpacing: pxToRem(-0.65),
                [theme.screen.max.laptop]: {
                    ...theme.siteVariables.textStyles.GTAmericaTrialFont.Bold75,
                },
            },
        },
    },
    [theme.screen.laptop]: {
        marginBottom: 0,
    },
});

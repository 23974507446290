import { Caption, useStyles, useTheme } from '@archipro-design/aria';
import * as S from './AboutCallToAction.style';
import React from 'react';
import { Link } from '@remix-run/react';
import { CustomArrowRight } from '@archipro-design/icons';
import type { CallToAction } from '@modules/elemental-page/type';
import { useLoginModal } from '@modules/design-board';
import { useUser } from '@modules/user';

export type AboutButtonTheme = 'light' | 'dark';

interface AboutCallToActionProps extends CallToAction {
    buttonTheme: AboutButtonTheme;
}

const AboutCallToAction = ({
    title,
    link,
    externalLink,
    linkToLoginModal,
    buttonTheme = 'light',
}: AboutCallToActionProps) => {
    const { css } = useStyles({ buttonTheme });
    const theme = useTheme();
    const newTab = externalLink && externalLink.length > 0;
    const user = useUser();
    const isLoggedIn = user.__typename === 'Me';
    const canLogin = !isLoggedIn && linkToLoginModal;
    const showCTA = (link || externalLink || canLogin) && title;
    const redirect = externalLink || link || '';
    const { showLoginModal, loginModal } = useLoginModal({
        customRedirect: redirect,
        allowExternalUrls: true,
        authSource: 'aboutCallToAction',
    });

    return (
        <>
            {showCTA && (
                <Link
                    to={redirect}
                    onClick={(e) => {
                        if (linkToLoginModal) {
                            e.preventDefault();
                            showLoginModal();
                        }
                    }}
                    target={newTab ? '_blank' : '_self'}
                    className={css(S.Link)}
                >
                    <Caption
                        variant="03"
                        variables={{
                            // letterSpacing: '0.06em',
                            captionColor:
                                buttonTheme === 'dark'
                                    ? theme.siteVariables.colors.primitive.white
                                    : theme.siteVariables.colors.primitive
                                          .black,
                        }}
                        className={css(S.LinkText)}
                    >
                        {title}
                    </Caption>
                    <CustomArrowRight className={css(S.LinkArrow)} />
                </Link>
            )}
            {canLogin && loginModal}
        </>
    );
};

export default AboutCallToAction;

import { useEffect, useState } from 'react';
import type { CampaignHeaderModuleProps } from '../../type';
import * as S from './CampaignHeaderModule.style';
import {
    Caption,
    Flex,
    Grid,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import { Link } from '@remix-run/react';

interface FormProps {
    title?: string;
    embedLink?: string;
    frameHeight?: number;
    anchor?: string;
    heroImageSrc: string;
}

const CampaignHeaderModule = (props: CampaignHeaderModuleProps) => {
    const { heroImage, leftImage, formEmbedUrl, customClassName, formTitle } =
        props;
    const { mobile } = useAppDisplayModeContext();
    const { css } = useStyles({
        backgroundImageUrl: heroImage?.desktopSrc ?? '',
        customClassName,
    });
    return (
        <div className={css(S.Background)}>
            <Grid columns={'1fr'} className={css(S.CampaignHeaderContainer)}>
                <Flex
                    vAlign="center"
                    hAlign="center"
                    className={css(S.CampaignTop)}
                >
                    <Link to="/">
                        <svg
                            viewBox="0 0 97 97"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0H46.1905V46.1974H36.9524V41.5784H9.2381V46.1974H0V0ZM36.9524 9.2404H9.2381V32.338H36.9524V9.2404Z"
                                    fill="white"
                                />
                                <path
                                    d="M97 0V41.5784H69.2857V32.338H87.7619V9.2404H60.0476V46.1974H50.8095V0H97Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M87.7619 55.4216H60.0476V50.8026H50.8095V97H97V50.8026H87.7619V55.4216ZM87.7619 87.7596H60.0476V64.6597H87.7619V87.7596Z"
                                    fill="white"
                                />
                                <path
                                    d="M36.9524 50.8026H46.1905V97H0V55.4216H27.7143V64.6621H9.2381V87.7596H36.9524V50.8026Z"
                                    fill="white"
                                />
                            </g>
                        </svg>
                    </Link>
                </Flex>
                <Grid columns={mobile ? '1fr' : '1fr 1fr'}>
                    <Flex vAlign="start" hAlign="center">
                        <img
                            style={{ width: '100%' }}
                            src={leftImage?.desktopSrc ?? ''}
                            alt="Campaign Left"
                        />
                    </Flex>

                    <IframeEmbedModule
                        title={formTitle ?? "LET'S COLLABORATE"}
                        embedLink={formEmbedUrl}
                        heroImageSrc={heroImage?.mobileSrc ?? ''}
                    />
                </Grid>
            </Grid>
            <div className={css(S.SpacerBottom)}></div>
        </div>
    );
};

const IframeEmbedModule = ({
    title,
    embedLink,
    frameHeight,
    anchor,
    heroImageSrc,
}: FormProps) => {
    const [maxFrameHeight, setMaxFrameHeight] = useState(
        Math.max(frameHeight || 0, 200)
    );

    useEffect(() => {
        const handleFrameHeightUpdate = (event: { data: number }) => {
            if (!isNaN(event.data)) {
                const chooseMaxHeight = Math.max(
                    frameHeight || 0,
                    200,
                    event.data
                );
                setMaxFrameHeight(chooseMaxHeight);
            }
        };

        window.addEventListener('message', handleFrameHeightUpdate);

        return () => {
            window.removeEventListener('message', handleFrameHeightUpdate);
        };
    }, [frameHeight]);

    const { css } = useStyles({
        maxFrameHeight,
        backgroundImageUrl: heroImageSrc,
    });

    if (!embedLink) {
        return null;
    }

    return (
        <Flex vAlign="center" hAlign="center" className={css(S.FormContainer)}>
            <div className={css(S.ModuleContainer)} id={anchor}>
                <Caption
                    as="h1"
                    variables={{
                        fontSize: pxToRem(24),
                    }}
                >
                    {title}
                </Caption>
                <iframe
                    src={embedLink}
                    width="100%"
                    height={maxFrameHeight}
                    frameBorder="0"
                    scrolling="no"
                    title={title || 'external content'}
                />
            </div>
        </Flex>
    );
};

export default CampaignHeaderModule;

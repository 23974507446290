import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const NavButtons: StyleRule<{
    darkTheme: boolean;
}> = ({ theme, darkTheme }) => ({
    position: 'absolute',
    display: 'flex',
    gap: pxToRem(9),
    [`& button`]: {
        background: guestHomeTheme(theme, darkTheme).button.background,
        cursor: 'pointer',
        border: 'none',
        borderRadius: '50%',
        padding: pxToRem(15),
        [`& svg`]: {
            width: pxToRem(24),
            height: pxToRem(24),
            fill: guestHomeTheme(theme, darkTheme).icon.fill,
        },
    },
});

export const PrevButton: StyleRule = () => ({});

export const NextButton: StyleRule = () => ({
    transform: 'rotate(180deg)',
});

export const FloatingNavButtons: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`& button`]: {
        position: 'absolute',
        cursor: 'pointer',
        top: '50%',
        zIndex: 1,
        background: guestHomeTheme(theme, darkTheme).button.background2,
        border: guestHomeTheme(theme, darkTheme).button.border,
        padding: pxToRem(20),
        [`& svg`]: {
            width: pxToRem(24),
            height: pxToRem(24),
            [`& path`]: {
                fill: guestHomeTheme(theme, darkTheme).icon.fill2,
            },
        },
        [`&.swiper-button-disabled`]: {
            display: 'none',
        },
    },
});

export const FloatingLeftButton: StyleRule = ({ theme }) => ({
    left: pxToRem(88),
    transform: 'translateY(-50%)',
    zIndex: 1,
    [`&:hover`]: {
        background: theme.siteVariables.colors.charcoal['250'],
        [`& svg path`]: {
            fill: theme.siteVariables.colors.primitive.white,
        },
    },
});

export const FloatingRightButton: StyleRule = ({ theme }) => ({
    right: pxToRem(88),
    transform: 'translateY(-50%) rotate(180deg)',
    zIndex: 1,
    [`&:hover`]: {
        background: theme.siteVariables.colors.charcoal['250'],
        [`& svg path`]: {
            fill: theme.siteVariables.colors.primitive.white,
        },
    },
});

import { useStyles } from '@archipro-design/aria';
import type { AccordionModuleProps } from '@modules/elemental-page/type';

import * as S from './AccordionModule.style';
import AccordionItem from '@modules/elemental-page/component/accordion-module/AccordionItem';
import type { ThemedElementColourType } from '@modules/elemental-page/type';

interface ThemedAccordionModuleProps extends AccordionModuleProps {
    id: string;
    theme: ThemedElementColourType;
}
const AccordionModule = ({ accordions, theme }: ThemedAccordionModuleProps) => {
    const darkTheme = theme === 'dark';
    const { css } = useStyles({ darkTheme });

    return (
        <div className={css(S.AccordionModule)}>
            <div className={css(S.Accordions)}>
                {accordions.map((accordion, i) => (
                    <AccordionItem
                        key={accordion.id}
                        index={i + 1}
                        darkTheme={darkTheme}
                        {...accordion}
                    />
                ))}
            </div>
        </div>
    );
};

export default AccordionModule;

import { Image, Caption, Flex, useStyles } from '@archipro-design/aria';
import * as S from './GuestHomeHeroModule.style';

import type { GuestHeroSlide } from '@modules/elemental-page/type';
import Marquee from 'react-fast-marquee';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';
import HeroWelcomeMessageMobile from '@modules/elemental-page/component/guest-home-hero-module/HeroWelcomeMessageMobile';

interface GuestHeroSlideMobileProps {
    item: GuestHeroSlide;
    showWelcomeMessage?: boolean;
    darkTheme: boolean;
}

const GuestHeroSlideMobile = ({
    item,
    showWelcomeMessage,
    darkTheme,
}: GuestHeroSlideMobileProps) => {
    const { css } = useStyles({ darkTheme });

    const mobileBackground = (
        <div className={css(S.ImageContainer)}>
            <Image
                key={item.id}
                width={414}
                height={631}
                layout={'fill'}
                objectFit={'cover'}
                src={item.backgroundImage?.mobileSrc || ''}
                alt={'img-alt'}
            />
        </div>
    );

    return (
        <div className={css(S.SlideContainer)}>
            {item.mobileScrollingEffect ? (
                <Marquee play={true} speed={25} direction={'up'} loop={0}>
                    {mobileBackground}
                    {mobileBackground}
                    {mobileBackground}
                </Marquee>
            ) : (
                mobileBackground
            )}
            <div className={css(S.Overlay)}>
                <div className={css(S.SlideContent)}>
                    {showWelcomeMessage && <HeroWelcomeMessageMobile />}
                    <Caption className={css(S.Title)}>{item.title}</Caption>
                    {item.content && (
                        <Caption className={css(S.Content)}>
                            {item.content}
                        </Caption>
                    )}
                    <Flex column className={css(S.SlideActions)}>
                        {item.primaryCta && (
                            <AboutCallToAction
                                {...item.primaryCta}
                                buttonTheme={'dark'}
                            />
                        )}
                        {item.secondaryCta && (
                            <AboutCallToAction
                                {...item.secondaryCta}
                                buttonTheme={'light'}
                            />
                        )}
                    </Flex>
                </div>
            </div>
        </div>
    );
};

export default GuestHeroSlideMobile;

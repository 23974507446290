import type { StyleRule } from '@archipro-design/aria';
import {
    bannerAdTileClassName,
    bannerAdTileSlotClassNames,
    buttonClassName,
    captionClassName,
    gridClassName,
    labelClassName,
    pxArrayToRem,
    pxToRem,
    tileImageClassName,
    tileImageSlotClassNames,
    tileOverlayClassName,
    tileVideoClassName,
    featuredArticleSlotClassNames,
} from '@archipro-design/aria';
import { hex2rgba } from '~/utils';

export const BaseStyle: StyleRule = ({ theme }) => ({
    gridGap: pxToRem(50),
    [theme.screen.tablet]: {
        gridGap: pxToRem(210),
    },
});

export const FeaturedArticleTileMobile: StyleRule = () => ({
    [`& .${tileImageClassName}`]: {
        height: pxToRem(131),
        [`& .${tileOverlayClassName}`]: {
            display: 'none',
        },
        [`& .${tileImageSlotClassNames.imageContainer}`]: {
            height: '100%',
        },
    },
    [`& > .ui-flex`]: {
        justifyContent: 'center !important',
        gap: pxToRem(15),
    },
});

export const FeaturedArticleTile: StyleRule = () => ({
    background: '#faf9f7',
    [`& .${featuredArticleSlotClassNames.contentButton}`]: {
        padding: pxArrayToRem([11.5, 23.5]),
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(16),
        },
    },

    [`& .${gridClassName}`]: {
        alignItems: 'center',
    },
});

export const FeaturedArticleTitle: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        [`&.${captionClassName}`]: {
            lineHeight: theme.siteVariables.lineHeightSmaller,
        },
    },
});

export const FeaturedArticleCategory: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        marginTop: pxToRem(23),
    },
});

export const FeaturedProject: StyleRule<{ inViewport?: boolean }> = ({
    theme,
    inViewport,
}) => ({
    // Change font family only for mobile
    [theme.screen.max.laptop]: {
        [`& .${bannerAdTileSlotClassNames.title}`]: {
            ...theme.siteVariables.textStyles.Heading.Roman55,
            textTransform: 'uppercase',
            fontSize: pxToRem(14),
        },
    },

    [theme.screen.laptop]: {
        [`& .${bannerAdTileSlotClassNames.title}`]: {
            marginTop: pxToRem(3),
            fontSize: pxToRem(20),
        },
        [`& .${bannerAdTileSlotClassNames.description}`]: {
            fontSize: pxToRem(16),
        },
        [`& .${bannerAdTileClassName}`]: {
            display: 'grid',

            [`& .${buttonClassName}`]: {
                padding: pxArrayToRem([11.5, 23.5]),
                [`& .${labelClassName}`]: {
                    fontSize: pxToRem(16),
                },
            },

            [`& .${tileVideoClassName}`]: {
                [`& video`]: {
                    filter: `grayscale(1)`,
                    transition: 'filter 0.5s',
                },
            },

            ...(inViewport && {
                [`& .${tileVideoClassName}`]: {
                    [`& video`]: {
                        filter: `grayscale(0)`,
                        transition: 'filter 0.5s',
                    },
                },
            }),
        },
    },
});

export const VideoOverlay: StyleRule<{ inViewport?: boolean }> = ({
    theme,
    inViewport,
}) => ({
    [theme.screen.tablet]: {
        opacity: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: hex2rgba(theme.siteVariables.colors.primitive.black, 0.5),
        zIndex: theme.siteVariables.zIndexes.foreground,
        transition: 'opacity 0.5s',

        ...(inViewport && {
            opacity: 0,
        }),
    },
});

export const ButtonWrapMobileStyle: StyleRule = () => ({
    padding: pxArrayToRem([0, 18]),
    marginTop: pxToRem(22),
});

export const ButtonMobileStyle: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
    },
});

import { useState } from 'react';
import {
    Caption,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './AccordionModule.style';
import {
    AddLine,
    SubtractLine,
    AngleDown,
    AngleUp,
} from '@archipro-design/icons';

interface AccordionItemProps {
    title?: string;
    index?: number;
    content?: string;
    darkTheme: boolean;
}

const AccordionItem = ({
    index,
    title,
    content,
    darkTheme,
}: AccordionItemProps) => {
    const [expanded, setExpanded] = useState(false);
    const { css } = useStyles({ expanded, darkTheme });
    const { desktop } = useAppDisplayModeContext();

    return (
        <div>
            <div
                className={css(S.AccordionHead)}
                onClick={() => setExpanded(!expanded)}
            >
                {!!index && (
                    <div className={css(S.AccordionIndex)}>{index}</div>
                )}
                <Caption className={css(S.AccordionTitle)}>{title}</Caption>
                {expanded ? (
                    <>
                        {desktop ? (
                            <SubtractLine
                                size="2x"
                                className={css(S.Indicator)}
                            />
                        ) : (
                            <AngleUp className={css(S.Indicator)} />
                        )}
                    </>
                ) : (
                    <>
                        {desktop ? (
                            <AddLine size="2x" className={css(S.Indicator)} />
                        ) : (
                            <AngleDown className={css(S.Indicator)} />
                        )}
                    </>
                )}
            </div>

            <div className={css(S.AccordionBody)}>
                <Caption className={css(S.Content)}>{content}</Caption>
            </div>
            <hr className={css(S.Divider)} />
        </div>
    );
};

export default AccordionItem;

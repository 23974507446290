import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const ImageContainer: StyleRule = ({ theme }) => {
    return {
        marginBottom: pxToRem(73),
        [theme.screen.laptop]: {
            marginBottom: pxToRem(228),
            marginLeft: pxToRem(202),
            marginRight: pxToRem(201),
        },
    };
};

export const ImageStyle: StyleRule = ({ theme }) => {
    return {
        display: 'none',
        [theme.screen.tablet]: {
            display: 'block',
        },
    };
};

export const ImageMobileStyle: StyleRule = ({ theme }) => {
    return {
        display: 'block',
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

export const CustomBaseSectionStyleWithImage: StyleRule = ({ theme }) => {
    return {
        paddingBottom: pxToRem(94),
        [theme.screen.laptop]: {
            paddingBottom: pxToRem(257),
        },
    };
};

export const CustomBaseSectionStyle: StyleRule = ({ theme }) => {
    return {
        paddingBottom: pxToRem(50),
        [theme.screen.laptop]: {
            paddingBottom: pxToRem(215),
        },
    };
};

export const CustomHeadingWithImage: StyleRule = ({ theme }) => {
    return {
        marginBottom: pxToRem(66),
        marginTop: pxToRem(1),
        color: theme.siteVariables.colors.primitive.white,
        [theme.screen.laptop]: {
            marginTop: 0,
            color: theme.siteVariables.colors.white['300'],
            maxWidth: pxToRem(1792),
        },
    };
};

export const CustomHeading: StyleRule = ({ theme }) => {
    return {
        marginBottom: pxToRem(39),
        marginTop: pxToRem(1),
        color: theme.siteVariables.colors.primitive.white,
        [theme.screen.laptop]: {
            marginBottom: pxToRem(134),
            marginTop: 0,
            maxWidth: pxToRem(1572),
            color: theme.siteVariables.colors.white['300'],
        },
    };
};

export const ContentContainer: StyleRule = ({ theme }) => {
    return {
        [theme.screen.laptop]: {
            width: '50%',
            paddingLeft: pxToRem(14),
        },
    };
};
export const ParagraphContainer: StyleRule<{ hasImage: boolean }> = ({
    theme,
    hasImage,
}) => {
    return {
        color: theme.siteVariables.colors.primitive.white,
        fontSize: theme.siteVariables.fontSizes.label02,
        lineHeight: theme.siteVariables.lineHeightSmall,

        '& p': {
            margin: '0',
        },

        [theme.screen.laptop]: {
            fontSize: pxToRem(40),
            lineHeight: theme.siteVariables.lineHeightSmaller,
            maxWidth: !hasImage ? pxToRem(850) : undefined,
            color: theme.siteVariables.colors.white['300'],
        },
    };
};

export const CustomLinkStyle: StyleRule = ({ theme }) => {
    return {
        marginTop: pxToRem(37),
        [theme.screen.laptop]: {
            marginTop: pxToRem(77),
        },
    };
};

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import type { ImageTextCarouselModuleOrientation } from '@modules/elemental-page/type';

export const FlexContainer: StyleRule<{
    orientation?: ImageTextCarouselModuleOrientation;
}> = ({ orientation }) => ({
    flexDirection: orientation === 'image-text' ? 'row' : 'row-reverse',
    alignItems: 'center',
});

export const ImageCarouselContainer: StyleRule<{
    slimImageCol?: boolean;
    orientation?: ImageTextCarouselModuleOrientation;
}> = ({ slimImageCol, orientation }) => ({
    position: 'relative',
    width: '50%',
    maxWidth: slimImageCol ? pxToRem(672) : 'none',

    [`& .chevron-buttons`]: {
        right: orientation === 'image-text' ? pxToRem(-60) : pxToRem(60),
    },
});

export const TextContentContainer: StyleRule<{
    orientation?: ImageTextCarouselModuleOrientation;
    renderAsSlide?: boolean;
}> = ({ orientation, renderAsSlide }) => ({
    flexGrow: 1,
    margin:
        orientation === 'image-text'
            ? pxArrayToRem([0, renderAsSlide ? 153 : 120, 0, 153])
            : pxArrayToRem([0, 153, 0, renderAsSlide ? 153 : 120]),
});

export const MobileContentContainer: StyleRule = () => ({
    padding: pxArrayToRem([0, 18]),
});

export const ExtraSpacer: StyleRule = () => ({
    marginBottom: pxToRem(32),
});

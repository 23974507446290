import { useStyles } from '@archipro-design/aria';
import BaseSection from '../base-section/BaseSection';
import EcommerceHeading from '../ecommerce-heading/EcommerceHeading';
import EcommerceLink from '../ecommerce-link/EcommerceLink';
import {
    CustomBaseSection,
    CustomHeading,
    SignInLink,
    BookingConfirmedText,
    AcceptIconStyle,
} from './CallToActionModule.style';
import { useElementalPage } from '../../context/ElementalPageContext';
import { AcceptIcon } from '@fluentui/react-icons-northstar';
import { useLocation } from '@remix-run/react';
import { useAppData } from '@modules/root';
import { useTracker } from '@archipro-website/tracker';
import type { CTAModuleProps } from '@modules/elemental-page/type';

const CallToActionModule = ({ content, cta, anchor }: CTAModuleProps) => {
    const { css } = useStyles();

    const { user } = useAppData();
    const location = useLocation();
    const { log } = useTracker();

    const { dispatch, state } = useElementalPage();

    const onClick = () => {
        log('EcomLandingPage_BookingReveal', {
            url: new URL(window.location.href),
        });
        if (cta?.anchor) {
            const anchorElement = document.getElementById(cta.anchor);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            dispatch({ type: 'toggleModal' });
        }
    };

    return (
        <BaseSection style={CustomBaseSection} id={anchor}>
            <div>
                {content && (
                    <EcommerceHeading style={CustomHeading} title={content} />
                )}
                {state.meetingBooked ? (
                    <div className={css(BookingConfirmedText)}>
                        <div>Booking Confirmed</div>
                        <AcceptIcon className={css(AcceptIconStyle)} />
                    </div>
                ) : (
                    <div>
                        {cta?.title && (
                            <EcommerceLink {...cta} onClick={onClick} />
                        )}
                        {(!user || user.__typename !== 'Me') && (
                            <a
                                className={css(SignInLink)}
                                href={`/login?redirect=${encodeURIComponent(
                                    `${location.pathname}?openModal`
                                )}`}
                                onClick={() => {
                                    log('EcomLandingPage_SignIn', {
                                        url: new URL(window.location.href),
                                    });
                                }}
                            >
                                Or Sign in
                            </a>
                        )}
                    </div>
                )}
            </div>
        </BaseSection>
    );
};

export default CallToActionModule;

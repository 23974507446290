import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    labelClassName,
    pxArrayToRem,
    pxToRem,
} from '@archipro-design/aria';

import { svgIconClassName } from '@archipro-design/icons';

import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const Title: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`&.${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: theme.siteVariables.fontSizes['subheading03-alt'],
        textTransform: 'uppercase',
        lineHeight: '113%',
        marginBottom: pxToRem(27),
        color: guestHomeTheme(theme, darkTheme).main.text3,
        [theme.screen.laptop]: {
            maxWidth: pxToRem(800),
            fontSize: pxToRem(42),
            marginBottom: pxToRem(42),
            color: guestHomeTheme(theme, darkTheme).main.text2,
        },
    },
});

export const Subtitle: StyleRule = ({ theme }) => ({
    [`&.${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: theme.siteVariables.fontSizes.caption06,
        textTransform: 'uppercase',
        color: theme.siteVariables.colors.gold['000'],
        marginBottom: pxToRem(8),
        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes.caption04,
            marginBottom: pxToRem(14),
        },
    },
});

export const Content: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`&.${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        fontSize: theme.siteVariables.fontSizes.caption03s,
        lineHeight: '150%',
        marginBottom: pxToRem(15),
        color: guestHomeTheme(theme, darkTheme).main.text,
        [theme.screen.laptop]: {
            marginBottom: pxToRem(36),
            [`&.${captionClassName}`]: {
                maxWidth: pxToRem(800),
                fontSize: theme.siteVariables.fontSizes.caption01,
                letterSpacing: pxToRem(-0.15),
                lineHeight: '140%',
                marginBottom: pxToRem(42),
            },
        },
    },
});

export const CTA: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`& a`]: {
        margin: pxArrayToRem([36, 0]),
        padding: pxArrayToRem([16, 13]),
        background: guestHomeTheme(theme, darkTheme).button.background3,
        borderRadius: pxToRem(5),
        [theme.screen.max.laptop]: {
            [`& .${captionClassName}`]: {
                ...theme.siteVariables.textStyles.Display.Roman55,
                fontSize: theme.siteVariables.fontSizes.caption03,
                letterSpacing: pxToRem(0.3),
                textTransform: 'unset',
            },
            [`& .${svgIconClassName}`]: {
                display: 'none',
            },
        },
        [theme.screen.laptop]: {
            [`& .${captionClassName}`]: {
                ...theme.siteVariables.textStyles.GTAmericaTrialFont.Bold75,
                letterSpacing: pxToRem(-0.65),
            },
            padding: pxArrayToRem([20, 40]),
            margin: 0,
            borderRadius: 0,
        },
        [`&:hover`]: {
            backgroundColor: guestHomeTheme(theme, darkTheme).button
                .hoverBackground,
            [`& .${captionClassName}`]: {
                color: theme.siteVariables.colors.primitive.white,
            },
            [`& svg path`]: {
                fill: theme.siteVariables.colors.primitive.white,
            },
        },
    },

    [theme.screen.laptop]: {
        marginBottom: pxToRem(42),
    },
});

export const Link: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.GTAmericaTrialFont.Bold75,
        fontSize: theme.siteVariables.fontSizes.caption05,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        color: guestHomeTheme(theme, darkTheme).main.text,
        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes.caption03s,
        },
    },
    borderRadius: pxToRem(2),
    [`&:hover`]: {
        backgroundColor: theme.siteVariables.colors.charcoal['260'],
        [`& .${labelClassName}`]: {
            color: theme.siteVariables.colors.primitive.white,
        },
    },
});

export const LinkRow: StyleRule = () => ({
    gap: pxToRem(6),
    marginTop: pxToRem(16),
    flexWrap: 'wrap',
});

export const SplitContent: StyleRule = ({ theme }) => ({
    alignItems: 'flex-end',
    [theme.screen.laptop]: {
        justifyContent: 'space-between',
    },
});

export const ExtraSpacing: StyleRule = ({ theme }) => ({
    [theme.screen.laptop]: {
        marginTop: pxToRem(82),
    },
});

export const CtaCol: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    minWidth: 0,
    [theme.screen.laptop]: {
        textAlign: darkTheme ? 'left' : 'right',
        minWidth: pxToRem(423),
    },
});

export const Links: StyleRule = ({ theme }) => ({
    [theme.screen.laptop]: {
        marginBottom: pxToRem(42),
    },
});

import { Flex, useStyles } from '@archipro-design/aria';
import * as S from './MainSlide.style';
import type { TextCarouselItem } from '@modules/elemental-page/type';
import ImageSlider from '@modules/elemental-page/component/image-text-carousel-module/ImageSlider';
import type { CommonModule } from '@modules/elemental-page/type';
import TextContent from '@modules/elemental-page/component/text-cta-content/TextContent';
import LinkTags from '@modules/elemental-page/component/text-cta-content/LinkTags';
import MainCta from '@modules/elemental-page/component/text-cta-content/MainCta';

interface MainSlideProps
    extends Pick<
        CommonModule,
        'imageCarouselItems' | 'id' | 'orientation' | 'slimImageCol'
    > {
    textContent?: TextCarouselItem;
    renderAsSlide?: boolean;
    darkTheme: boolean;
}

const MainSlide = ({
    id,
    orientation,
    slimImageCol,
    imageCarouselItems,
    textContent,
    renderAsSlide,
    darkTheme,
}: MainSlideProps) => {
    const { css } = useStyles({
        orientation,
        slimImageCol: slimImageCol,
        renderAsSlide,
    });

    return (
        <Flex className={css(S.FlexContainer)}>
            <div className={css(S.ImageCarouselContainer)}>
                {imageCarouselItems.length && (
                    <ImageSlider
                        id={id}
                        renderAsSlide={renderAsSlide}
                        orientation={orientation}
                        imageCarouselItems={imageCarouselItems}
                        darkTheme={darkTheme}
                    />
                )}
            </div>
            <div className={css(S.TextContentContainer)}>
                {textContent && (
                    <>
                        <TextContent {...textContent} darkTheme={darkTheme} />
                        <MainCta {...textContent} darkTheme={darkTheme} />
                        <LinkTags {...textContent} darkTheme={darkTheme} />
                    </>
                )}
            </div>
        </Flex>
    );
};

export default MainSlide;

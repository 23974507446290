import {
    Button,
    Caption,
    Flex,
    pxArrayToRem,
    pxToRem,
    useStyles,
    useTheme,
} from '@archipro-design/aria';
import * as SlideStyles from './MainSlide.style';
import * as TextStyles from '@modules/elemental-page/component/text-cta-content/TextContent.style';
import type {
    TextCarouselItem,
    CommonModule,
} from '@modules/elemental-page/type';

import ModuleSlider from '@modules/elemental-page/component/module-slider/ModuleSlider';
import ImageSlide from '@modules/elemental-page/component/image-text-carousel-module/ImageSlide';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';
import * as S from '@modules/elemental-page/component/text-cta-content/TextContent.style';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

interface ImageSliderMobileProps
    extends Pick<CommonModule, 'imageCarouselItems' | 'id'> {
    textContent?: TextCarouselItem;
    renderAsSlide?: boolean;
    darkTheme: boolean;
}

const ImageSlideMobile = ({
    id,
    imageCarouselItems,
    textContent,
    renderAsSlide,
    darkTheme,
}: ImageSliderMobileProps) => {
    const { css } = useStyles({ darkTheme });
    const isCarousel = imageCarouselItems.length > 1;
    const theme = useTheme();

    const slides = isCarousel
        ? imageCarouselItems.map((item) => (
              <ImageSlide
                  key={item.id}
                  item={item}
                  renderAsSlide={renderAsSlide}
                  darkTheme={darkTheme}
              />
          ))
        : [];

    const darkOutlinedButtonBorderNormal = guestHomeTheme(theme, darkTheme)
        .button.border2;

    const firstSlide = imageCarouselItems[0] ? (
        <ImageSlide item={imageCarouselItems[0]} darkTheme={darkTheme} />
    ) : null;

    return (
        <Flex column>
            <div className={css(SlideStyles.MobileContentContainer)}>
                {textContent?.subtitle && (
                    <Caption className={css(TextStyles.Subtitle)}>
                        {textContent.subtitle}
                    </Caption>
                )}
                {textContent?.title && (
                    <Caption className={css(TextStyles.Title)}>
                        {textContent.title}
                    </Caption>
                )}
                {textContent?.content && (
                    <Caption className={css(TextStyles.Content)}>
                        {textContent.content}
                    </Caption>
                )}
            </div>
            <div className={css(SlideStyles.MobileContentContainer)}>
                {isCarousel ? (
                    <ModuleSlider
                        moduleId={id}
                        slides={slides}
                        darkTheme={darkTheme}
                        slidesPerView={1.28}
                        gap={18}
                        loop={true}
                        data-testid="img-slider"
                    />
                ) : (
                    firstSlide
                )}
            </div>
            <div className={css(SlideStyles.MobileContentContainer)}>
                {textContent?.cta && (
                    <div className={css(TextStyles.CTA)}>
                        <AboutCallToAction
                            {...textContent?.cta}
                            buttonTheme={darkTheme ? 'light' : 'dark'}
                        />
                    </div>
                )}
            </div>
            <div className={css(SlideStyles.MobileContentContainer)}>
                {textContent?.linksTitle && (
                    <Caption className={css(S.Subtitle)}>
                        {textContent.linksTitle}
                    </Caption>
                )}
                {textContent?.links && textContent.links.length > 0 && (
                    <Flex className={css(S.LinkRow)}>
                        {textContent.links
                            .filter((link) => link.url && link.title)
                            .map((link, i) => (
                                <Button
                                    key={`category-link-${link.url}-${i}`}
                                    className={css(S.Link)}
                                    variant="outlined"
                                    color="dark"
                                    as={'a'}
                                    size={16}
                                    href={link.url}
                                    variables={{
                                        buttonPadding: pxArrayToRem([8, 13]),
                                        buttonSize16LineHeight: pxToRem(15),
                                        darkOutlinedButtonBorderNormal,
                                    }}
                                >
                                    {link.title}
                                </Button>
                            ))}
                    </Flex>
                )}
            </div>
        </Flex>
    );
};

export default ImageSlideMobile;

import type {
    ImageCarouselItemAspect,
    ImageDimension,
} from '@modules/elemental-page/type';
import {
    IMAGE_TEXT_CAROUSEL_IMAGE_DIMENSIONS,
    IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS,
} from '@modules/elemental-page/config/module-constants';

export const getImageTextCarouselModuleDimension = (
    desktop: boolean,
    imageAspect: ImageCarouselItemAspect,
    asCarousel: boolean
): ImageDimension => {
    if (!desktop) {
        return asCarousel
            ? IMAGE_TEXT_CAROUSEL_IMAGE_DIMENSIONS.mobile
            : IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS.mobile;
    }

    switch (imageAspect) {
        case 'landscape':
            return asCarousel
                ? IMAGE_TEXT_CAROUSEL_IMAGE_DIMENSIONS.desktop.landscape
                : IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS.desktop.landscape;
        case 'square':
            return asCarousel
                ? IMAGE_TEXT_CAROUSEL_IMAGE_DIMENSIONS.desktop.square
                : IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS.desktop.square;
        case 'portrait':
            return asCarousel
                ? IMAGE_TEXT_CAROUSEL_IMAGE_DIMENSIONS.desktop.portrait
                : IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS.desktop.portrait;
        default:
            return IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS.desktop.landscape;
    }
};

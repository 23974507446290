import { useStyles } from '@archipro-design/aria';
import type { IframeEmbedModuleProps } from '@modules/elemental-page/type';
import * as S from './IframeEmbedModule.style';
import { MINIMUM_MODULE_HEIGHT } from '@modules/elemental-page/config/module-constants';
import React from 'react';

const IframeEmbedModule = ({
    title,
    embedLink,
    frameHeight,
    anchor,
}: IframeEmbedModuleProps) => {
    const [maxFrameHeight, setMaxFrameHeight] = React.useState(
        Math.max(frameHeight || 0, MINIMUM_MODULE_HEIGHT)
    );

    React.useEffect(() => {
        const handleFrameHeightUpdate = (event: { data: number }) => {
            if (!isNaN(event.data)) {
                const chooseMaxHeight = Math.max(
                    frameHeight || 0,
                    MINIMUM_MODULE_HEIGHT,
                    event.data
                );
                setMaxFrameHeight(chooseMaxHeight);
            }
        };

        window.addEventListener('message', handleFrameHeightUpdate);

        return () => {
            window.removeEventListener('message', handleFrameHeightUpdate);
        };
    }, [frameHeight]);

    const { css } = useStyles({ maxFrameHeight });

    if (!embedLink) {
        return null;
    }

    return (
        <div className={css(S.ModuleContainer)} id={anchor}>
            <iframe
                src={embedLink}
                width="100%"
                height={maxFrameHeight}
                frameBorder="0"
                scrolling="no"
                title={title || 'external content'}
            />
        </div>
    );
};

export default IframeEmbedModule;

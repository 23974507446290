import {
    useAppDisplayModeContext,
    Header,
    Subheader,
    Grid,
    useStyles,
    pxToRem,
    Image,
    Caption,
} from '@archipro-design/aria';
import * as S from './FramedPictureContentModule.style';
import type { FramedPictureContentProps } from '@modules/elemental-page/type';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { CustomArrowLeft, CustomArrowRight } from '@archipro-design/icons';
import { getBoundedImageDimension } from '@modules/elemental-page/util/get-bounded-image-dimension';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

const DEFAULT_FRAME_IMAGE_DESKTOP_WIDTH = 400;
const DEFAULT_FRAME_IMAGE_DESKTOP_HEIGHT = 300;

const FramedPictureContentModule = ({
    title,
    content,
    image,
    backgroundImage,
    milestones,
    cta,
    anchor,
}: FramedPictureContentProps) => {
    const { mobile } = useAppDisplayModeContext();

    const imageSrc =
        mobile && image?.mobileSrc ? image.mobileSrc : image?.desktopSrc;
    const backgroundSrc =
        mobile && backgroundImage?.mobileSrc
            ? backgroundImage.mobileSrc
            : backgroundImage?.desktopSrc || '';
    const { css } = useStyles({ backgroundSrc });

    const defaultDimensions = {
        width: DEFAULT_FRAME_IMAGE_DESKTOP_WIDTH,
        height: DEFAULT_FRAME_IMAGE_DESKTOP_HEIGHT,
    };
    const imageDimension = getBoundedImageDimension(
        mobile,
        defaultDimensions,
        image?.originalDesktopDimension,
        image?.originalMobileDimension
    );

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                {title && (
                    <Header
                        as={'h3'}
                        variables={(siteVars) => ({
                            fontSize: mobile ? pxToRem(20) : pxToRem(22),
                            ...siteVars?.textStyles.Heading.Medium65,
                            letterSpacing: '0.2em',
                            lineHeight: siteVars?.lineHeightSmall,
                        })}
                        className={css(S.Title)}
                    >
                        {title}
                    </Header>
                )}

                {imageSrc && (
                    <div
                        className={css(
                            S.ImageContainer(
                                imageDimension.width,
                                imageDimension.height
                            )
                        )}
                    >
                        <Image
                            src={imageSrc}
                            width={imageDimension.width}
                            height={imageDimension.height}
                            layout={'fill'}
                            objectFit={'cover'}
                            alt={''}
                        />
                    </div>
                )}

                {content && (
                    <Caption
                        variant={mobile ? '04' : '03'}
                        variables={{ lineHeight: 1.215 }}
                        className={css(S.Content)}
                    >
                        {content}
                    </Caption>
                )}

                {!!milestones?.length && (
                    <>
                        {mobile ? (
                            <>
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        nextEl: `.milestone-slider-button-next`,
                                        prevEl: `.milestone-slider-button-prev`,
                                    }}
                                    allowSlideNext
                                    allowSlidePrev
                                    allowTouchMove
                                    className={css(S.Slider)}
                                >
                                    {milestones.map((milestone, i) => (
                                        <SwiperSlide
                                            className={css(S.Slide)}
                                            key={`milestone-slide-${i}`}
                                        >
                                            <div className={css(S.Milestone)}>
                                                <Subheader>
                                                    {milestone.title}
                                                </Subheader>
                                                <Caption variant={'04'}>
                                                    {milestone.content}
                                                </Caption>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div>
                                    <button
                                        className={classnames(
                                            css(S.NavigateButton),
                                            'milestone-slider-button-prev'
                                        )}
                                    >
                                        <CustomArrowLeft />
                                    </button>
                                    <button
                                        className={classnames(
                                            css(S.NavigateButton),
                                            'milestone-slider-button-next'
                                        )}
                                    >
                                        <CustomArrowRight />
                                    </button>
                                </div>
                            </>
                        ) : (
                            <Grid
                                columns={mobile ? 1 : 3}
                                className={css(S.MilestoneGrid)}
                            >
                                {milestones.map((milestone) => (
                                    <div
                                        className={css(S.Milestone)}
                                        key={`milestone-${milestone.id}`}
                                    >
                                        <Subheader>{milestone.title}</Subheader>
                                        <Caption variant={'03'}>
                                            {milestone.content}
                                        </Caption>
                                    </div>
                                ))}
                            </Grid>
                        )}
                    </>
                )}
                <AboutCallToAction {...cta} buttonTheme={'dark'} />
            </div>
        </div>
    );
};

export default FramedPictureContentModule;

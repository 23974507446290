import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem, captionClassName } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const SimpleCTAContainer: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    padding: pxArrayToRem([0, 18]),
    textAlign: 'center',
    [theme.screen.laptop]: {
        padding: pxToRem(120),
    },
});

export const Title: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    color: guestHomeTheme(theme, darkTheme).main.text2,
    textTransform: 'uppercase',
    [theme.screen.max.laptop]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: pxToRem(22),
        lineHeight: '90%',
    },
    [theme.screen.laptop]: {
        margin: '0 auto',
        marginBottom: pxToRem(88),
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: pxToRem(62),
        lineHeight: 1,
    },
});

export const CTA: StyleRule = ({ theme }) => ({
    [theme.screen.max.laptop]: {
        [`& a`]: {
            padding: pxArrayToRem([24, 32]),
            margin: pxArrayToRem([24, 0]),
            borderRadius: 0,
            textTransform: 'uppercase',
            [`& .${captionClassName}`]: {
                letterSpacing: pxToRem(-0.65),
                [theme.screen.max.laptop]: {
                    ...theme.siteVariables.textStyles.GTAmericaTrialFont.Bold75,
                },
            },
        },
    },
    [theme.screen.laptop]: {
        marginBottom: 0,
    },
});

import React from 'react';
import { createContext } from 'react';
import type { ElementalPageActions } from '@modules/elemental-page/context/ElementalPageReducer';
import { ElementalPageReducer } from '@modules/elemental-page/context/ElementalPageReducer';

export interface ElementalPageState {
    modalOpen: boolean;
    demoURL: string;
    meetingBooked: boolean;
}

type ElementalPageDispatch = (action: ElementalPageActions) => void;

const ElementalPageContext = createContext<
    | {
          state: ElementalPageState;
          dispatch: ElementalPageDispatch;
      }
    | undefined
>(undefined);

interface ProviderWrapperProps {
    children?: React.ReactNode;
}

const ElementalPageProvider = ({ children }: ProviderWrapperProps) => {
    const [state, dispatch] = React.useReducer(ElementalPageReducer, {
        modalOpen: false,
        demoURL:
            'https://meetings.hubspot.com/tim750?embed=true&firstname=&lastname=&email=&phone=&company=',
        meetingBooked: false,
    });

    const value = { state, dispatch };
    return (
        <ElementalPageContext.Provider value={value}>
            {children}
        </ElementalPageContext.Provider>
    );
};

const useElementalPage = () => {
    const context = React.useContext(ElementalPageContext);
    if (context === undefined) {
        throw new Error(
            'useElementalPage must be used within a ElementalPageProvider'
        );
    }
    return context;
};

export { ElementalPageProvider, useElementalPage };

import type {
    ImageTextCarouselModuleProps,
    TextCarouselItem,
} from '@modules/elemental-page/type';
import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import * as S from './ImageTextCarouselModule.style';

import type { ImageCarouselItem } from '@modules/elemental-page/type';
import MainSlide from '@modules/elemental-page/component/image-text-carousel-module/MainSlide';
import type { NavigationType } from '@modules/elemental-page/component/module-slider/ModuleSlider';
import ModuleSlider from '@modules/elemental-page/component/module-slider/ModuleSlider';
import type { ThemedElementColourType } from '@modules/elemental-page/type';
import MainSlideMobile from '@modules/elemental-page/component/image-text-carousel-module/MainSlideMobile';
import ImageSlideMobile from '@modules/elemental-page/component/image-text-carousel-module/ImageSlideMobile';

interface ThemedImageTextCarouselModuleProps
    extends ImageTextCarouselModuleProps {
    id: string;
    theme: ThemedElementColourType;
}

const ImageTextCarouselModule = ({
    id,
    orientation,
    slimImageCol,
    imageCarouselItems,
    textCarouselItems,
    theme,
}: ThemedImageTextCarouselModuleProps) => {
    const darkTheme = theme === 'dark';
    const { desktop } = useAppDisplayModeContext();
    const { css } = useStyles({ orientation, darkTheme });

    const minSlides = Math.min(
        imageCarouselItems.length,
        textCarouselItems.length
    );
    const maxSlides = Math.max(
        imageCarouselItems.length,
        textCarouselItems.length
    );

    const isCarousel = minSlides > 1;

    // if module contains multiple images and text content, make them into slides.
    // slide can be 1 sided with only image or only text if they don't match in numbers
    const slides = isCarousel
        ? Array.from({ length: maxSlides }).map((_, i) => {
              const imageItem =
                  i < imageCarouselItems.length
                      ? (imageCarouselItems[i] as ImageCarouselItem)
                      : undefined;
              const textItem =
                  i < textCarouselItems.length
                      ? (textCarouselItems[i] as TextCarouselItem)
                      : undefined;
              return (
                  <>
                      {desktop ? (
                          <MainSlide
                              id={id}
                              orientation={orientation}
                              slimImageCol={slimImageCol}
                              imageCarouselItems={imageItem ? [imageItem] : []}
                              textContent={textItem}
                              renderAsSlide={true}
                              darkTheme={darkTheme}
                          />
                      ) : (
                          <MainSlideMobile
                              imageContent={imageItem}
                              textContent={textItem}
                              renderAsSlide={true}
                              darkTheme={darkTheme}
                          />
                      )}
                  </>
              );
          })
        : [];

    const carouselNavigation = desktop
        ? { navigationType: 'floating-buttons' as NavigationType }
        : {};

    return (
        <div className={css(S.ModuleContainer)}>
            {isCarousel ? (
                <div className={css(S.MainSlider)} data-testid="main-slider">
                    <ModuleSlider
                        moduleId={id}
                        slides={slides}
                        slidesPerView={desktop ? 1 : 1.28}
                        gap={18}
                        darkTheme={darkTheme}
                        {...carouselNavigation}
                    />
                </div>
            ) : (
                <>
                    {desktop ? (
                        <MainSlide
                            id={id}
                            orientation={orientation}
                            slimImageCol={slimImageCol}
                            imageCarouselItems={imageCarouselItems}
                            textContent={textCarouselItems[0]}
                            renderAsSlide={imageCarouselItems.length > 1}
                            darkTheme={darkTheme}
                        />
                    ) : (
                        <ImageSlideMobile
                            id={id}
                            imageCarouselItems={imageCarouselItems}
                            textContent={textCarouselItems[0]}
                            darkTheme={darkTheme}
                            renderAsSlide={imageCarouselItems.length > 1}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ImageTextCarouselModule;

import {
    Caption,
    Button,
    Flex,
    useStyles,
    pxArrayToRem,
    pxToRem,
    useTheme,
} from '@archipro-design/aria';

import * as S from './TextContent.style';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';
import type { Link } from '@modules/elemental-page/type';

interface LinkTagsProps {
    linksTitle?: string;
    links?: Link[];
    darkTheme: boolean;
}

const LinkTags = ({ links, linksTitle, darkTheme }: LinkTagsProps) => {
    const { css } = useStyles({ darkTheme });
    const theme = useTheme();
    const darkOutlinedButtonBorderNormal = guestHomeTheme(theme, darkTheme)
        .button.border2;

    return (
        <div className={css(S.Links)}>
            {linksTitle && (
                <Caption className={css(S.Subtitle)}>{linksTitle}</Caption>
            )}
            {links && (
                <Flex className={css(S.LinkRow)}>
                    {links
                        .filter((link) => link.url && link.title)
                        .map((link, i) => (
                            <Button
                                key={`category-link-${link.url}-${i}`}
                                className={css(S.Link)}
                                variant="outlined"
                                color="dark"
                                as={'a'}
                                size={16}
                                href={link.url}
                                variables={{
                                    buttonPadding: pxArrayToRem([8, 13]),
                                    buttonSize16LineHeight: pxToRem(15),
                                    darkOutlinedButtonBorderNormal,
                                }}
                            >
                                {link.title}
                            </Button>
                        ))}
                </Flex>
            )}
        </div>
    );
};

export default LinkTags;

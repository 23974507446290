import {
    useAppDisplayModeContext,
    Header,
    Subheader,
    Grid,
    useStyles,
    pxToRem,
    Caption,
} from '@archipro-design/aria';
import * as S from './MilestoneModule.style';
import type {
    MilestoneModuleProps,
    ThemedElementColourType,
} from '@modules/elemental-page/type';
import React from 'react';

interface ThemedMilestoneModuleProps extends MilestoneModuleProps {
    id: string;
    theme: ThemedElementColourType;
}

const MilestoneModule = ({
    title,
    milestones,
    id,
    theme,
}: ThemedMilestoneModuleProps) => {
    const darkTheme = theme === 'dark';
    const { mobile } = useAppDisplayModeContext();
    const { css } = useStyles({ darkTheme });

    return (
        <div className={css(S.MilestoneModule)}>
            {title && (
                <Header
                    as={'h3'}
                    variables={(siteVars) => ({
                        fontSize: mobile ? pxToRem(20) : pxToRem(22),
                        ...siteVars?.textStyles.Heading.Medium65,
                        letterSpacing: '0.2em',
                        lineHeight: siteVars?.lineHeightSmall,
                    })}
                    className={css(S.Title)}
                >
                    {title}
                </Header>
            )}
            <Grid columns={mobile ? 1 : 3}>
                {milestones?.map((milestone) => (
                    <div
                        className={css(S.Milestone)}
                        key={`module-${id}-milestone-${milestone.id}`}
                    >
                        <Subheader>{milestone.title}</Subheader>
                        <Caption variant={'03'}>{milestone.content}</Caption>
                    </div>
                ))}
            </Grid>
        </div>
    );
};

export default MilestoneModule;

import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    SubheaderClassName,
    captionClassName,
} from '@archipro-design/aria';

export const Module: StyleRule<{ backgroundSrc: string }> = ({
    theme,
    backgroundSrc,
}) => {
    return {
        position: 'relative',
        [theme.screen.laptop]: {
            backgroundImage: `url(${backgroundSrc})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            padding: pxArrayToRem([180, 0]),
        },
    };
};

export const Container: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        maxWidth: pxToRem(1000),
        margin: 'auto',
        textAlign: 'center',
        padding: pxArrayToRem([0, 18]),
        [theme.screen.laptop]: {
            backgroundColor: theme.siteVariables.colors.primitive.white,
            padding: pxArrayToRem([60, 32, 62]),
        },
    };
};

export const ImageContainer: (width: number, height: number) => StyleRule =
    (width, height) =>
    ({ theme }) => {
        return {
            position: 'relative',
            margin: `${pxToRem(30)} auto`,
            width: pxToRem(width),
            height: pxToRem(height),
            [theme.screen.laptop]: {
                margin: `${pxToRem(60)} auto`,
            },
        };
    };

export const Title: StyleRule = ({ theme }) => {
    return {
        textTransform: 'uppercase',
        marginTop: 0,
        marginBottom: pxToRem(30),
        maxWidth: pxToRem(420),
        margin: 'auto',
        fontSize: pxToRem(20),
        ...theme.siteVariables.textStyles.Heading.Medium65,

        [theme.screen.laptop]: {
            marginBottom: pxToRem(60),
            maxWidth: pxToRem(490),
            fontSize: pxToRem(22),
        },
    };
};

export const Content: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        maxWidth: pxToRem(780),
        margin: `${pxToRem(30)} auto`,
        padding: pxArrayToRem([0, 6]),
        [theme.screen.laptop]: {
            margin: `${pxToRem(60)} auto`,
            padding: 0,
            lineHeight: 1.6,
        },
    };
};

export const MilestoneGrid: StyleRule = ({ theme }) => {
    return {
        '& > div:not(:last-child)': {
            borderRight: `1px solid ${theme.siteVariables.colors.primitive.black}`,
        },
    };
};

export const Milestone: StyleRule = ({ theme }) => {
    return {
        width: '100%',
        [`& .${SubheaderClassName}`]: {
            ...theme.siteVariables.textStyles.Heading.Medium65,
            fontSize: theme.siteVariables.fontSizes.caption01,
            lineHeight: theme.siteVariables.lineHeightDefault,
            textTransform: 'uppercase',
            paddingTop: pxToRem(30),
        },
        [`& .${captionClassName}`]: {
            padding: pxArrayToRem([30, 32]),
            [theme.screen.laptop]: {
                padding: pxArrayToRem([20, 16, 10]),
                lineHeight: theme.siteVariables.lineHeightSmallest,
            },
        },
    };
};

export const Slider: StyleRule = () => {
    return {
        marginTop: pxToRem(70),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };
};

export const Slide: StyleRule = () => {
    return {
        whiteSpace: 'normal',
        width: '100%',
        display: 'inline-block',
    };
};

export const NavigateButton: StyleRule = ({ theme }) => {
    return {
        border: 'none',
        background: 'transparent',
        fill: theme.siteVariables.colors.primitive.black,
        [`& path`]: {
            fill: theme.siteVariables.colors.primitive.black,
        },
    };
};

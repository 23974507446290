import { useStyles } from '@archipro-design/aria';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

import * as S from './TextContent.style';
import type { CallToAction } from '@modules/elemental-page/type';

interface MainCtaProps {
    cta?: CallToAction;
    darkTheme: boolean;
}

const MainCta = ({ cta, darkTheme }: MainCtaProps) => {
    const { css } = useStyles({ darkTheme });
    return (
        <>
            {cta && (
                <div className={css(S.CTA)}>
                    <AboutCallToAction
                        {...cta}
                        buttonTheme={darkTheme ? 'light' : 'dark'}
                    />
                </div>
            )}
        </>
    );
};

export default MainCta;

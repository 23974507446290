import {
    Image,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './ProfessionalMembersModule.style';
import type {
    ProfessionalMembersModuleProps,
    ThemedElementColourType,
} from '@modules/elemental-page/type';

import type { NavigationType } from '@modules/elemental-page/component/module-slider/ModuleSlider';
import ModuleSlider from '@modules/elemental-page/component/module-slider/ModuleSlider';
import { Link } from '@remix-run/react';
import { CustomArrowRight } from '@archipro-design/icons';
import TextCtaSplitContent from '@modules/elemental-page/component/text-cta-content/TextCtaSplitContent';
import TextContent from '@modules/elemental-page/component/text-cta-content/TextContent';
import MainCta from '@modules/elemental-page/component/text-cta-content/MainCta';
import LinkTags from '@modules/elemental-page/component/text-cta-content/LinkTags';

interface ThemedProfessionalMembersModuleProps
    extends ProfessionalMembersModuleProps {
    id: string;
    theme: ThemedElementColourType;
}

export const TEAM_MEMBER_IMAGE_DIMENSION = {
    desktop: {
        width: 328,
        height: 410,
    },
    mobile: {
        width: 294,
        height: 269,
    },
};

const ProfessionalMembersModule = ({
    id,
    theme,
    textContent,
    teamMembers,
}: ThemedProfessionalMembersModuleProps) => {
    const darkTheme = theme === 'dark';
    const { desktop } = useAppDisplayModeContext();
    const dimension = desktop
        ? TEAM_MEMBER_IMAGE_DIMENSION.desktop
        : TEAM_MEMBER_IMAGE_DIMENSION.mobile;
    const { css } = useStyles({ darkTheme });

    const carouselNavigation = desktop
        ? { navigationType: 'floating-buttons' as NavigationType }
        : {};

    return (
        <div className={css(S.ProfessionalMembersModule)}>
            {textContent && (
                <div className={css(S.TopSection)}>
                    {desktop ? (
                        <TextCtaSplitContent
                            item={textContent}
                            darkTheme={darkTheme}
                        />
                    ) : (
                        <TextContent {...textContent} darkTheme={darkTheme} />
                    )}
                </div>
            )}
            <div className={css(S.MembersSection)}>
                <div className={css(S.SliderContainer)}>
                    <ModuleSlider
                        moduleId={id}
                        slides={teamMembers.map((teamMember) => (
                            <Link
                                key={`link-${teamMember.id}`}
                                to={teamMember.professionalLink || ''}
                                target={'_self'}
                            >
                                <div className={css(S.ImageContainer)}>
                                    <Image
                                        key={teamMember.id}
                                        {...dimension}
                                        layout={'fill'}
                                        objectFit={'cover'}
                                        src={teamMember.memberImage || ''}
                                        alt={'img-alt'}
                                    />
                                </div>
                                <div className={css(S.Profile)}>
                                    <p className={css(S.Name)}>
                                        {teamMember.name}
                                    </p>
                                    <p className={css(S.ProfessionalTitle)}>
                                        {teamMember.professionalTitle}
                                    </p>
                                    {desktop && (
                                        <Link
                                            to={
                                                teamMember.professionalLink ||
                                                ''
                                            }
                                            target={'_self'}
                                            className={css(S.Link)}
                                        >
                                            Read more
                                            <CustomArrowRight />
                                        </Link>
                                    )}
                                </div>
                            </Link>
                        ))}
                        slidesPerView={desktop ? 5 : 1.28}
                        gap={desktop ? 9 : 18}
                        darkTheme={darkTheme}
                        {...carouselNavigation}
                    />
                </div>
            </div>
            {!desktop && (
                <div className={css(S.BottomSection)}>
                    <MainCta {...textContent} darkTheme={darkTheme} />
                    <LinkTags {...textContent} darkTheme={darkTheme} />
                </div>
            )}
        </div>
    );
};

export default ProfessionalMembersModule;

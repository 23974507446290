import { Caption, Flex, useStyles } from '@archipro-design/aria';
import * as SlideStyles from './MainSlide.style';
import * as TextStyles from '@modules/elemental-page/component/text-cta-content/TextContent.style';
import type { TextCarouselItem } from '@modules/elemental-page/type';

import ImageSlide from '@modules/elemental-page/component/image-text-carousel-module/ImageSlide';
import type { ImageCarouselItem } from '@modules/elemental-page/type';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

interface MainSlideMobileProps {
    imageContent?: ImageCarouselItem;
    textContent?: TextCarouselItem;
    renderAsSlide?: boolean;
    darkTheme: boolean;
}

const MainSlideMobile = ({
    imageContent,
    textContent,
    renderAsSlide,
    darkTheme,
}: MainSlideMobileProps) => {
    const { css } = useStyles({ darkTheme });

    return (
        <Flex column>
            {imageContent && (
                <div className={css(SlideStyles.ExtraSpacer)}>
                    <ImageSlide
                        item={imageContent}
                        darkTheme={darkTheme}
                        renderAsSlide={renderAsSlide}
                    />
                </div>
            )}
            {textContent?.subtitle && (
                <Caption className={css(TextStyles.Subtitle)}>
                    {textContent.subtitle}
                </Caption>
            )}
            {textContent?.title && (
                <Caption className={css(TextStyles.Title)}>
                    {textContent.title}
                </Caption>
            )}
            {textContent?.content && (
                <Caption className={css(TextStyles.Content)}>
                    {textContent.content}
                </Caption>
            )}
            {textContent?.cta && (
                <div className={css(TextStyles.CTA)}>
                    <AboutCallToAction
                        {...textContent.cta}
                        buttonTheme={darkTheme ? 'light' : 'dark'}
                    />
                </div>
            )}
        </Flex>
    );
};

export default MainSlideMobile;

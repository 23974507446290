import { pxArrayToRem, pxToRem, type StyleRule } from '@archipro-design/aria';
import { extractValueFromClass } from '../../util/extract-value-from-class';

export const ModuleContainer: StyleRule<{ maxFrameHeight: number }> = ({
    maxFrameHeight,
    theme,
}) => {
    return {
        width: '100%',
        margin: pxToRem(20),
        padding: pxToRem(20),
        background: 'white',
        textAlign: 'center',
        minHeight: pxToRem(maxFrameHeight),
        [theme.screen.tablet]: {
            padding: pxToRem(40),
            width: pxToRem(528),
            margin: 0,
            input: {
                margin: pxArrayToRem([20, 0]),
            },
        },
    };
};

export const Background: StyleRule<{
    customClassName?: string | null;
}> = ({ customClassName }) => {
    return {
        backgroundColor: customClassName
            ? extractValueFromClass('bg', customClassName)
            : '#1c1c1c',
    };
};

export const CampaignTop: StyleRule = ({ theme }) => {
    return {
        padding: pxArrayToRem([20, 0]),
        [theme.screen.tablet]: {
            padding: pxArrayToRem([40, 0]),
        },
        '& svg': {
            width: pxToRem(40),
            height: pxToRem(40),
            [theme.screen.tablet]: {
                width: pxToRem(90),
                height: pxToRem(90),
            },
        },
    };
};

export const FormContainer: StyleRule<{ backgroundImageUrl: string }> = ({
    theme,
    backgroundImageUrl,
}) => {
    return {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: pxArrayToRem([20, 0]),
        [theme.screen.tablet]: {
            padding: 0,
            backgroundImage: 'none',
        },
    };
};
export const CampaignHeaderContainer: StyleRule<{
    backgroundImageUrl: string;
    customClassName?: string | null;
}> = ({ theme, backgroundImageUrl, customClassName }) => {
    return {
        minHeight: '100vh',
        '@supports (minHeight: 100dvh)': {
            minHeight: '100dvh',
        },
        backgroundColor: customClassName
            ? extractValueFromClass('bg', customClassName)
            : '#1c1c1c',
        [theme.screen.tablet]: {
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
    };
};

export const SpacerBottom: StyleRule = ({ theme }) => {
    return {
        height: pxToRem(90),
        [theme.screen.tablet]: {
            height: pxToRem(160),
        },
    };
};

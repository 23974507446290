import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const ModuleContainer: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    position: 'relative',
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    [theme.screen.tablet]: {
        paddingTop: pxToRem(80),
        paddingBottom: pxToRem(80),
    },
});

export const MainSlider: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([0, 18]),
    [theme.screen.tablet]: {
        padding: 0,
        [`& .swiper-wrapper`]: {
            display: 'flex',
            alignItems: 'center',
        },
    },
});

import { pxToRem } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

export const BaseSection: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        paddingTop: pxToRem(58),
        paddingBottom: `${pxToRem(10)}`,
        [theme.screen.laptop]: {
            paddingBottom: `${pxToRem(51)}`,
        },
    };
};

export const TestimonialSlider = () => {
    return {
        whiteSpace: 'nowrap', //todo swiper css fix to be removed when swapping to aria carousel
        overflow: 'hidden',
    };
};

export const TestimonialSlide = () => {
    return {
        whiteSpace: 'normal',
        width: '100%',
        display: 'inline-block',
    };
};

export const TestimonialSlideInner: StyleRule = ({ theme }) => {
    return {
        width: '100%',
        [theme.screen.laptop]: {
            display: 'flex',
        },
    };
};

export const SlideColumn: StyleRule = ({ theme }) => {
    return {
        margin: `0 0 ${pxToRem(70)} 0`,
        [theme.screen.laptop]: {
            flexBasis: '50%',
        },
    };
};

export const SlideColumnMobile = () => {
    return {
        marginBottom: pxToRem(50),
    };
};

export const TestimonialPhotoDesktop: StyleRule = ({ theme }) => {
    return {
        margin: 'auto',
        display: 'none',
        [theme.screen.tablet]: {
            width: pxToRem(350),
            height: 'auto',
            display: 'block',
            marginLeft: 0,
            marginRight: 'auto',
        },
        [theme.screen.laptop]: {
            display: 'block',
            width: `${pxToRem(586)} !important`, //TODO: fix when media queris mobile first is fixed
            height: `${pxToRem(765)} !important`, //TODO: fix when media queris mobile first is fixed
            marginLeft: `${pxToRem(154)} !important`, //TODO: fix when media queris mobile first is fixed
            marginRight: `${pxToRem(185)} !important`, //TODO: fix when media queris mobile first is fixed
        },
    };
};

export const TestimonialPhotoMobile: StyleRule = ({ theme }) => {
    return {
        width: pxToRem(248),
        height: pxToRem(323),
        display: 'block',
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

export const TestimonialBody: StyleRule = ({ theme }) => {
    return {
        color: theme.siteVariables.colors.charcoal['250'],
        fontSize: theme.siteVariables.fontSizes.caption01,
        lineHeight: theme.siteVariables.lineHeightSmall,
        marginBottom: pxToRem(32),
        [theme.screen.laptop]: {
            fontSize: pxToRem(40),
            lineHeight: theme.siteVariables.lineHeightSmaller,
            marginTop: pxToRem(98),
            marginBottom: pxToRem(120),
        },
    };
};

export const TestimonialFooter: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        paddingTop: pxToRem(21),
        '& p': {
            margin: 0,
            letterSpacing: theme.siteVariables.letterSpacingMedium,
            fontSize: theme.siteVariables.fontSizes.caption02,
            lineHeight: pxToRem(21),
        },
        [theme.screen.laptop]: {
            paddingTop: pxToRem(68),
            '& p': {
                fontSize: theme.siteVariables.fontSizes.paragraph01,
                lineHeight: pxToRem(35),
            },
        },
    };
};

export const TestimonialDivider: StyleRule = ({ theme }) => {
    return {
        width: pxToRem(46),
        height: pxToRem(2),
        background: theme.siteVariables.colors.charcoal['250'],
        position: 'absolute',
        top: 0,
        [theme.screen.laptop]: {
            width: pxToRem(77),
            height: pxToRem(6),
        },
    };
};

export const TestimonialSliderControls: StyleRule = ({ theme }) => {
    return {
        zIndex: theme.siteVariables.zIndexes.overlay,
        position: 'absolute',
        bottom: pxToRem(60),
        right: pxToRem(4),
        [theme.screen.laptop]: {
            bottom: pxToRem(186),
            right: pxToRem(109),
        },
    };
};

export const ButtonSlider: StyleRule = () => {
    return {
        border: 'none',
        background: 'transparent',
        padding: 0,
    };
};

export const TestimonialSliderPrev: StyleRule = ({ theme }) => {
    return {
        height: pxToRem(31.54),
        width: pxToRem(27.6),
        margin: `0 ${pxToRem(14)}`,
        '> svg': {
            stroke: theme.siteVariables.colors.charcoal['250'],
            strokeWidth: 1,
        },
        [theme.screen.laptop]: {
            height: pxToRem(58),
            width: pxToRem(58),
            cursor: 'pointer',
            margin: `0 ${pxToRem(22)}`,
        },
    };
};

import {
    useAppDisplayModeContext,
    Image,
    useStyles,
} from '@archipro-design/aria';
import * as S from './PhotoCollageModule.style';
import React from 'react';
import type { PhotoCollageModuleProps } from '@modules/elemental-page/type';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

const PhotoCollageModule = ({
    galleryImages,
    cta,
    anchor,
}: PhotoCollageModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    const collagePhotoSrc = galleryImages?.slice(0, 9).map((image) => {
        if (mobile && image.mobileSrc) {
            return image.mobileSrc;
        } else {
            return image.desktopSrc || '';
        }
    });

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                <div className={css(S.PhotoCollage)}>
                    {collagePhotoSrc &&
                        collagePhotoSrc.map((src, i) => (
                            <div
                                className={css(S.Frame)}
                                key={`collage-image-${i}`}
                            >
                                <Image
                                    key={`collage-photo-${i}`}
                                    src={src}
                                    width={mobile ? 125 : 328}
                                    height={mobile ? 125 : 328}
                                    layout={'fill'}
                                    objectFit={'cover'}
                                    alt={''}
                                />
                            </div>
                        ))}
                </div>
                <AboutCallToAction {...cta} buttonTheme={'dark'} />
            </div>
        </div>
    );
};

export default PhotoCollageModule;

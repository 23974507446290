import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { NEW_BLACK_01 } from '@modules/elemental-page/config/about-us-style-constants';

export const Module: StyleRule = () => {
    return {
        position: 'relative',
    };
};

export const Container: StyleRule = () => {
    return {
        position: 'relative',
        maxWidth: pxToRem(1000),
        margin: 'auto',
        textAlign: 'center',
    };
};

export const Slider: StyleRule = () => {
    return {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };
};

export const Slide: StyleRule = () => {
    return {
        whiteSpace: 'normal',
        width: '100%',
        display: 'inline-block',
    };
};

export const Testimonial: StyleRule = ({ theme }) => {
    return {
        ...theme.siteVariables.textStyles.Heading.Roman55,
        fontSize: theme.siteVariables.fontSizes.caption03,
        lineHeight: theme.siteVariables.lineHeightSmall,
        color: NEW_BLACK_01,
        marginBottom: pxToRem(30),
        padding: pxArrayToRem([0, 18]),
        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes.caption01,
            marginBottom: pxToRem(60),
            padding: 0,
        },
        [`& > div`]: {
            display: 'inline',
        },
    };
};

export const Author: StyleRule = ({ theme }) => {
    return {
        marginBottom: pxToRem(30),
        [theme.screen.laptop]: {
            marginBottom: pxToRem(60),
        },
    };
};

export const Quotes: StyleRule = ({ theme }) => {
    return {
        ...theme.siteVariables.textStyles.Heading.Roman55,
    };
};
export const AuthorName: StyleRule = ({ theme }) => {
    return {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
    };
};

export const AuthorType: StyleRule = ({ theme }) => {
    return {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        textTransform: 'uppercase',
    };
};

export const ImageContainer: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        margin: 'auto',
        width: pxToRem(231),
        height: pxToRem(219),
        marginBottom: pxToRem(30),
        [theme.screen.laptop]: {
            width: pxToRem(559),
            height: pxToRem(528),
            marginBottom: pxToRem(60),
        },
    };
};

export const NavigateButton: StyleRule = ({ theme }) => {
    return {
        border: 'none',
        background: 'transparent',
        fill: theme.siteVariables.colors.primitive.black,
        [`& path`]: {
            fill: theme.siteVariables.colors.primitive.black,
        },
    };
};

import { Image, useDeviceTypeContext, useStyles } from '@archipro-design/aria';
import { HeadingModuleContainer } from './HeadingModule.style';
import type { HeadingModuleProps } from '@modules/elemental-page/type';
import DefaultHeading from './default-heading.svg?url';

const HeadingModule = ({
    title,
    headingLevel = 'h2',
    anchor,
}: HeadingModuleProps) => {
    const { css } = useStyles();
    const HeadingTag = headingLevel as keyof JSX.IntrinsicElements;
    const { mobile } = useDeviceTypeContext();

    return (
        <div id={anchor} className={css(HeadingModuleContainer)}>
            {title ? (
                <HeadingTag>{title}</HeadingTag>
            ) : (
                <Image
                    width={mobile ? 353 : 851}
                    height={mobile ? 42 : 102}
                    src={DefaultHeading}
                    alt="ArchiPro"
                />
            )}
        </div>
    );
};

export default HeadingModule;

import type { CustomShorthandImageCollection } from '@archipro-design/aria';
import {
    useAppDisplayModeContext,
    useStyles,
    BannerAdTile,
} from '@archipro-design/aria';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import React, { useRef } from 'react';
import type { VideoModuleProps } from '@modules/elemental-page/type';
import { Link } from '@remix-run/react';
import { useIsWebmLegacyDevice } from '@modules/root/hook/use-is-webm-legacy-device';
import { useCompatibleVideoSources } from '@modules/root/hook/use-compatible-video-sources';
import { useHydrated } from 'remix-utils';
import { useInViewport } from 'ahooks';
import { useUser } from '@modules/user';
import { useLoginModal } from '@modules/design-board';
import { VideoOverlay } from '@modules/home/component/inspiration-module/InspirationModule.style';
import * as S from './VideoModule.style';

const HostedVideoModule = ({
    videoMp4Url,
    videoWebMUrl,
    videoCover,
    anchor,
    autoplay,
    autoPlayRedirect,
    autoPlayRedirectRequireLogin,
}: VideoModuleProps) => {
    const ref = useRef(null);
    const [inViewport] = useInViewport(ref, {
        threshold: 0.75,
    });
    const { css } = useStyles({ inViewport });

    const { desktop } = useAppDisplayModeContext();
    const isWebmLegacyDevice = useIsWebmLegacyDevice();
    const isHydrated = useHydrated();
    const videoSources = useCompatibleVideoSources(isWebmLegacyDevice, [
        videoWebMUrl || '',
        videoMp4Url || '',
    ]);
    const user = useUser();
    const isLoggedIn = user.__typename === 'Me';
    const canLogin = !isLoggedIn && autoPlayRedirectRequireLogin;
    const { showLoginModal, loginModal } = useLoginModal({
        customRedirect: autoPlayRedirect,
        allowExternalUrls: true,
        authSource: 'videoModule',
    });

    const redirectProps = autoPlayRedirect
        ? {
              as: Link,
              to: autoPlayRedirect,
              onClick: (e: MouseEvent) => {
                  if (autoPlayRedirectRequireLogin) {
                      e.preventDefault();
                      showLoginModal();
                  }
              },
          }
        : {};

    const images: CustomShorthandImageCollection = [
        {
            alt: `video cover image`,
            src: videoCover?.desktopSrc || '',
            width: desktop ? 1820 : 414,
            height: desktop ? 811 : 264,
            loading: 'lazy',
            sizes: getImagesSizes({
                desktop: 1820,
                mobile: 414,
            }),
            overlay: {
                children: <div />,
                prefetch: 'intent',
                icon: <div />,
                alwaysVisible: true,
                variables: {
                    backgroundColor: 'transparent',
                },
            },
        },
    ];

    return (
        <div ref={ref} id={anchor} className={css(S.HostedVideoModule)}>
            <BannerAdTile
                variant={'01'}
                images={images}
                size={'medium'}
                title={''}
                description={''}
                company={''}
                actionButton={''}
                {...(videoSources &&
                    isHydrated && {
                        tileVideo: {
                            src: videoSources,
                            width: desktop ? 1820 : 414,
                            height: desktop ? 811 : 274,
                            playing: autoplay && inViewport,
                            hoverTrigger: null,
                            playsInline: true,
                            topContent: <div className={css(VideoOverlay)} />,
                            ...redirectProps,
                            hideFavourite: true,
                            lazy: false,
                            poster: videoCover?.desktopSrc || '',
                        },
                    })}
            />

            {canLogin && loginModal}
        </div>
    );
};

export default HostedVideoModule;

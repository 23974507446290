import type { StyleRule } from '@archipro-design/aria';
import { paragraphClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const TitleContainer: StyleRule = ({ theme }) => {
    return {
        marginTop: pxToRem(25),

        [theme.screen.laptop]: {
            marginTop: pxToRem(36),
            [`& .${paragraphClassName}`]: {
                ...theme.siteVariables.textStyles.Heading.Medium65,
                textTransform: 'uppercase',
                fontSize: pxToRem(20),
            },
        },
    };
};

export const SummaryContainer: StyleRule = ({ theme }) => {
    return {
        maxWidth: pxToRem(339),
        marginTop: pxToRem(8),

        [`& .${paragraphClassName}`]: {
            fontSize: pxToRem(20),
            whiteSpace: 'pre-wrap',
            lineHeight: theme.siteVariables.lineHeightSmall,
        },

        [theme.screen.tablet]: {
            maxWidth: 'unset',
        },
        [theme.screen.laptop]: {
            marginTop: pxToRem(4),

            [`& .${paragraphClassName}`]: {
                fontSize: theme.siteVariables.fontSizes.label02,
            },
        },
    };
};

export const DesktopImageStyle: StyleRule = ({ theme }) => {
    return {
        display: 'none',
        [theme.screen.tablet]: {
            display: 'block',
        },
    };
};

export const MobileImageStyle: StyleRule = ({ theme }) => {
    return {
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

export const TitleParagraph: StyleRule = ({ theme }) => {
    return {
        fontSize: theme.siteVariables.fontSizes.label02,
        fontWeight: 400,
        lineHeight: theme.siteVariables.lineHeightSmall,
        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes.paragraph01,
        },
    };
};

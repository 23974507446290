import type { ImageDimension } from '@modules/elemental-page/type';
import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import { getImageTextCarouselModuleDimension } from '@modules/elemental-page/util/get-image-text-carousel-module-dimension';
import { Image } from '@archipro-design/aria';
import ImageCaption from '@modules/elemental-page/component/image-text-carousel-module/ImageCaption';
import * as S from './ImageSlide.style';

import type { ImageCarouselItem } from '@modules/elemental-page/type';
import type { ImageTextCarouselModuleOrientation } from '@modules/elemental-page/type';

interface ImageSlideProps {
    item: ImageCarouselItem;
    orientation?: ImageTextCarouselModuleOrientation;
    renderAsSlide?: boolean;
    darkTheme: boolean;
}

const ImageSlide = ({
    item,
    orientation = 'image-text',
    renderAsSlide,
    darkTheme,
}: ImageSlideProps) => {
    const { desktop } = useAppDisplayModeContext();
    const mobileSrc = item.image?.mobileSrc || item.image?.desktopSrc;
    const imageSrc = desktop ? item.image?.desktopSrc : mobileSrc;
    const dimension: ImageDimension = getImageTextCarouselModuleDimension(
        desktop,
        item.imageAspect || 'landscape',
        !!renderAsSlide
    );

    const { css } = useStyles({
        dimension,
        orientation,
        imageAspect: item.imageAspect,
    });

    const image = imageSrc ? (
        <Image
            key={item.id}
            width={dimension.width}
            height={dimension.height}
            layout={'fill'}
            objectFit={'cover'}
            placeholderColor="transparent"
            src={imageSrc}
            alt={'img-alt'}
        />
    ) : null;

    const caption = renderAsSlide ? (
        <ImageCaption
            mainCaption={item.captionOne}
            subCaption={item.captionTwo}
            darkTheme={darkTheme}
        />
    ) : null;

    return (
        <>
            <div className={css(S.ImageContainer)}>{image}</div>
            {caption}
        </>
    );
};

export default ImageSlide;

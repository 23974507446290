import type { StyleRule } from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import * as S from './EcommerceLink.style';
import { ArrowNextLine, ArrowRightLine } from '@archipro-design/icons';
import { Button } from '@archipro-design/aria';
import type { CallToAction } from '@modules/elemental-page/type';

interface EcommerceLinkProps extends CallToAction {
    style?: StyleRule;
    color?: 'white' | 'black';
    onClick: () => void;
}

const EcommerceLink = ({
    style = {},
    title,
    link,
    onClick,
    color = 'white',
}: EcommerceLinkProps) => {
    const { css } = useStyles({ color });

    if (onClick) {
        return (
            <>
                <Button
                    variant={'text'}
                    variables={{ buttonPadding: 0 }}
                    iconAfter={<ArrowNextLine />}
                    size={36}
                    onClick={onClick}
                    className={css(S.DesktopButton, style)}
                >
                    {title}
                </Button>
                <Button
                    size={24}
                    onClick={onClick}
                    className={css(S.MobileButton, style)}
                >
                    {title}
                </Button>
            </>
        );
    } else if (link) {
        return (
            <a className={css(S.LinkStyle, style)} href={link}>
                {title}
                <ArrowRightLine className={css(S.LinkIcon)} />
            </a>
        );
    }

    return null;
};

export default EcommerceLink;

import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';
import { TEAM_MEMBER_IMAGE_DIMENSION } from '@modules/elemental-page/component/professional-members-module/ProfessionalMembersModule';

export const ProfessionalMembersModule: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    position: 'relative',
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    display: 'flex',
    flexDirection: 'column',
    [theme.screen.tablet]: {
        paddingTop: pxToRem(80),
        paddingBottom: pxToRem(47),
    },
});

export const TopSection: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([0, 16]),
    [theme.screen.tablet]: {
        margin: pxArrayToRem([0, 120]),
        marginBottom: pxToRem(28),
    },
});

export const BottomSection: StyleRule = () => ({
    margin: pxArrayToRem([0, 16]),
    [`& > div > a`]: {
        margin: pxArrayToRem([13, 0, 38, 0]),
    },
});

export const MembersSection: StyleRule = () => ({
    position: 'relative',
});

export const SliderContainer: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    margin: pxArrayToRem([0, 16]),
    [`& .swiper-pagination-bullet`]: {
        backgroundColor: '#BBB',
        [`&-active`]: {
            backgroundColor: guestHomeTheme(theme, darkTheme).main.text2,
        },
    },
    [theme.screen.tablet]: {
        margin: pxArrayToRem([0, 120]),
        [`& button`]: {
            top: pxToRem(205),
        },
    },
});

export const ImageContainer: StyleRule = ({ theme }) => ({
    position: 'relative',
    height: pxToRem(TEAM_MEMBER_IMAGE_DIMENSION.mobile.height),
    width: pxToRem(TEAM_MEMBER_IMAGE_DIMENSION.mobile.width),
    [`& img`]: {
        filter: 'grayscale(100%)',
    },
    [theme.screen.tablet]: {
        height: pxToRem(TEAM_MEMBER_IMAGE_DIMENSION.desktop.height),
        width: pxToRem(TEAM_MEMBER_IMAGE_DIMENSION.desktop.width),
    },
});

export const Profile: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(12),
    [theme.screen.tablet]: {
        padding: pxArrayToRem([0, 6]),
        marginTop: pxToRem(35),
        marginBottom: 0,
    },
});
export const Name: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    color: guestHomeTheme(theme, darkTheme).main.text2,
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.screen.max.tablet]: {
        ...theme.siteVariables.textStyles.Text.Medium65,
        fontSize: theme.siteVariables.fontSizes.caption04,
    },
    [theme.screen.tablet]: {
        textTransform: 'uppercase',
        ...theme.siteVariables.textStyles.Heading.Medium65,
    },
});

export const ProfessionalTitle: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    color: guestHomeTheme(theme, darkTheme).main.text,
    marginTop: 0,
    marginBottom: pxToRem(24),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.screen.max.tablet]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        fontSize: theme.siteVariables.fontSizes.caption04,
    },
    [theme.screen.tablet]: {
        textTransform: 'uppercase',
        ...theme.siteVariables.textStyles.Heading.Roman55,
        fontSize: theme.siteVariables.fontSizes.caption03s,
    },
});

export const Link: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    textTransform: 'uppercase',
    ...theme.siteVariables.textStyles.Heading.Medium65,
    color: guestHomeTheme(theme, darkTheme).main.text,
    fontSize: theme.siteVariables.fontSizes.caption03s,
    textDecoration: 'none',
    display: 'flex',
    gap: pxToRem(5),
    alignItems: 'center',
    [`&:visited`]: {
        color: guestHomeTheme(theme, darkTheme).main.text,
        textDecoration: 'none',
    },
    [`&:hover`]: {
        color: guestHomeTheme(theme, darkTheme).main.text,
        textDecoration: 'none',
    },
});

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const SignInButton: StyleRule = ({ theme }) => {
    return {
        color: theme.siteVariables.colors.charcoal[250],
        textDecoration: 'none',
        fontSize: pxToRem(22),
        lineHeight: pxToRem(27),
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        [theme.screen.laptop]: {
            letterSpacing: theme.siteVariables.letterSpacingSmall,
        },
    };
};

export const EcomDemoButtonsSection: StyleRule = ({ theme }) => {
    return {
        marginTop: pxToRem(45),
        [theme.screen.laptop]: {
            marginTop: pxToRem(87),
        },
    };
};

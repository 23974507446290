import {
    useAppDisplayModeContext,
    Image,
    useStyles,
} from '@archipro-design/aria';
import * as S from './ImageCarouselModule.style';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { CustomArrowLeft, CustomArrowRight } from '@archipro-design/icons';
import type { ImageCarouselProps } from '@modules/elemental-page/type';

const ImageCarouselModule = ({ galleryImages, anchor }: ImageCarouselProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    const sources = galleryImages?.map((image) => {
        if (mobile && image.mobileSrc) {
            const width = image.isPortrait ? 197 : 414;
            const height = 286;
            return { src: image.mobileSrc, width, height };
        } else {
            const width = image.isPortrait ? 476 : 1000;
            const height = 690;
            return { src: image.desktopSrc, width, height };
        }
    });

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                <Swiper
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.image-slider-button-next',
                        prevEl: '.image-slider-button-prev',
                    }}
                    allowSlideNext
                    allowSlidePrev
                    allowTouchMove
                    loop
                    className={css(S.Slider)}
                >
                    {sources &&
                        sources.map((src, i) => (
                            <SwiperSlide
                                className={css(S.Slide)}
                                key={`photo-slide-${i}`}
                            >
                                <div className={css(S.ImageContainer)}>
                                    <Image
                                        key={`gallery-slide-${i}`}
                                        src={src.src ?? ''}
                                        width={src.width}
                                        height={src.height}
                                        alt={''}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className={css(S.SlideNav)}>
                    <button
                        className={classnames(
                            css(S.NavigateButton),
                            'image-slider-button-prev'
                        )}
                    >
                        <CustomArrowLeft />
                    </button>
                    <button
                        className={classnames(
                            css(S.NavigateButton),
                            'image-slider-button-next'
                        )}
                    >
                        <CustomArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImageCarouselModule;

import {
    Caption,
    useStyles,
} from '@archipro-design/aria';
import * as S from './ImageSlide.style';

interface ImageCaptionProps {
    mainCaption?: string;
    subCaption?: string;
    darkTheme: boolean;
}

const ImageCaption = ({
    mainCaption,
    subCaption,
    darkTheme,
}: ImageCaptionProps) => {
    const { css } = useStyles({ darkTheme });

    return (
        <>
            {mainCaption && (
                <Caption
                    variant="03"
                    className={css(S.MainCaption)}
                >
                    {mainCaption}
                </Caption>
            )}
            {subCaption && (
                <Caption
                    variant="05-alt"
                    className={css(S.SubCaption)}
                >
                    {subCaption}
                </Caption>
            )}
        </>
    );
};

export default ImageCaption;

import {
    Flex,
    Image,
    useStyles,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import BaseSection from '../base-section/BaseSection';
import EcommerceHeading from '../ecommerce-heading/EcommerceHeading';
import EcommerceLink from '../ecommerce-link/EcommerceLink';
import {
    ContentContainer,
    ImageStyle,
    ParagraphContainer,
    ImageMobileStyle,
    ImageContainer,
    CustomBaseSectionStyle,
    CustomBaseSectionStyleWithImage,
    CustomLinkStyle,
    CustomHeading,
    CustomHeadingWithImage,
} from './FeatureShowcaseModule.style';
import { useTracker } from '@archipro-website/tracker';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import type {
    ShowcaseModuleProps,
    ThemedElementColourType,
} from '@modules/elemental-page/type';

interface ThemedShowcaseModuleProps extends ShowcaseModuleProps {
    theme?: ThemedElementColourType;
}

const FeatureShowcaseModule = ({
    title,
    content,
    image,
    cta,
    theme,
    anchor,
}: ThemedShowcaseModuleProps) => {
    const hasImage = !!image?.desktopSrc || !!image?.mobileSrc;
    const { css } = useStyles({ hasImage });
    const { mobile } = useAppDisplayModeContext();
    const { log } = useTracker();

    const onClick = () => {
        log('EcomLandingPage_ExploreProductEditor', {
            url: new URL(window.location.href),
        });
        if (cta?.anchor) {
            const anchorElement = document.getElementById(cta.anchor);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <BaseSection
            background={theme}
            style={
                hasImage
                    ? CustomBaseSectionStyleWithImage
                    : CustomBaseSectionStyle
            }
            id={anchor}
        >
            {title && (
                <EcommerceHeading
                    title={title}
                    color={'white'}
                    style={hasImage ? CustomHeadingWithImage : CustomHeading}
                />
            )}
            {hasImage && (
                <div className={css(ImageContainer)}>
                    {mobile
                        ? image?.mobileSrc && (
                              <div className={css(ImageMobileStyle)}>
                                  <Image
                                      height={409}
                                      src={image.mobileSrc}
                                      width={382}
                                      layout={'responsive'}
                                      {...getCustomPlaceholderColor('dark')}
                                      sizes={getImagesSizes(1417)}
                                      alt={'Product detail overview'}
                                  />
                              </div>
                          )
                        : image?.desktopSrc && (
                              <div className={css(ImageStyle)}>
                                  <Image
                                      height={1470}
                                      src={image.desktopSrc}
                                      width={1417}
                                      layout={'responsive'}
                                      {...getCustomPlaceholderColor('dark')}
                                      sizes={getImagesSizes(1417)}
                                      alt={'Product detail overview'}
                                  />
                              </div>
                          )}
                </div>
            )}
            <Flex hAlign={'end'}>
                {(content || cta) && (
                    <div className={css(ContentContainer)}>
                        {content && (
                            <div
                                className={css(ParagraphContainer)}
                                dangerouslySetInnerHTML={{
                                    __html: content,
                                }}
                            />
                        )}
                        {cta?.title && cta?.link && (
                            <EcommerceLink
                                style={CustomLinkStyle}
                                {...cta}
                                onClick={onClick}
                            />
                        )}
                    </div>
                )}
            </Flex>
        </BaseSection>
    );
};

export default FeatureShowcaseModule;

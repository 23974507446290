import { useStyles } from '@archipro-design/aria';
import * as S from './ModuleSlider.style';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { ChevronLeft, ArrowLeftLine } from '@archipro-design/icons';
import type { ReactNode } from 'react';
import type { ImageTextCarouselModuleOrientation } from '@modules/elemental-page/type';

export type NavigationType = 'floating-buttons' | 'chevrons' | 'pagination';

interface ModuleSliderProps {
    moduleId: string;
    slides: ReactNode[];
    orientation?: ImageTextCarouselModuleOrientation;
    navigationType?: NavigationType;
    slidesPerView?: number;
    gap?: number;
    loop?: boolean;
    darkTheme: boolean;
}

const ModuleSlider = ({
    moduleId,
    slides,
    navigationType,
    slidesPerView,
    gap,
    loop,
    darkTheme,
}: ModuleSliderProps) => {
    const { css } = useStyles({ darkTheme });

    const navKeys = {
        nextEl: `carousel-${moduleId}-next`,
        prevEl: `carousel-${moduleId}-prev`,
    };
    const navigationProp = navigationType
        ? navigationType === 'pagination'
            ? {
                  pagination: {
                      clickable: true,
                      renderBullet: function (
                          index: number,
                          className: string
                      ) {
                          return '<span class="' + className + '"></span>';
                      },
                  },
              }
            : {
                  navigation: {
                      nextEl: `.${navKeys.nextEl}`,
                      prevEl: `.${navKeys.prevEl}`,
                  },
              }
        : {};

    return (
        <>
            <Swiper
                slidesPerView={slidesPerView || 1}
                spaceBetween={gap || 0}
                allowSlideNext
                allowSlidePrev
                allowTouchMove
                loop={!!loop}
                {...navigationProp}
            >
                {slides.map((slide, i) => (
                    <SwiperSlide key={`.carousel-${moduleId}-slide-${i}`}>
                        {slide}
                    </SwiperSlide>
                ))}
            </Swiper>
            {navigationType === 'floating-buttons' ? (
                <div className={css(S.FloatingNavButtons)}>
                    <button
                        className={classnames(
                            css(S.FloatingLeftButton),
                            navKeys.prevEl
                        )}
                    >
                        <ArrowLeftLine />
                    </button>
                    <button
                        className={classnames(
                            css(S.FloatingRightButton),
                            navKeys.nextEl
                        )}
                    >
                        <ArrowLeftLine />
                    </button>
                </div>
            ) : navigationType === 'chevrons' ? (
                <div className={`${css(S.NavButtons)} chevron-buttons`}>
                    <button
                        className={classnames(
                            css(S.PrevButton),
                            navKeys.prevEl
                        )}
                    >
                        <ChevronLeft />
                    </button>
                    <button
                        className={classnames(
                            css(S.NextButton),
                            navKeys.nextEl
                        )}
                    >
                        <ChevronLeft />
                    </button>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default ModuleSlider;

import { pxToRem } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

export const BaseSection: StyleRule = ({ theme }) => {
    return {
        paddingBottom: 0,
        paddingTop: pxToRem(81),
        [theme.screen.laptop]: {
            paddingTop: `${pxToRem(239)}`,
            paddingBottom: `${pxToRem(87)}`,
        },
    };
};

const customMobileFontColour = '#B0B0B0'; //figma colour not found in aria-Colours
const customDesktopFontColour = '#898989'; //figma colour not found in aria-Colours
const customDesktopHeadingColour = '#E8E9EB'; //figma colour not found in aria-Colours

export const ModuleHeading: StyleRule = ({ theme }) => {
    return {
        color: customMobileFontColour,
        marginBottom: pxToRem(79),
        [theme.screen.laptop]: {
            color: customDesktopHeadingColour,
            marginBottom: pxToRem(173),
            maxWidth: pxToRem(1818),
        },
    };
};

export const PrimaryImageDesktopContainer: StyleRule = ({ theme }) => {
    return {
        width: pxToRem(1417),
        margin: 'auto',
        display: 'none',
        maxWidth: '100%',
        [theme.screen.tablet]: {
            display: 'block',
        },
    };
};

export const PrimaryImageMobileContainer: StyleRule = ({ theme }) => {
    return {
        width: pxToRem(382),
        margin: 'auto',
        display: 'block',
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

export const SecondaryImageContainer: StyleRule = ({ theme }) => {
    return {
        width: pxToRem(483),
        height: pxToRem(820),
        display: 'none',
        [theme.screen.laptop]: {
            display: 'block',
        },
    };
};

export const SecondaryImage = () => {
    return {
        boxShadow: '0px 4px 16px -3px rgba(0, 0, 0, 0.4)',
    };
};

export const FeatureListSection: StyleRule = ({ theme }) => {
    return {
        [theme.screen.laptop]: {
            marginTop: pxToRem(-203),
            paddingRight: pxToRem(105),
        },
    };
};

export const FeatureList: StyleRule = ({ theme }) => {
    return {
        paddingLeft: 0,
        paddingRight: 0,
        margin: 0,
        marginTop: pxToRem(72),
        marginRight: pxToRem(10),
        width: '100%',
        [theme.screen.laptop]: {
            marginTop: 0,
            marginRight: 0,
            paddingTop: pxToRem(291),
            paddingBottom: pxToRem(50),
        },
    };
};

export const FeatureListItem: StyleRule = ({ theme }) => {
    return {
        color: customMobileFontColour,
        fontSize: theme.siteVariables.fontSizes.caption01,
        lineHeight: pxToRem(43),
        listStyle: 'none',
        borderBottom: '1px solid #B0B0B0',
        width: '100%',
        letterSpacing: pxToRem(-0.4),
        [theme.screen.laptop]: {
            color: customDesktopFontColour,
            paddingRight: 0,
            fontSize: pxToRem(57),
            lineHeight: pxToRem(68),
            borderBottom: 'none',
            paddingLeft: 0,
            letterSpacing: pxToRem(-1.25),
        },
    };
};

export const TickIcon: StyleRule = ({ theme }) => {
    return {
        height: pxToRem(19),
        width: pxToRem(20),
        margin: `0 ${pxToRem(11)} ${pxToRem(8)} ${pxToRem(7)}`,
        '> svg': {
            fill: customMobileFontColour,
            stroke: customMobileFontColour,
            strokeWidth: 0.6,
        },
        [theme.screen.laptop]: {
            display: 'none',
        },
    };
};

import { useStyles, Header } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';
import {
    HeaderStyling,
    HeaderVariables,
} from '../ecommerce-heading/EcommerceHeading.style';

export type EcommerceHeadingColor = 'white' | 'black' | 'grey';

interface EcommerceHeadingProps {
    title: string;
    color?: EcommerceHeadingColor;
    style?: StyleRule;
}

const EcommerceHeading = ({
    title,
    color,
    style = {},
}: EcommerceHeadingProps) => {
    const { css } = useStyles({ color });

    if (!title) {
        return null;
    }

    return (
        <Header
            variables={{
                color: HeaderVariables(color),
            }}
            as={'h3'}
            className={css(HeaderStyling, style)}
            dangerouslySetInnerHTML={{ __html: title }}
        />
    );
};

export default EcommerceHeading;

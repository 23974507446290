import * as S from './AboutTestimonialModule.style';
import {
    Caption,
    Image,
    useStyles,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import { CustomArrowLeft, CustomArrowRight } from '@archipro-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import type { TestimonialModuleProps } from '@modules/elemental-page/type';
import { NEW_GREY_01 } from '@modules/elemental-page/config/about-us-style-constants';
import React from 'react';

const AboutTestimonialModule = ({
    testimonials,
    anchor,
}: TestimonialModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                <Swiper
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.testimonial-slider-button-next',
                        prevEl: '.testimonial-slider-button-prev',
                    }}
                    allowSlideNext
                    allowSlidePrev
                    allowTouchMove
                    loop
                    className={css(S.Slider)}
                >
                    {testimonials &&
                        testimonials.length > 0 &&
                        testimonials.map((slide, i) => {
                            const imageSrc =
                                mobile && slide.photo?.mobileSrc
                                    ? slide.photo.mobileSrc
                                    : slide.photo?.desktopSrc;
                            return (
                                <SwiperSlide
                                    className={css(S.Slide)}
                                    key={`testimonial-slide-${i}`}
                                >
                                    {imageSrc && (
                                        <div className={css(S.ImageContainer)}>
                                            <Image
                                                src={imageSrc}
                                                width={mobile ? 231 : 559}
                                                height={mobile ? 219 : 528}
                                                layout={'fill'}
                                                objectFit={'cover'}
                                                alt={''}
                                            />
                                        </div>
                                    )}
                                    <div>
                                        {slide?.content && (
                                            <div className={css(S.Testimonial)}>
                                                <Caption
                                                    variant={'01'}
                                                    className={css(S.Quotes)}
                                                >
                                                    “
                                                </Caption>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: slide.content,
                                                    }}
                                                />
                                                <Caption
                                                    variant={'01'}
                                                    className={css(S.Quotes)}
                                                >
                                                    ”
                                                </Caption>
                                            </div>
                                        )}
                                        <div className={css(S.Author)}>
                                            {slide?.author && (
                                                <Caption
                                                    variant={'04'}
                                                    className={css(
                                                        S.AuthorName
                                                    )}
                                                    variables={{
                                                        letterSpacing: '0.07em',
                                                    }}
                                                >
                                                    {slide.author}
                                                </Caption>
                                            )}
                                            {slide?.organisation && (
                                                <Caption
                                                    variant={'04'}
                                                    className={css(
                                                        S.AuthorType
                                                    )}
                                                    variables={{
                                                        letterSpacing: '0.07em',
                                                        captionColor:
                                                            NEW_GREY_01,
                                                    }}
                                                >
                                                    {slide.organisation}
                                                </Caption>
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
                <div>
                    <button
                        className={classnames(
                            css(S.NavigateButton),
                            'testimonial-slider-button-prev'
                        )}
                    >
                        <CustomArrowLeft />
                    </button>
                    <button
                        className={classnames(
                            css(S.NavigateButton),
                            'testimonial-slider-button-next'
                        )}
                    >
                        <CustomArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AboutTestimonialModule;

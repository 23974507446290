import type { ImageDimension } from '@modules/elemental-page/type';
import {
    MAXIMUM_DESKTOP_IMAGE_WIDTH,
    MAXIMUM_MOBILE_IMAGE_WIDTH,
} from '@modules/elemental-page/config/about-us-style-constants';

export const getBoundedImageDimension = (
    isMobile: boolean,
    defaultDimension: ImageDimension,
    desktopDimension?: ImageDimension,
    mobileDimension?: ImageDimension
): ImageDimension => {
    const imageDimension =
        isMobile && mobileDimension
            ? mobileDimension
            : desktopDimension
            ? desktopDimension
            : defaultDimension;

    let width = imageDimension.width;
    let height = imageDimension.height;
    const imageAspect = width / height;

    const bound = isMobile
        ? MAXIMUM_MOBILE_IMAGE_WIDTH
        : MAXIMUM_DESKTOP_IMAGE_WIDTH;

    if (width > bound) {
        width = bound;
        height = width / imageAspect;
    }

    return { width, height };
};

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import type { ThemedElementColourType } from '@modules/elemental-page/type';

const PADDING_MOBILE = `${pxToRem(80)} ${pxToRem(16)}`;
const PADDING_DESKTOP = `${pxToRem(240)} ${pxToRem(50)}`;

interface BackgroundColor {
    [key: string]: string;
}

interface BaseSectionStylingProps {
    background?: ThemedElementColourType;
}

export const BaseSectionStyling: StyleRule<BaseSectionStylingProps> = ({
    theme,
    background = 'grey',
}) => {
    const backgroundColor: BackgroundColor = {
        sage: theme.siteVariables.colors.grey['500A'] || '#6B7064', // remove when thene implemented
        dark: theme.siteVariables.colors.charcoal['000']!,
        grey: theme.siteVariables.colors.white['FFF']!,
        transparent: 'transparent',
    };

    return {
        padding: PADDING_MOBILE,
        backgroundColor: background && backgroundColor[background],
        [theme.screen.laptop]: {
            padding: PADDING_DESKTOP,
        },
    };
};

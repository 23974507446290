import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Module: StyleRule = () => {
    return {
        position: 'relative',
    };
};

export const Container: StyleRule = () => {
    return {
        position: 'relative',
        maxWidth: pxToRem(1000),
        margin: 'auto',
    };
};

export const Slider: StyleRule = () => {
    return {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };
};

export const Slide: StyleRule = () => {
    return {
        whiteSpace: 'normal',
        width: '100%',
        display: 'inline-block',
    };
};

export const ImageContainer: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        width: '100%',
        height: pxToRem(286),
        '& span': {
            width: '100%',
            justifyContent: 'center',
            background: 'none',
        },
        [theme.screen.laptop]: {
            height: pxToRem(690),
        },
    };
};

export const SlideNav: StyleRule = ({ theme }) => {
    return {
        textAlign: 'center',
        marginTop: pxToRem(21),
        [theme.screen.laptop]: {
            marginTop: pxToRem(55),
        },
    };
};

export const NavigateButton: StyleRule = () => {
    return {
        border: 'none',
        background: 'transparent',
    };
};

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { OverrideGoogleMap } from '~/modules/professional/component/contact-tab/OverrideGoogleMap.style';

const MAP_WIDTH = 906;
const MAP_HEIGHT = 439;

export const RightMapSection: StyleRule = ({ theme }) => ({
    textAlign: 'center',
    border: `1px solid ${theme.siteVariables.colors.grey[125]}`,
    width: '100%',
    height: pxToRem(460),
    ...OverrideGoogleMap(theme),

    [theme.screen.laptop]: {
        width: pxToRem(MAP_WIDTH),
        height: pxToRem(MAP_HEIGHT),
        boxShadow: `0px 4px 4px 0px #00000040`,
    },
});

export const MapAndLocationText: StyleRule = ({ theme, spaceBelow }) => ({
    [theme.screen.laptop]: {
        marginBottom: `${spaceBelow}rem`,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: pxToRem(120),
    },
});

export const LocationText: StyleRule = ({ theme }) => ({
    cursor: 'pointer',
    ...theme.siteVariables.textStyles.Text.Roman55,
    color: theme.siteVariables.colors.charcoal['250'],
    display: 'flex',
    flexDirection: 'column',
    margin: pxToRem(18),
    '& dt': {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(20.8),
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        marginBottom: pxToRem(1),
        ...theme.siteVariables.textStyles.Text.Medium65,
    },

    '& dd': {
        margin: 0,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(20.8),

        '& a:last-child': {
            marginTop: pxToRem(20),
            color: theme.siteVariables.colors.charcoal['250'],
            display: 'block',
            textDecoration: 'underline',
        },
    },

    [theme.screen.laptop]: {
        width: pxToRem(MAP_WIDTH),
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',

        '& dt': {
            fontSize: pxToRem(36),
            lineHeight: pxToRem(46.8),
            letterSpacing: theme.siteVariables.letterSpacingSmall,
            marginTop: pxToRem(9),
            marginBottom: pxToRem(9),
            ...theme.siteVariables.textStyles.Text.Roman55,
        },

        '& dd': {
            margin: 0,
            fontSize: pxToRem(20),
            lineHeight: pxToRem(24.6),

            '& a:last-child': {
                marginTop: pxToRem(58),
                color: theme.siteVariables.colors.charcoal['250'],
                display: 'block',
                textDecoration: 'underline',
            },
        },
    },
});

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const CustomBaseSection: StyleRule = ({ theme }) => {
    return {
        paddingTop: pxToRem(55),
        paddingBottom: pxToRem(54),
        [theme.screen.laptop]: {
            paddingTop: pxToRem(118),
            paddingBottom: pxToRem(120),
        },
    };
};

export const CustomHeading: StyleRule = ({ theme }) => {
    return {
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        marginBottom: pxToRem(38),
        [theme.screen.tablet]: {
            maxWidth: pxToRem(600),
        },
        [theme.screen.laptop]: {
            marginBottom: `${pxToRem(90)}`,
            maxWidth: `${pxToRem(1790)} !important`, //TODO: fix when media queris mobile first is fixed
        },
    };
};

export const SignInLink: StyleRule = ({ theme }) => {
    return {
        display: 'block',
        fontSize: pxToRem(22),
        lineHeight: pxToRem(27),
        color: theme.siteVariables.colors.charcoal['250'],
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        textDecoration: 'none',
        maxWidth: pxToRem(200),
    };
};

export const BookingConfirmedText: StyleRule = ({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.siteVariables.fontSizes.label01,
        lineHeight: theme.siteVariables.lineHeightDeafult,
        color: theme.siteVariables.colors.charcoal[250],
        marginTop: pxToRem(46),
        [theme.screen.laptop]: {
            fontSize: pxToRem(40),
            lineHeight: pxToRem(54),
            paddingBottom: pxToRem(7),
            borderBottom: `${pxToRem(2)} solid transparent`,
            marginTop: pxToRem(88),
        },
    };
};

export const AcceptIconStyle: StyleRule = ({ theme }) => {
    return {
        width: pxToRem(19),
        marginLeft: pxToRem(16),
        [theme.screen.laptop]: {
            width: pxToRem(32),
        },
    };
};

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const ModuleContainer: StyleRule<{
    spacingBelow: number;
    spacingBelowMobile: number;
}> = ({ spacingBelow, spacingBelowMobile, theme }) => {
    return {
        [`& > div`]: {
            marginBottom: pxToRem(spacingBelowMobile),
            [theme.screen.laptop]: {
                marginBottom: pxToRem(spacingBelow),
            },
        },
    };
};

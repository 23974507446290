import type { BaseThemePrepared } from '@archipro-design/aria';

export function guestHomeTheme(theme: BaseThemePrepared, darkTheme: boolean) {
    return {
        main: {
            text: darkTheme
                ? '#A2A2A2'
                : theme.siteVariables.colors.charcoal['250'],
            text2: darkTheme
                ? theme.siteVariables.colors.primitive.white
                : theme.siteVariables.colors.primitive.black,
            text3: darkTheme
                ? theme.siteVariables.colors.primitive.white
                : theme.siteVariables.colors.charcoal['400'],
            background: darkTheme
                ? theme.siteVariables.colors.charcoal['400']
                : '#F3F3F3',
        },
        button: {
            background: darkTheme
                ? '#3F3F3F'
                : theme.siteVariables.colors.primitive.white,
            background2: darkTheme ? '#1C1C1C' : '#F2F2F2',
            background3: darkTheme
                ? theme.siteVariables.colors.primitive.white
                : theme.siteVariables.colors.charcoal['250'],
            hoverBackground: darkTheme
                ? theme.siteVariables.colors.charcoal['250']
                : theme.siteVariables.colors.charcoal['260'],
            border: `1px solid ${
                darkTheme
                    ? '#A2A2A2'
                    : theme.siteVariables.colors.primitive.black
            }`,
            border2: `1px solid ${
                darkTheme ? '#A2A2A2' : theme.siteVariables.colors.charcoal[250]
            }`,
        },
        icon: {
            fill: darkTheme ? '#A2A2A2' : '#A1A1A1',
            fill2: darkTheme
                ? '#A2A2A2'
                : theme.siteVariables.colors.charcoal['300'],
        },
    };
}

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { NEW_BLACK_01 } from '@modules/elemental-page/config/about-us-style-constants';

export const Module: StyleRule = () => {
    return {
        position: 'relative',
    };
};

export const Container: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        maxWidth: pxToRem(1000),
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: NEW_BLACK_01,
        padding: pxArrayToRem([80, 30]),
        [theme.screen.laptop]: {
            padding: pxArrayToRem([180, 60]),
        },
    };
};

export const Slider: StyleRule = () => {
    return {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        margin: 'auto',
    };
};

export const Slide: StyleRule = () => {
    return {
        whiteSpace: 'normal',
        width: '100%',
        display: 'inline-block',
    };
};

export const Title: StyleRule = ({ theme }) => {
    return {
        marginTop: 0,
        textTransform: 'uppercase',
        marginBottom: pxToRem(30),
        fontSize: pxToRem(20),
        ...theme.siteVariables.textStyles.Heading.Medium65,
        color: theme.siteVariables.colors.white['FFF'],

        [theme.screen.laptop]: {
            marginBottom: pxToRem(60),
            fontSize: pxToRem(22),
        },
    };
};

export const Content: StyleRule = ({ theme }) => {
    return {
        fontSize: theme.siteVariables.fontSizes.caption04,
        lineHeight: theme.siteVariables.lineHeightSmaller,
        color: theme.siteVariables.colors.primitive.white,
        marginBottom: pxToRem(30),
        '& p': {
            lineHeight: 1.215,
        },

        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes.caption03,
            lineHeight: 1.6,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: pxToRem(550),
            marginBottom: pxToRem(60),
            '& p': {
                lineHeight: '1.6',
            },
        },
    };
};

export const ImageContainer: (width: number, height: number) => StyleRule =
    (width, height) =>
    ({ theme }) => {
        return {
            position: 'relative',
            margin: 'auto',
            width: pxToRem(width),
            height: pxToRem(height),
            marginBottom: pxToRem(30),
            [theme.screen.laptop]: {
                marginBottom: pxToRem(60),
            },
        };
    };

export const NavigateButton: StyleRule = ({ theme }) => {
    return {
        border: 'none',
        background: 'transparent',
        fill: theme.siteVariables.colors.primitive.white,
        [`& path`]: {
            fill: theme.siteVariables.colors.primitive.white,
        },
    };
};

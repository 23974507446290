import {
    Image,
    Caption,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import type {
    ProfessionalFeaturesModuleProps,
    ThemedElementColourType,
} from '@modules/elemental-page/type';
import * as S from './ProjectFeaturesModule.style';

import ModuleSlider from '@modules/elemental-page/component/module-slider/ModuleSlider';
import { SwiperSlide } from 'swiper/react';
import { Link } from '@remix-run/react';
import { CustomArrowRight } from '@archipro-design/icons';

interface ThemedProfessionalFeaturesModuleProps
    extends ProfessionalFeaturesModuleProps {
    id: string;
    theme: ThemedElementColourType;
}

export const PROJECT_FEATURE_IMAGE_DIMENSION = {
    desktop: {
        width: 552,
        height: 298,
    },
    mobile: {
        width: 378,
        height: 237,
    },
};

const ProjectFeaturesModule = ({
    id,
    theme,
    title,
    featureBlocks,
}: ThemedProfessionalFeaturesModuleProps) => {
    const darkTheme = theme === 'dark';
    const { desktop } = useAppDisplayModeContext();
    const dimension = desktop
        ? PROJECT_FEATURE_IMAGE_DIMENSION.desktop
        : PROJECT_FEATURE_IMAGE_DIMENSION.mobile;
    const { css } = useStyles({ darkTheme });

    const slides = featureBlocks.map((block, i) => {
        const mobileSrc = block.image?.mobileSrc || block.image?.desktopSrc;
        const imageSrc = desktop ? block.image?.desktopSrc : mobileSrc;
        const tileContent = (
            <>
                <div className={css(S.ImageContainer)}>
                    <Image
                        key={block.id}
                        {...dimension}
                        layout={'fill'}
                        objectFit={'cover'}
                        src={imageSrc || ''}
                        alt={'img-alt'}
                    />
                </div>
                <div className={css(S.ProjectInfo)}>
                    <Caption className={css(S.Title)}>{block.title}</Caption>
                    <Caption className={css(S.Summary)}>
                        {block.summary}
                    </Caption>
                    {block.cta && desktop && (
                        <Link
                            to={block.cta.externalLink || block.cta.link || ''}
                            target={block.cta.externalLink ? '_blank' : '_self'}
                            className={css(S.Link)}
                        >
                            {block.cta.title}
                            <CustomArrowRight />
                        </Link>
                    )}
                </div>
            </>
        );
        return (
            <SwiperSlide key={`module-${id}-image-slide-${i}`}>
                {block.cta ? (
                    <Link
                        to={block.cta.externalLink || block.cta.link || ''}
                        target={block.cta.externalLink ? '_blank' : '_self'}
                    >
                        {tileContent}
                    </Link>
                ) : (
                    tileContent
                )}
            </SwiperSlide>
        );
    });

    return (
        <div className={css(S.ProjectFeaturesModule)}>
            {title && <Caption className={css(S.Heading)}>{title}</Caption>}
            <div className={css(S.SliderContainer)}>
                <ModuleSlider
                    moduleId={id}
                    slides={slides}
                    slidesPerView={desktop ? 3 : 1}
                    gap={desktop ? 12 : 0}
                    navigationType={desktop ? 'floating-buttons' : 'pagination'}
                    darkTheme={darkTheme}
                />
            </div>
        </div>
    );
};

export default ProjectFeaturesModule;

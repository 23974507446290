import { pxToRem } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

export const CustomBaseSection: StyleRule = ({ theme }) => {
    return {
        padding: `${pxToRem(70)} 0 ${pxToRem(98)}`,
        [theme.screen.laptop]: {
            padding: `${pxToRem(106)} 0 ${pxToRem(240)}`,
        },
    };
};

export const CustomBaseSectionNoText: StyleRule = ({ theme }) => {
    return {
        padding: `0 0 ${pxToRem(71)}`,
        [theme.screen.laptop]: {
            padding: `${pxToRem(70)} 0 ${pxToRem(188)}`,
        },
    };
};

export const ShortText: StyleRule = ({ theme }) => {
    return {
        color: '#717171', //TODO: change to theme color once implemented
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20.5),
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        marginLeft: pxToRem(16),
        maxWidth: pxToRem(383),
        marginTop: pxToRem(47),

        '& p': {
            margin: 0,
        },

        '& a': {
            color: '#717171', //TODO: change to theme color once implemented
        },

        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes['label02'],
            lineHeight: pxToRem(24.5),
            marginLeft: pxToRem(50),
            maxWidth: pxToRem(795),
            marginTop: pxToRem(75),
        },
    };
};

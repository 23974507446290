import { Image, Paragraph, useStyles } from '@archipro-design/aria';
import * as S from './BusniessSupportBlock.style';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import type { BusinessTextBlock } from '@modules/elemental-page/type';

export const BusinessSupportBlock = ({
    title,
    summary,
    image,
}: BusinessTextBlock) => {
    const { css } = useStyles();

    return (
        <div style={{ flex: 1 }}>
            {image?.desktopSrc && (
                <div className={css(S.DesktopImageStyle)}>
                    <Image
                        src={image.desktopSrc}
                        sizes={getImagesSizes(588)}
                        height={375}
                        width={588}
                        {...getCustomPlaceholderColor('dark')}
                        layout={'responsive'}
                        alt={title || summary || ''}
                    />
                </div>
            )}
            {image?.mobileSrc && (
                <div className={css(S.MobileImageStyle)}>
                    <Image
                        src={image.mobileSrc}
                        height={244}
                        width={383}
                        {...getCustomPlaceholderColor('dark')}
                        layout={'responsive'}
                        sizes={getImagesSizes(588)}
                        alt={title || summary || ''}
                    />
                </div>
            )}
            {title && (
                <div className={css(S.TitleContainer)}>
                    <Paragraph className={css(S.TitleParagraph)}>
                        {title}
                    </Paragraph>
                </div>
            )}
            {summary && (
                <div className={css(S.SummaryContainer)}>
                    <Paragraph>{summary}</Paragraph>
                </div>
            )}
        </div>
    );
};

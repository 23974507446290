import * as S from './FramedPictureCarouselModule.style';
import {
    Image,
    useStyles,
    useAppDisplayModeContext,
    Header,
    pxToRem,
    useTheme,
} from '@archipro-design/aria';
import { CustomArrowLeft, CustomArrowRight } from '@archipro-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import type { FramedPictureCarouselProps } from '@modules/elemental-page/type';
import { getBoundedImageDimension } from '@modules/elemental-page/util/get-bounded-image-dimension';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';
import React from 'react';

const DEFAULT_FRAME_IMAGE_DESKTOP_WIDTH = 315;
const DEFAULT_FRAME_IMAGE_DESKTOP_HEIGHT = 560;

const FramedPictureCarouselModule = ({
    framedPictureItems,
    cta,
    anchor,
}: FramedPictureCarouselProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();
    const theme = useTheme();

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                <Swiper
                    slidesPerView={1}
                    navigation={{
                        nextEl: '.framed-slider-button-next',
                        prevEl: '.framed-slider-button-prev',
                    }}
                    allowSlideNext
                    allowSlidePrev
                    allowTouchMove
                    loop
                    className={css(S.Slider)}
                >
                    {framedPictureItems &&
                        framedPictureItems.length > 0 &&
                        framedPictureItems.map((slide, i) => {
                            const imageSrc =
                                mobile && slide.image?.mobileSrc
                                    ? slide.image.mobileSrc
                                    : slide.image?.desktopSrc;

                            const defaultDimensions = {
                                width: DEFAULT_FRAME_IMAGE_DESKTOP_WIDTH,
                                height: DEFAULT_FRAME_IMAGE_DESKTOP_HEIGHT,
                            };
                            const imageDimension = getBoundedImageDimension(
                                mobile,
                                defaultDimensions,
                                slide.image?.originalMobileDimension,
                                slide.image?.originalDesktopDimension
                            );

                            return (
                                <SwiperSlide
                                    className={css(S.Slide)}
                                    key={`framed-content-slide-${i}`}
                                >
                                    {slide?.title && (
                                        <Header
                                            as={'h3'}
                                            variables={(siteVars) => ({
                                                fontSize: mobile
                                                    ? pxToRem(20)
                                                    : pxToRem(22),
                                                ...siteVars?.textStyles.Heading
                                                    .Medium65,
                                                letterSpacing: '0.2em',
                                                lineHeight:
                                                    siteVars?.lineHeightSmall,
                                                color: theme.siteVariables
                                                    .colors.primitive.white,
                                            })}
                                            className={css(S.Title)}
                                        >
                                            {slide.title}
                                        </Header>
                                    )}
                                    {imageSrc && (
                                        <div
                                            className={css(
                                                S.ImageContainer(
                                                    imageDimension.width,
                                                    imageDimension.height
                                                )
                                            )}
                                        >
                                            <Image
                                                src={imageSrc}
                                                width={imageDimension.width}
                                                height={imageDimension.height}
                                                layout={'fill'}
                                                objectFit={'cover'}
                                                alt={''}
                                                variables={{
                                                    placeholderBackgroundColorDark:
                                                        'transparent',
                                                    placeholderBackgroundColorLight:
                                                        'transparent',
                                                }}
                                            />
                                        </div>
                                    )}
                                    {slide?.content && (
                                        <div
                                            className={css(S.Content)}
                                            dangerouslySetInnerHTML={{
                                                __html: slide.content,
                                            }}
                                        />
                                    )}
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
                <div>
                    <button
                        className={classnames(
                            css(S.NavigateButton),
                            'framed-slider-button-prev'
                        )}
                    >
                        <CustomArrowLeft />
                    </button>
                    <button
                        className={classnames(
                            css(S.NavigateButton),
                            'framed-slider-button-next'
                        )}
                    >
                        <CustomArrowRight />
                    </button>
                </div>
                <AboutCallToAction {...cta} buttonTheme={'light'} />
            </div>
        </div>
    );
};

export default FramedPictureCarouselModule;

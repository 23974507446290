import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';
import { PROJECT_FEATURE_IMAGE_DIMENSION } from '@modules/elemental-page/component/project-feature-module/ProjectFeaturesModule';

export const ProjectFeaturesModule: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    [theme.screen.tablet]: {
        paddingTop: pxToRem(80),
        paddingBottom: pxToRem(80),
    },
});

export const SliderContainer: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    padding: pxArrayToRem([0, 16]),
    position: 'relative',
    [`& .swiper-pagination-bullet`]: {
        backgroundColor: '#BBB',
        [`&-active`]: {
            backgroundColor: guestHomeTheme(theme, darkTheme).main.text2,
        },
    },
    [theme.screen.tablet]: {
        padding: pxArrayToRem([0, 120]),
        [`& button`]: {
            top: pxToRem(149),
        },
    },
});

export const Heading: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    textAlign: 'center',
    ...theme.siteVariables.textStyles.Heading.Medium65,
    fontSize: theme.siteVariables.fontSizes.caption01,
    maxWidth: pxToRem(380),
    margin: `0 auto ${pxToRem(40)} auto`,
    color: guestHomeTheme(theme, darkTheme).main.text2,
    textTransform: 'uppercase',
    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        maxWidth: pxToRem(900),
        margin: `0 auto ${pxToRem(66)} auto`,
        lineHeight: `113%`,
        fontSize: theme.siteVariables.fontSizes.heading05,
    },
});

export const ImageContainer: StyleRule = ({ theme }) => ({
    position: 'relative',
    height: pxToRem(PROJECT_FEATURE_IMAGE_DIMENSION.mobile.height),
    width: pxToRem(PROJECT_FEATURE_IMAGE_DIMENSION.mobile.width),
    [theme.screen.tablet]: {
        height: pxToRem(PROJECT_FEATURE_IMAGE_DIMENSION.desktop.height),
        width: pxToRem(PROJECT_FEATURE_IMAGE_DIMENSION.desktop.width),
    },
});

export const ProjectInfo: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(40),
    marginBottom: pxToRem(38),
    textAlign: 'center',
    [theme.screen.tablet]: {
        marginTop: pxToRem(22),
        textAlign: 'left',
        marginBottom: 0,
    },
});

export const Title: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    ...theme.siteVariables.textStyles.Heading.Medium65,
    fontSize: theme.siteVariables.fontSizes.label02,
    color: guestHomeTheme(theme, darkTheme).main.text2,
    textTransform: 'uppercase',
    marginBottom: pxToRem(11),
    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        marginBottom: pxToRem(22),
        fontSize: theme.siteVariables.fontSizes.label03,
        lineHeight: '113%',
    },
});

export const Summary: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    color: guestHomeTheme(theme, darkTheme).main.text,
    maxWidth: pxToRem(410),
    marginBottom: pxToRem(11),
    [theme.screen.max.tablet]: {
        fontSize: theme.siteVariables.fontSizes.caption03,
        lineHeight: '120%',
        padding: pxToRem(4),
    },
    [theme.screen.tablet]: {
        letterSpacing: pxToRem(-0.2),
        marginBottom: pxToRem(20),
        fontSize: theme.siteVariables.fontSizes.caption01,
        lineHeight: '140%',
    },
});

export const Link: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    textTransform: 'uppercase',
    ...theme.siteVariables.textStyles.Heading.Medium65,
    fontSize: theme.siteVariables.fontSizes.caption03s,
    color: guestHomeTheme(theme, darkTheme).main.text,
    textDecoration: 'none',
    alignItems: 'center',
    [`&:visited`]: {
        color: guestHomeTheme(theme, darkTheme).main.text,
        textDecoration: 'none',
    },
    [`&:hover`]: {
        color: guestHomeTheme(theme, darkTheme).main.text,
        textDecoration: 'none',
    },
});

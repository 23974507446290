import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const Container: StyleRule = ({ theme }) => {
    return {
        [theme.screen.laptop]: {
            paddingBottom: pxToRem(220),
        },
        '& p': {
            margin: '0',
            '& span': {
                textDecorationThickness: '1px !important',
                textUnderlineOffset: `${pxToRem(4)} !important`,
                [theme.screen.laptop]: {
                    textDecorationThickness: '2px !important',
                    textUnderlineOffset: `${pxToRem(9)} !important`,
                },
            },
        },
    };
};

export const BlocksContainer: StyleRule = ({ theme }) => {
    return {
        display: 'grid',
        gridTemplateRows: `repeat(3, auto)`,
        gap: theme.siteVariables.spaces['space-80'],
        marginTop: pxToRem(90),
        [theme.screen.laptop]: {
            gap: theme.siteVariables.spaces['space-28'],
            flexDirection: 'row',
            marginTop: pxToRem(240),
            gridTemplateColumns: `repeat(3, 1fr)`,
            gridTemplateRows: `1fr`,
        },
    };
};

export const HeadingCustom: StyleRule = ({ theme }) => {
    return {
        [theme.screen.laptop]: {
            maxWidth: pxToRem(1820),
        },
    };
};

export const Intro: StyleRule = ({ theme }) => {
    return {
        fontSize: pxToRem(40),
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        lineHeight: '100%',
        [theme.screen.laptop]: {
            fontSize: pxToRem(120),
            lineHeight: pxToRem(120),
        },
    };
};

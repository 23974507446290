import BaseSection from '../base-section/BaseSection';
import {
    CustomBaseSection,
    CustomBaseSectionNoText,
    ShortText,
} from './LogoCarouselModule.style';
import EcommerceLogoMarquee from '../ecommerce-logo-marquee/EcommerceLogoMarquee';
import { useStyles } from '@archipro-design/aria';
import type { ThemedElementColourType } from '@modules/elemental-page/type';
import { useInViewport } from 'ahooks';
import { useRef } from 'react';
import type { LogoCarouselModuleProps } from '@modules/elemental-page/type';

interface ThemedLogoCarouselModuleProps extends LogoCarouselModuleProps {
    theme?: ThemedElementColourType;
}

const LogoCarouselModule = ({
    finePrint,
    logos,
    theme,
    anchor,
}: ThemedLogoCarouselModuleProps) => {
    const ref = useRef(null);
    const { css } = useStyles();
    const [inViewport] = useInViewport(ref, {
        threshold: 0,
        rootMargin: '100%',
    });

    return (
        <BaseSection
            ref={ref}
            background={theme}
            style={finePrint ? CustomBaseSection : CustomBaseSectionNoText}
            id={anchor}
        >
            {logos && logos.length > 0 && inViewport && (
                <EcommerceLogoMarquee
                    logos={logos}
                    size={finePrint ? 'small' : 'default'}
                />
            )}
            {finePrint && (
                <div
                    className={css(ShortText)}
                    dangerouslySetInnerHTML={{ __html: finePrint }}
                />
            )}
        </BaseSection>
    );
};

export default LogoCarouselModule;

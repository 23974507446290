import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    videoPlayerClassName,
    boxClassName,
    pxArrayToRem,
    bannerAdTileSlotClassNames,
} from '@archipro-design/aria';
import { extractValueFromClass } from '../../util/extract-value-from-class';

export const VideoModuleContainer: StyleRule<{
    fullWidth?: boolean;
    customClassName?: string | null;
}> = ({ theme, fullWidth, customClassName }) => {
    return {
        position: 'relative',
        backgroundColor: customClassName
            ? extractValueFromClass('bg', customClassName)
            : undefined,
        [theme.screen.laptop]: {
            padding: fullWidth ? pxArrayToRem([0, 80]) : 0,
        },
    };
};

export const Frame: StyleRule<{ fullWidth?: boolean }> = ({ fullWidth }) => {
    return {
        position: 'relative',
        maxWidth: fullWidth ? 'none' : pxToRem(1000),
        width: '100%',
        margin: 'auto',
    };
};

export const VideoAspect: StyleRule<{
    playsInline?: boolean;
    fullWidth?: boolean;
}> = ({ playsInline, fullWidth }) => {
    return {
        position: 'relative',
        paddingTop: playsInline ? (fullWidth ? '42.2%' : '56.25%') : '69%',
        [`& .${videoPlayerClassName}`]: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            [`& .${boxClassName}`]: {
                position: 'absolute',
                overflow: 'hidden',
                [`& svg`]: {
                    transition: 'none',
                },
            },
        },
    };
};

export const AutoPlayClickBlocker: StyleRule = () => {
    return {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
        zIndex: 1,
    };
};

export const HostedVideoModule: StyleRule = () => {
    return {
        [`& .${bannerAdTileSlotClassNames.contentWrap}`]: {
            display: 'none',
        },
    };
};

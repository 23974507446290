import type { CommonModule } from '@modules/elemental-page/type';

import ImageSlide from '@modules/elemental-page/component/image-text-carousel-module/ImageSlide';
import ModuleSlider from '@modules/elemental-page/component/module-slider/ModuleSlider';
import * as S from './ImageSlide.style';
import { useStyles } from '@archipro-design/aria';

interface ImageColumnProps
    extends Pick<CommonModule, 'imageCarouselItems' | 'id' | 'orientation'> {
    renderAsSlide?: boolean;
    darkTheme: boolean;
}

const ImageSlider = ({
    imageCarouselItems,
    id,
    orientation,
    renderAsSlide,
    darkTheme,
}: ImageColumnProps) => {
    const { css } = useStyles({ orientation });
    const isCarousel = imageCarouselItems.length > 1;

    const slides = isCarousel
        ? imageCarouselItems.map((item) => (
              <ImageSlide
                  key={item.id}
                  item={item}
                  orientation={orientation}
                  renderAsSlide={renderAsSlide}
                  darkTheme={darkTheme}
              />
          ))
        : [];

    const firstSlide = imageCarouselItems[0] ? (
        <ImageSlide
            item={imageCarouselItems[0]}
            orientation={orientation}
            renderAsSlide={
                imageCarouselItems[0].imageAspect === 'landscape'
                    ? false
                    : renderAsSlide
            }
            darkTheme={darkTheme}
        />
    ) : null;

    return (
        <>
            {isCarousel ? (
                <div data-testid="img-slider">
                    <ModuleSlider
                        moduleId={id}
                        slides={slides}
                        darkTheme={darkTheme}
                        slidesPerView={1}
                        loop={true}
                        navigationType={'chevrons'}
                        data-testid="img-slider"
                    />
                </div>
            ) : (
                <div className={css(S.SingleSlideWrapper)}>{firstSlide}</div>
            )}
        </>
    );
};

export default ImageSlider;

export const MINIMUM_MODULE_HEIGHT = 200;
export const MOBILE_CONTAINER_SIDE_GUTTER = 18;
export const DEFAULT_MODULE_GAP = 180;
export const DEFAULT_MODULE_GAP_MOBILE = 90;

export const SOCIAL_LINK_YOUTUBE = 'https://www.youtube.com/@ArchiProGlobal';
export const SOCIAL_LINK_PINTEREST = 'https://www.pinterest.nz/ArchiPro';
export const SOCIAL_LINK_LINKEDIN =
    'https://www.linkedin.com/company/archipro/mycompany';
export const SOCIAL_LINK_INSTAGRAM = 'https://www.instagram.com/archipro_';
export const SOCIAL_LINK_FACEBOOK = 'https://www.facebook.com/archipro';

export const IMAGE_TEXT_SINGLE_IMAGE_DIMENSIONS = {
    desktop: {
        landscape: { width: 834, height: 469 },
        square: { width: 834, height: 834 },
        portrait: { width: 834, height: 834 },
    },
    mobile: { width: 378, height: 346 },
};

export const IMAGE_TEXT_CAROUSEL_IMAGE_DIMENSIONS = {
    desktop: {
        landscape: { width: 954, height: 640 },
        square: { width: 552, height: 460 },
        portrait: { width: 441, height: 460 },
    },
    mobile: { width: 294, height: 269 },
};

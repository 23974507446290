import { useEffect, useState } from 'react';
import type { CampaignFormFooterModuleProps } from '../../type';
import * as S from './CampaignFormFooterModule.style';
import { Caption, Flex, pxToRem, useStyles } from '@archipro-design/aria';

interface FormProps {
    title?: string;
    embedLink?: string;
    frameHeight?: number;
    anchor?: string;
    heroImageSrc: string;
}

const CampaignFormFooterModule = (props: CampaignFormFooterModuleProps) => {
    const { heroImage, formEmbedUrl, customClassName, formTitle } = props;
    const { css } = useStyles({
        backgroundImageUrl: heroImage?.desktopSrc ?? '',
        customClassName,
    });
    return (
        <div className={css(S.FooterContainer, S.Background)}>
            <IframeEmbedModule
                title={formTitle ?? "LET'S COLLABORATE"}
                embedLink={formEmbedUrl}
                heroImageSrc={heroImage?.mobileSrc ?? ''}
            />
        </div>
    );
};

const IframeEmbedModule = ({
    title,
    embedLink,
    frameHeight,
    anchor,
    heroImageSrc,
}: FormProps) => {
    const [maxFrameHeight, setMaxFrameHeight] = useState(
        Math.max(frameHeight || 0, 200)
    );

    useEffect(() => {
        const handleFrameHeightUpdate = (event: { data: number }) => {
            if (!isNaN(event.data)) {
                const chooseMaxHeight = Math.max(
                    frameHeight || 0,
                    200,
                    event.data
                );
                setMaxFrameHeight(chooseMaxHeight);
            }
        };

        window.addEventListener('message', handleFrameHeightUpdate);

        return () => {
            window.removeEventListener('message', handleFrameHeightUpdate);
        };
    }, [frameHeight]);

    const { css } = useStyles({
        maxFrameHeight,
        backgroundImageUrl: heroImageSrc,
    });

    if (!embedLink) {
        return null;
    }

    return (
        <Flex vAlign="center" hAlign="center" id="form-footer">
            <div className={css(S.ModuleContainer)} id={anchor}>
                <Caption
                    as="h1"
                    variables={{
                        fontSize: pxToRem(24),
                    }}
                >
                    {title}
                </Caption>
                <iframe
                    src={embedLink}
                    width="100%"
                    height={maxFrameHeight}
                    frameBorder="0"
                    scrolling="no"
                    title={title || 'external content'}
                />
            </div>
        </Flex>
    );
};

export default CampaignFormFooterModule;

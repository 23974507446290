import type { TextCarouselItem } from '@modules/elemental-page/type';
import { Flex, useStyles } from '@archipro-design/aria';

import * as S from './TextContent.style';
import LinkTags from '@modules/elemental-page/component/text-cta-content/LinkTags';
import TextContent from '@modules/elemental-page/component/text-cta-content/TextContent';
import MainCta from '@modules/elemental-page/component/text-cta-content/MainCta';

interface TextCtaSplitContentProps {
    item: TextCarouselItem;
    darkTheme: boolean;
}

const TextCtaSplitContent = ({ item, darkTheme }: TextCtaSplitContentProps) => {
    const { css } = useStyles({ darkTheme });

    return (
        <Flex className={css(S.SplitContent)}>
            <div>
                <TextContent {...item} darkTheme={darkTheme} />
                <div className={css(S.ExtraSpacing)}>
                    <LinkTags {...item} darkTheme={darkTheme} />
                </div>
            </div>
            <div className={css(S.CtaCol)}>
                <MainCta {...item} darkTheme={darkTheme} />
            </div>
        </Flex>
    );
};

export default TextCtaSplitContent;

import type { ReactChildren } from '@fluentui/react-northstar';
import { useStyles } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';
import { BaseSectionStyling } from './BaseSection.style';
import type { ThemedElementColourType } from '@modules/elemental-page/type';
import { forwardRef } from 'react';

interface BaseSectionProps {
    id?: string;
    background?: ThemedElementColourType;
    children?: ReactChildren;
    style?: StyleRule;
}

const BaseSection = forwardRef<HTMLDivElement, BaseSectionProps>(
    ({ id, background, children, style = {} }, ref) => {
        const { css } = useStyles({ background });
        return (
            <div ref={ref} id={id} className={css(BaseSectionStyling, style)}>
                {children}
            </div>
        );
    }
);

BaseSection.displayName = 'BaseSection';

export default BaseSection;

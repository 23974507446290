import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseContainerOverride: StyleRule = ({ theme }) => ({
    padding: `${pxToRem(22)} 0 ${pxToRem(67)} 0`,
    [theme.screen.laptop]: {
        padding: `${pxToRem(50)} ${pxToRem(110)} ${pxToRem(160)} 0`,
        display: 'flex',
        alignItems: 'center',
    },
});

const WIDTH_DESKTOP_IMAGE = 890;
const WIDTH_TABLET_IMAGE = 722;

export const ImageContainerDesktop: StyleRule = ({ theme }) => ({
    display: 'block',
    margin: '0',
    position: 'relative',
    [theme.screen.tablet]: {
        width: pxToRem(WIDTH_TABLET_IMAGE),
        maxWidth: pxToRem(WIDTH_TABLET_IMAGE),
        minWidth: pxToRem(WIDTH_TABLET_IMAGE),
        height: pxToRem(630),
    },
    [theme.screen.laptop]: {
        height: pxToRem(798),
        width: pxToRem(WIDTH_DESKTOP_IMAGE),
        maxWidth: pxToRem(WIDTH_DESKTOP_IMAGE),
        minWidth: pxToRem(WIDTH_DESKTOP_IMAGE),
    },
});

export const ImageContainerMobile: StyleRule = ({ theme }) => ({
    display: 'block',
    height: pxToRem(468),
    width: '100%',
    margin: '0',
    position: 'relative',
    [theme.screen.tablet]: {
        display: 'none',
    },
});

export const ContentContainer: StyleRule = ({ theme }) => ({
    margin: `0 ${pxToRem(16)}`,
    [theme.screen.laptop]: {
        margin: 0,
        marginLeft: `${pxToRem(86)}`,
        maxWidth: pxToRem(816),
        marginTop: `${pxToRem(11)}`,
    },
});

export const HeaderOverrides: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[250],
    fontSize: `${theme.siteVariables.fontSizes.subheading01} !important`, // overwrite aria's font-size
    lineHeight: `${pxToRem(41)}`,
    letterSpacing: theme.siteVariables.letterSpacingMedium,
    maxWidth: pxToRem(358),
    marginTop: pxToRem(31),
    marginBottom: pxToRem(30),
    [theme.screen.laptop]: {
        ...theme.siteVariables.textStyles.Heading.Bold75,
        textTransform: 'uppercase',
        fontSize: `${pxToRem(40)} !important`, // overwrite aria's font-size
        lineHeight: pxToRem(54),
        letterSpacing: theme.siteVariables.letterSpacingNormal,
        marginTop: 0,
        marginBottom: pxToRem(60),
        fontWeight: '500',
        maxWidth: 'none',
    },
});

export const ParagraphOverrides: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[250],
    fontSize: theme.siteVariables.fontSizes.label02,
    lineHeight: theme.siteVariables.lineHeightSmall,
    [theme.screen.laptop]: {
        marginTop: pxToRem(60),
        fontSize: pxToRem(40),
        lineHeight: pxToRem(48),
    },
    margin: '0px',
    '& p': {
        margin: '0',
    },
});

export const BookingConfirmedText: StyleRule = ({ theme }) => ({
    fontSize: theme.siteVariables.fontSizes.label01,
    lineHeight: theme.siteVariables.lineHeightDeafult,
    color: theme.siteVariables.colors.charcoal[250],
    marginTop: pxToRem(46),
    [theme.screen.laptop]: {
        fontSize: pxToRem(40),
        lineHeight: pxToRem(54),
        paddingBottom: pxToRem(7),
        borderBottom: `${pxToRem(2)} solid transparent`,
        marginTop: pxToRem(88),
    },
});

export const AcceptIconStyle: StyleRule = ({ theme }) => ({
    width: pxToRem(19),
    marginLeft: pxToRem(16),
    [theme.screen.laptop]: {
        width: pxToRem(32),
    },
});

import { Caption, useStyles } from '@archipro-design/aria';
import * as S from './SimpleCTAModule.style';
import { CTA } from '@modules/elemental-page/component/text-cta-content/TextContent.style';
import type { CTAModuleProps } from '@modules/elemental-page/type';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

import type { ThemedElementColourType } from '@modules/elemental-page/type';

interface ThemedSimpleCTAModuleProps extends CTAModuleProps {
    theme: ThemedElementColourType;
}

const SimpleCTAModule = ({
    cta,
    content,
    theme,
}: ThemedSimpleCTAModuleProps) => {
    const darkTheme = theme === 'dark';
    const { css } = useStyles({ darkTheme });

    return (
        <div className={css(S.SimpleCTAContainer)}>
            <Caption className={css(S.Title)}>{content}</Caption>
            {cta && (
                <div className={css(CTA, S.CTA)}>
                    <AboutCallToAction
                        {...cta}
                        buttonTheme={darkTheme ? 'light' : 'dark'}
                    />
                </div>
            )}
        </div>
    );
};

export default SimpleCTAModule;

import {
    Header,
    useStyles,
    Image,
    Flex,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import { AcceptIcon } from '@fluentui/react-icons-northstar';
import DemoLink from '../demo-link/DemoLink';
import BaseSection from '../base-section/BaseSection';
import { useElementalPage } from '../../context/ElementalPageContext';
import * as S from './WelcomeModule.style';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import type { WelcomeModuleProps } from '@modules/elemental-page/type';

const WelcomeModule = ({
    title,
    content,
    image,
    cta,
    anchor,
}: WelcomeModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    const { state } = useElementalPage();

    return (
        <BaseSection style={S.BaseContainerOverride} id={anchor}>
            {!!Image && (
                <>
                    {mobile ? (
                        <div className={css(S.ImageContainerMobile)}>
                            <Image
                                src={image?.mobileSrc || ''}
                                width={414}
                                height={468}
                                layout="fill"
                                objectFit="contain"
                                objectPosition="center"
                                loading="eager"
                                sizes={getImagesSizes(890)}
                                variables={{
                                    placeholderBackgroundColorLight:
                                        'transparent',
                                }}
                                alt={'Archipro welcome ecommerce image'}
                            />
                        </div>
                    ) : (
                        <div className={css(S.ImageContainerDesktop)}>
                            <Image
                                src={image?.desktopSrc || ''}
                                width={1125}
                                height={798}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="right"
                                loading="eager"
                                sizes={getImagesSizes(890)}
                                variables={{
                                    placeholderBackgroundColorLight:
                                        'transparent',
                                }}
                                alt={'Archipro  welcome ecommerce image'}
                            />
                        </div>
                    )}
                </>
            )}
            <div className={css(S.ContentContainer)}>
                {!!title && (
                    <Header as="h1" className={css(S.HeaderOverrides)}>
                        {title}
                    </Header>
                )}
                {!!content && (
                    <div
                        className={css(S.ParagraphOverrides)}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                )}
                {state.meetingBooked ? (
                    <Flex
                        vAlign="center"
                        className={css(S.BookingConfirmedText)}
                    >
                        <div>Booking Confirmed</div>
                        <AcceptIcon className={css(S.AcceptIconStyle)} />
                    </Flex>
                ) : (
                    <div>{cta?.title && <DemoLink {...cta} />}</div>
                )}
            </div>
        </BaseSection>
    );
};

export default WelcomeModule;

import type { StyleRule } from '@archipro-design/aria';
import { imageClassName, pxArrayToRem, pxToRem } from '@archipro-design/aria';
import { MOBILE_CONTAINER_SIDE_GUTTER } from '@modules/elemental-page/config/module-constants';

export const Module: StyleRule = () => {
    return {
        position: 'relative',
    };
};

export const Container: StyleRule = () => {
    return {
        position: 'relative',
        textAlign: 'center',
    };
};

export const PhotoCollage: StyleRule = ({ theme }) => {
    return {
        maxWidth: pxToRem(1000 + 2 * MOBILE_CONTAINER_SIDE_GUTTER),
        padding: pxArrayToRem([0, MOBILE_CONTAINER_SIDE_GUTTER]),
        margin: 'auto',
        display: 'grid',
        gap: pxToRem(3),
        gridTemplateColumns: 'repeat(3, 1fr)',

        [theme.screen.laptop]: {
            gap: pxToRem(8),
        },
    };
};

export const Frame: StyleRule = () => {
    return {
        width: '100%',
        paddingTop: '100%',
        position: 'relative',

        [`& .${imageClassName}`]: {
            position: 'absolute',
            top: 0,
            left: 0,
        },
    };
};

import type { StyleRule } from '@archipro-design/aria';

export const BannerImageStyling: StyleRule = ({ theme }) => {
    return {
        display: 'none',

        [theme.screen.tablet]: {
            display: 'block',
        },
    };
};

export const BannerImageMobileStyling: StyleRule = ({ theme }) => {
    return {
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

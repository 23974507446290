import Marquee from 'react-fast-marquee';
import {
    ECOMMERCE_LOGO_HEIGHT,
    LogoContainer,
    MarqueeWrapper,
} from './EcommerceLogoMarquee.style';
import { Image, useStyles } from '@archipro-design/aria';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import type { FixedSizedImage } from '@modules/elemental-page/type';

interface EcommerceLogoMarqueeProps {
    logos: FixedSizedImage[];
    size: 'default' | 'small';
}

const EcommerceLogoMarquee = ({
    logos,
    size = 'default',
}: EcommerceLogoMarqueeProps) => {
    const { css } = useStyles({ height: ECOMMERCE_LOGO_HEIGHT[size] });

    return (
        <div className={css(MarqueeWrapper)}>
            {logos && (
                <Marquee
                    speed={50}
                    direction={'left'}
                    play={true}
                    gradient={false}
                    className={css({ overflowY: 'hidden' })}
                >
                    {logos.map((logo) => {
                        return (
                            logo?.src &&
                            logo?.height &&
                            logo?.width && (
                                <div
                                    className={css(LogoContainer)}
                                    key={logo.src}
                                    style={{
                                        aspectRatio: String(
                                            logo.width / logo.height
                                        ),
                                    }}
                                >
                                    <Image
                                        src={logo.src}
                                        layout={'raw'}
                                        sizes={getImagesSizes({
                                            desktop: 340,
                                            mobile: 240,
                                        })}
                                        height={logo.height}
                                        width={logo.width}
                                        alt=""
                                        {...getCustomPlaceholderColor('light')}
                                    />
                                </div>
                            )
                        );
                    })}
                </Marquee>
            )}
        </div>
    );
};

export default EcommerceLogoMarquee;

import type { StyleRule } from '@archipro-design/aria';
import type { ImageDimension } from '@modules/elemental-page/type';
import { captionClassName, pxToRem } from '@archipro-design/aria';
import type { ImageTextCarouselModuleOrientation } from '@modules/elemental-page/type';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const ImageContainer: StyleRule<{
    dimension: ImageDimension;
    orientation?: ImageTextCarouselModuleOrientation;
}> = ({ theme, dimension, orientation }) => ({
    position: 'relative',
    height: pxToRem(dimension.height),
    width: pxToRem(dimension.width),
    [theme.screen.tablet]: {
        marginLeft: orientation === 'image-text' ? 'auto' : 0,
        marginRight: orientation === 'image-text' ? 0 : 'auto',
    },
});

export const SingleSlideWrapper: StyleRule<{
    orientation?: ImageTextCarouselModuleOrientation;
}> = ({ orientation }) => ({
    marginLeft: orientation === 'image-text' ? pxToRem(120) : 0,
    marginRight: orientation === 'image-text' ? 0 : pxToRem(120),
});

export const MainCaption: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`&.${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Text.Medium65,
        fontSize: theme.siteVariables.fontSizes.caption04,
        color: guestHomeTheme(theme, darkTheme).main.text2,
        lineHeight: '140%',
        marginTop: pxToRem(11),

        [theme.screen.tablet]: {
            ...theme.siteVariables.textStyles.Heading.Roman55,
            fontSize: theme.siteVariables.fontSizes.caption01,
            marginTop: pxToRem(20),
        },
    },
});

export const SubCaption: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    [`&.${captionClassName}`]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        fontSize: theme.siteVariables.fontSizes.caption04,
        color: guestHomeTheme(theme, darkTheme).main.text,
        lineHeight: '140%',

        [theme.screen.tablet]: {
            fontSize: theme.siteVariables.fontSizes.caption01,
        },
    },
});

import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    imageClassName,
    HeaderClassName,
} from '@archipro-design/aria';
import { MOBILE_CONTAINER_SIDE_GUTTER } from '@modules/elemental-page/config/module-constants';

export const Module: StyleRule = () => {
    return {
        position: 'relative',
    };
};

export const Container: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        maxWidth: pxToRem(1000 + 2 * MOBILE_CONTAINER_SIDE_GUTTER),
        padding: pxArrayToRem([0, MOBILE_CONTAINER_SIDE_GUTTER]),
        margin: 'auto',
        textAlign: 'center',

        [`& .${HeaderClassName}`]: {
            fontSize: pxToRem(20),
            ...theme.siteVariables.textStyles.Heading.Medium65,

            [theme.screen.laptop]: {
                fontSize: pxToRem(22),
            },
        },
    };
};

export const RichContent: StyleRule = ({ theme }) => {
    return {
        fontSize: theme.siteVariables.fontSizes.caption04,
        lineHeight: theme.siteVariables.lineHeightSmaller,
        '& p': {
            marginBottom: pxToRem(16),
            lineHeight: 1.215,
        },

        [theme.screen.laptop]: {
            fontSize: theme.siteVariables.fontSizes.caption03,
            lineHeight: 1.6,
            '& p': {
                marginBottom: pxToRem(28),
                lineHeight: '1.6',
            },
        },
    };
};

export const FooterImage: StyleRule = () => {
    return {
        position: 'relative',
        width: '100%',
        paddingTop: '50%',
        marginTop: pxToRem(60),
        [`& .${imageClassName}`]: {
            position: 'absolute',
            top: 0,
            left: 0,
        },
    };
};

import * as S from './TestimonialModule.style';
import {
    Paragraph,
    Image,
    useStyles,
    useAppDisplayModeContext,
} from '@archipro-design/aria';

import BaseSection from '../base-section/BaseSection';
import { ArrowNextLine, ArrowPrevLine } from '@archipro-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import classnames from 'classnames';
import type { ThemedElementColourType } from '@modules/elemental-page/type';
import { useTracker } from '@archipro-website/tracker';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import type { TestimonialModuleProps } from '@modules/elemental-page/type';

SwiperCore.use([Navigation]);

interface ThemedTestimonialModuleProps extends TestimonialModuleProps {
    theme?: ThemedElementColourType;
}

const TestimonialModule = ({
    testimonials,
    theme,
    anchor,
}: ThemedTestimonialModuleProps) => {
    const { css } = useStyles({ theme });
    const { mobile } = useAppDisplayModeContext();
    const { log } = useTracker();

    const onClick = () => {
        log('EcomLandingPage_TestimonialInteraction', {
            url: new URL(window.location.href),
            label: 'Testimonial slider',
        });
    };

    return (
        <BaseSection background={theme} style={S.BaseSection} id={anchor}>
            <Swiper
                slidesPerView={1}
                navigation={{
                    nextEl: '.slider-button-next',
                    prevEl: '.slider-button-prev',
                }}
                allowSlideNext
                allowSlidePrev
                allowTouchMove
                loop
                className={css(S.TestimonialSlider)}
            >
                {testimonials &&
                    testimonials.length > 0 &&
                    testimonials.map((slide, index) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className={css(S.TestimonialSlide)}
                            >
                                <div className={css(S.TestimonialSlideInner)}>
                                    <div className={css(S.SlideColumn)}>
                                        {slide.photo?.desktopSrc &&
                                            (mobile ? (
                                                <div
                                                    className={css(
                                                        S.TestimonialPhotoMobile
                                                    )}
                                                >
                                                    <Image
                                                        src={
                                                            slide.photo
                                                                .mobileSrc ??
                                                            slide.photo
                                                                .desktopSrc
                                                        }
                                                        width={248}
                                                        height={323}
                                                        layout="responsive"
                                                        {...getCustomPlaceholderColor(
                                                            'dark'
                                                        )}
                                                        sizes={getImagesSizes({
                                                            desktop: 586,
                                                            mobile: 312,
                                                        })}
                                                        alt={`${slide.author} testimonial image`}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className={css(
                                                            S.TestimonialPhotoDesktop
                                                        )}
                                                    >
                                                        <Image
                                                            src={
                                                                slide.photo
                                                                    .desktopSrc
                                                            }
                                                            width={586}
                                                            height={765}
                                                            layout="responsive"
                                                            sizes={getImagesSizes(
                                                                586
                                                            )}
                                                            alt={`${slide.author} testimonial image`}
                                                            {...getCustomPlaceholderColor(
                                                                'dark'
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            ))}
                                    </div>
                                    <div className={css(S.SlideColumnMobile)}>
                                        {slide?.content && (
                                            <div
                                                className={css(
                                                    S.TestimonialBody
                                                )}
                                                dangerouslySetInnerHTML={{
                                                    __html: slide.content,
                                                }}
                                            />
                                        )}
                                        <div
                                            className={css(S.TestimonialFooter)}
                                        >
                                            <div
                                                className={css(
                                                    S.TestimonialDivider
                                                )}
                                            />
                                            {slide?.author && (
                                                <Paragraph>
                                                    {slide.author}
                                                </Paragraph>
                                            )}
                                            {slide?.organisation && (
                                                <Paragraph>
                                                    {slide.organisation}
                                                </Paragraph>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
            <div className={css(S.TestimonialSliderControls)}>
                <button
                    className={classnames(
                        css(S.ButtonSlider),
                        'slider-button-prev'
                    )}
                    onClick={onClick}
                >
                    <ArrowPrevLine className={css(S.TestimonialSliderPrev)} />
                </button>
                <button
                    className={classnames(
                        css(S.ButtonSlider),
                        'slider-button-next'
                    )}
                    onClick={onClick}
                >
                    <ArrowNextLine className={css(S.TestimonialSliderPrev)} />
                </button>
            </div>
        </BaseSection>
    );
};

export default TestimonialModule;

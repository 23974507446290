import { pxArrayToRem, pxToRem, type StyleRule } from '@archipro-design/aria';
import { extractValueFromClass } from '../../util/extract-value-from-class';

export const ModuleContainer: StyleRule<{ maxFrameHeight: number }> = ({
    maxFrameHeight,
    theme,
}) => {
    return {
        width: '100%',
        margin: pxToRem(20),
        padding: pxToRem(20),
        background: 'white',
        textAlign: 'center',
        minHeight: pxToRem(maxFrameHeight),
        [theme.screen.tablet]: {
            padding: pxToRem(40),
            width: pxToRem(528),
            margin: 0,
            input: {
                margin: pxArrayToRem([20, 0]),
            },
        },
    };
};

export const Background: StyleRule<{
    customClassName?: string | null;
}> = ({ customClassName, theme }) => {
    return {
        backgroundColor: customClassName
            ? extractValueFromClass('bg', customClassName)
            : '#1c1c1c',
        padding: pxArrayToRem([20, 0]),
        [theme.screen.tablet]: {
            padding: pxToRem(200),
        },
    };
};

export const FormContainer: StyleRule<{ backgroundImageUrl: string }> = ({
    theme,
    backgroundImageUrl,
}) => {
    return {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        [theme.screen.tablet]: {
            backgroundImage: 'none',
        },
    };
};

export const FooterContainer: StyleRule<{ backgroundImageUrl: string }> = ({
    backgroundImageUrl,
}) => {
    return {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };
};

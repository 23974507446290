import {
    useAppDisplayModeContext,
    useStyles,
    VideoPlayer,
} from '@archipro-design/aria';
import * as S from './VideoModule.style';
import React, { useRef } from 'react';
import type { VideoModuleProps } from '@modules/elemental-page/type';
import { ClientOnly } from 'remix-utils';
import { useInViewport } from 'ahooks';
import { useLoginModal } from '@modules/design-board';
import { useUser } from '@modules/user';
import { Link } from '@remix-run/react';
import { useTracker } from '@archipro-website/tracker';

const VideoModule = ({
    videoUrl,
    videoCover,
    title,
    anchor,
    playsInline,
    autoplay,
    autoPlayRedirect,
    customClassName,
    autoPlayRedirectRequireLogin,
    fullWidth,
}: VideoModuleProps) => {
    const { css } = useStyles({ playsInline, fullWidth, customClassName });
    const ref = useRef(null);
    const [inViewport] = useInViewport(ref, {
        threshold: 0.75,
    });
    const { mobile } = useAppDisplayModeContext();
    const tracker = useTracker();
    const user = useUser();
    const isLoggedIn = user.__typename === 'Me';
    const canLogin = !isLoggedIn && autoPlayRedirectRequireLogin;
    const { showLoginModal, loginModal } = useLoginModal({
        customRedirect: autoPlayRedirect,
        allowExternalUrls: true,
        authSource: 'videoModule',
    });
    const extraVideoProps = {
        playing: autoplay && inViewport,
        muted: autoplay,
        controls: !autoplay,
        playsInline: playsInline,
        variables: {
            playIconSizeDesktop: autoplay ? 0 : 100,
            playIconSizeMobile: autoplay ? 0 : 40,
            coverImagePosition: 'absolute',
        },
        trackPlayEvent: () => {
            tracker.log('Video', {
                url: new URL(window.location.href),
            });
        },
    };

    const videoPlayer = (
        <VideoPlayer
            src={videoUrl || ''}
            coverImage={{
                src: videoCover?.desktopSrc || '',
                height: mobile ? 286 : fullWidth ? 810 : 690,
                width: mobile ? 414 : fullWidth ? 1760 : 1000,
                layout: 'intrinsic',
                objectFit: 'cover',
                objectPosition: 'center',
                alt: title || '',
            }}
            {...extraVideoProps}
        />
    );

    return (
        <div ref={ref} id={anchor} className={css(S.VideoModuleContainer)}>
            <div className={css(S.Frame)}>
                <div className={css(S.VideoAspect)}>
                    {playsInline ? (
                        <ClientOnly fallback={<></>}>
                            {() => videoPlayer}
                        </ClientOnly>
                    ) : (
                        videoPlayer
                    )}

                    {autoplay &&
                        autoPlayRedirect &&
                        autoPlayRedirect.length > 0 && (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <Link
                                to={autoPlayRedirect}
                                onClick={(e) => {
                                    if (autoPlayRedirectRequireLogin) {
                                        e.preventDefault();
                                        showLoginModal();
                                    }
                                }}
                                className={css(S.AutoPlayClickBlocker)}
                            >
                                {''}
                            </Link>
                        )}
                </div>
            </div>

            {canLogin && loginModal}
        </div>
    );
};

export default VideoModule;

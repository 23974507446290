import { useStyles, Flex } from '@archipro-design/aria';
import React, { useState } from 'react';
import type { Maps } from 'google-map-react';
import GoogleMap from 'google-map-react';
import { useRootData } from '@modules/root';
import {
    getArchiProMapVariables,
    GOOGLE_MAP_STYLES,
} from '~/modules/professional/component/contact-tab/LocationConstant';
import type { LocationDataType } from '@modules/professional/type/contact-page-types';
import LocationMapMarker from '~/modules/professional/component/contact-tab/LocationMapMarker';
import * as S from './ContactUsMapModule.style';
import { AP_LOCATIONS } from '~/modules/elemental-page/component/contact-us-map-module/ArchiproLocations';
import type { CommonModule } from '~/modules/elemental-page/type';

const ContactUsMapModule = (moduleProps: CommonModule) => {
    const { localisation, config, appDisplayMode } = useRootData();
    const isAu = localisation.country === 'AU';
    const isMobile = appDisplayMode === 'mobile';
    const spaceBelow = isMobile
        ? moduleProps.spacingBelowMobile
        : moduleProps.spacingBelow;
    const moduleAnchor = moduleProps.anchor || moduleProps.type;

    const { css } = useStyles({ isMobile, isAu, spaceBelow });

    const apLocations = isAu
        ? [...AP_LOCATIONS.slice(1), ...AP_LOCATIONS.slice(0, 1)]
        : AP_LOCATIONS;

    const zoom = 9;
    const defaultCenter = {
        lat: apLocations[0]?.MapCoordinates.Latitude || 0,
        lng: apLocations[0]?.MapCoordinates.Longitude || 0,
    };

    const [hoveredLocation, setHoveredLocation] = useState<LocationDataType>();
    const [clickedLocation, setClickedLocation] = useState<LocationDataType>();
    const [center, setCenter] = useState(defaultCenter);

    const onClickLocationText = (location: LocationDataType) => {
        setClickedLocation(location);
        setHoveredLocation(location);
        setCenter({
            lat: location.MapCoordinates.Latitude,
            lng: location.MapCoordinates.Longitude,
        });
        isMobile && window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const mapVariables = getArchiProMapVariables(
        localisation.country,
        isMobile
    );

    const mapOptions = (maps: Maps) => {
        return {
            styles: GOOGLE_MAP_STYLES,
            fullscreenControl: false,
            zoomControl: false,
            zoomControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT,
            },
            minZoom: mapVariables.zoom.min,
            maxZoom: mapVariables.zoom.max,
            clickableIcons: false,
        };
    };
    return (
        <div className={css(S.MapAndLocationText)} id={moduleAnchor}>
            <div className={css(S.RightMapSection)}>
                <GoogleMap
                    bootstrapURLKeys={{
                        key: config.pixels.websiteGoogleMapKey,
                        language: 'en',
                        region: 'new zealand',
                    }}
                    center={center}
                    zoom={zoom}
                    options={mapOptions}
                >
                    {apLocations.map(
                        (locationData, index) =>
                            locationData?.ID &&
                            locationData.MapCoordinates && (
                                <LocationMapMarker
                                    key={index}
                                    lat={locationData.MapCoordinates.Latitude}
                                    lng={locationData.MapCoordinates.Longitude}
                                    locationID={locationData.ID}
                                    hoveredLocation={hoveredLocation}
                                    clickedLocation={clickedLocation}
                                />
                            )
                    )}
                </GoogleMap>
            </div>
            <Flex
                space={'evenly'}
                vAlign={'start'}
                className={css(S.LocationText)}
            >
                {apLocations.map((location, index) => (
                    <dl
                        key={index}
                        onClick={() => onClickLocationText(location)}
                    >
                        <dt>
                            {location.ID === clickedLocation?.ID ? (
                                <u>{location.Title}</u>
                            ) : (
                                location.Title
                            )}
                        </dt>
                        <dd>
                            <div>{location.Address1}</div>
                            <div>{location.Address2}</div>
                            <div>{`${location.Suburb}, ${location.Region} ${location.PostCode}`}</div>
                            <div>{location.Country}</div>
                            {location.Phone && (
                                <a
                                    href={`tel:${location.Phone.replaceAll(
                                        ' ',
                                        ''
                                    )}`}
                                >
                                    {location.Phone}
                                </a>
                            )}
                        </dd>
                    </dl>
                ))}
            </Flex>
        </div>
    );
};

export default ContactUsMapModule;

import { Image, Caption, useStyles } from '@archipro-design/aria';
import * as S from './GuestHomeHeroModule.style';
import DefaultHeading from '@modules/elemental-page/component/heading-module/default-heading.svg?url';

const HeroWelcomeMessageMobile = () => {
    const { css } = useStyles();

    return (
        <div className={css(S.HeroWelcomeSection)}>
            <Caption className={css(S.WelcomeMessage)}>Welcome to</Caption>
            <Image
                width={282}
                height={32}
                src={DefaultHeading}
                alt="ArchiPro"
                variables={{
                    placeholderBackgroundColorLight: 'transparent',
                }}
            />
        </div>
    );
};

export default HeroWelcomeMessageMobile;

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const AccordionModule: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    position: 'relative',
    padding: pxArrayToRem([0, 16, 120, 16]),
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    [theme.screen.tablet]: {
        padding: pxArrayToRem([80, 0, 280, 0]),
    },
});
export const Accordions: StyleRule = () => ({
    maxWidth: pxToRem(1400),
    margin: 'auto',
});

export const AccordionHead: StyleRule = ({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
    gap: pxToRem(30),
    padding: 0,
    [theme.screen.tablet]: {
        gap: pxToRem(50),
        padding: pxArrayToRem([40, 0]),
    },
});

export const AccordionIndex: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    ...theme.siteVariables.textStyles.Heading.Medium65,
    color: guestHomeTheme(theme, darkTheme).main.text2,
    border: `1px solid ${guestHomeTheme(theme, darkTheme).main.text2}`,
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: pxToRem(11),
    width: pxToRem(18),
    minWidth: pxToRem(18),
    height: pxToRem(18),
    lineHeight: pxToRem(17),
    alignSelf: 'center',
    [theme.screen.tablet]: {
        fontSize: pxToRem(19),
        width: pxToRem(46),
        minWidth: pxToRem(46),
        height: pxToRem(46),
        lineHeight: pxToRem(45),
    },
});

export const AccordionTitle: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    flexGrow: 1,
    ...theme.siteVariables.textStyles.Heading.Medium65,
    color: guestHomeTheme(theme, darkTheme).main.text2,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
    [theme.screen.max.tablet]: {
        fontSize: theme.siteVariables.fontSizes.caption03s,
        letterSpacing: pxToRem(0.6),
        lineHeight: pxToRem(40),
    },
    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: theme.siteVariables.fontSizes.subheading02,
        lineHeight: pxToRem(46),
    },
});

export const Indicator: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    width: pxToRem(24),
    alignSelf: 'center',
    marginRight: pxToRem(15),
    [`& svg`]: {
        fill: guestHomeTheme(theme, darkTheme).main.text2,
    },
    [theme.screen.tablet]: {
        marginRight: 0,
    },
});

export const AccordionBody: StyleRule<{ expanded: boolean }> = ({
    expanded,
}) => ({
    maxWidth: pxToRem(1302),
    transition: `max-height 0.3s ease-in-out`,
    maxHeight: expanded ? pxToRem(1000) : 0,
    overflow: 'hidden',
});

export const Content: StyleRule<{ expanded: boolean; darkTheme: boolean }> = ({
    theme,
    expanded,
    darkTheme,
}) => ({
    color: guestHomeTheme(theme, darkTheme).main.text,
    lineHeight: '140%',
    transition: `opacity 0.2s linear`,
    opacity: expanded ? 1 : 0,
    margin: pxArrayToRem([16, 25, 30, 0]),
    [theme.screen.max.tablet]: {
        fontSize: theme.siteVariables.fontSizes.caption03s,
        lineHeight: '150%',
    },
    [theme.screen.tablet]: {
        fontSize: theme.siteVariables.fontSizes.caption01,
        letterSpacing: pxToRem(-0.15),
        margin: pxArrayToRem([22, 141, 78, 141]),
    },
});

export const Divider: StyleRule = () => ({
    margin: 0,
});

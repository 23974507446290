import { Caption, useStyles } from '@archipro-design/aria';

import * as S from './TextContent.style';

interface TextContentProps {
    title?: string;
    subtitle?: string;
    content?: string;
    darkTheme: boolean;
}

const TextContent = ({
    title,
    subtitle,
    content,
    darkTheme,
}: TextContentProps) => {
    const { css } = useStyles({ darkTheme });
    return (
        <>
            {subtitle && (
                <Caption className={css(S.Subtitle)}>{subtitle}</Caption>
            )}
            {title && <Caption className={css(S.Title)}>{title}</Caption>}
            {content && <Caption className={css(S.Content)}>{content}</Caption>}
        </>
    );
};

export default TextContent;

import type { StyleRule } from '@archipro-design/aria';
import {
    HeaderClassName,
    captionClassName,
    pxArrayToRem,
    pxToRem,
} from '@archipro-design/aria';
import { MOBILE_CONTAINER_SIDE_GUTTER } from '@modules/elemental-page/config/module-constants';

export const Module: StyleRule = () => {
    return {
        position: 'relative',
    };
};

export const Container: StyleRule = ({ theme }) => {
    return {
        maxWidth: pxToRem(1000 + 2 * MOBILE_CONTAINER_SIDE_GUTTER),
        padding: pxArrayToRem([0, MOBILE_CONTAINER_SIDE_GUTTER]),
        margin: 'auto',
        textAlign: 'center',

        [`& .${HeaderClassName}`]: {
            fontSize: pxToRem(20),
            ...theme.siteVariables.textStyles.Heading.Medium65,

            [theme.screen.laptop]: {
                fontSize: pxToRem(22),
            },
        },
    };
};

export const SectionGrid: StyleRule = () => {
    return {
        gridColumnGap: pxToRem(16),
        gridRowGap: pxToRem(60),
    };
};

export const Slider: StyleRule = () => {
    return {
        margin: pxArrayToRem([0, 49]),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };
};

export const Slide: StyleRule = () => {
    return {
        whiteSpace: 'normal',
        width: '100%',
        display: 'inline-block',
    };
};

export const SliderActions: StyleRule = () => {
    return {
        marginTop: pxToRem(28),
    };
};

export const NavigateButton: StyleRule = () => {
    return {
        border: 'none',
        background: 'transparent',
    };
};

export const SectionBlock: StyleRule = () => {
    return {
        textAlign: 'left',
    };
};

export const RowHead: StyleRule = ({ theme }) => {
    return {
        backgroundColor: theme.siteVariables.colors.charcoal['000'],
        padding: pxToRem(8),
        marginBottom: pxToRem(17),
        [`& .${captionClassName}`]: {
            ...theme.siteVariables.textStyles.Heading.Medium65,
            letterSpacing: `-0.04em`,
            textTransform: 'uppercase',
            [theme.screen.laptop]: {
                letterSpacing: `-0.01em`,
            },
        },
        [theme.screen.laptop]: {
            marginBottom: pxToRem(20),
        },
    };
};

export const RowLink: StyleRule = ({ theme }) => {
    return {
        padding: pxArrayToRem([4.5, 6]),
        marginBottom: pxToRem(1),
        borderBottom: `1px solid ${theme.siteVariables.colors.primitive.black}`,
        [`& a`]: {
            color: theme.siteVariables.colors.charcoal['000'],
            [`&:hover`]: {
                textDecoration: 'none',
            },
        },
        [theme.screen.laptop]: {
            padding: pxToRem(8),
        },
    };
};

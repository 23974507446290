import type { LocationDataType } from '~/modules/professional/type';

export const AP_LOCATIONS: LocationDataType[] = [
    {
        ID: 1,
        Title: 'Auckland',
        Type: 'Showroom',
        Phone: '0800 272 776',
        Address1: 'Level 3 Textile Centre',
        Address2: '117-125 Saint Georges Bay Road',
        Suburb: 'Parnell',
        City: 'Auckland',
        Region: 'Auckland',
        Country: 'New Zealand',
        PostCode: '1052',
        AllowsPickup: true,
        MapCoordinates: {
            Latitude: -36.852,
            Longitude: 174.775,
        },
    },
    {
        ID: 2,
        Title: 'Sydney',
        Type: 'Showroom',
        Phone: '',
        Address1: '346 Kent Street',
        Address2: '',
        Suburb: 'Sydney',
        City: 'Sydney',
        Region: 'NSW',
        Country: 'Australia',
        PostCode: '2000',
        AllowsPickup: true,
        MapCoordinates: {
            Latitude: -33.875,
            Longitude: 151.205,
        },
    },
    {
        ID: 3,
        Title: 'Melbourne',
        Type: 'Showroom',
        Phone: '',
        Address1: '10-20 Gwynne St,',
        Address2: '',
        Suburb: 'Cremorne',
        City: 'Melbourne',
        Region: 'VIC',
        Country: 'Australia',
        PostCode: '3121',
        AllowsPickup: true,
        MapCoordinates: {
            Latitude: -37.827,
            Longitude: 144.99,
        },
    },
];

import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, useTheme, HeaderClassName } from '@archipro-design/aria';
import type { EcommerceHeadingColor } from './EcommerceHeading';

interface FontColor {
    [key: string]: string;
}

export const HeaderStyling: StyleRule = ({ theme }) => {
    return {
        [`&.${HeaderClassName}`]: {
            fontSize: pxToRem(40),
            lineHeight: 1,
            marginBottom: pxToRem(67),
            marginTop: '0',

            [theme.screen.laptop]: {
                ...theme.siteVariables.textStyles.Heading.Roman55,
                textTransform: 'uppercase',
                fontSize: pxToRem(70),
                marginBottom: pxToRem(240),
            },
        },
    };
};

export const HeaderVariables = (color?: EcommerceHeadingColor) => {
    const theme = useTheme();
    const fontColor: FontColor = {
        white: theme.siteVariables.colors.white['100A']!,
        black: theme.siteVariables.colors.charcoal['250']!,
        gray: '#E8E9EB', // TODO: replace with theme color once implemented
    };
    return fontColor[color || 'black'];
};

import {
    useAppDisplayModeContext,
    Header,
    Image,
    useStyles,
    pxToRem,
} from '@archipro-design/aria';
import * as S from './RichTextModule.style';
import React from 'react';
import type { RichTextModuleProps } from '@modules/elemental-page/type';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

const RichTextModule = ({
    title,
    content,
    cta,
    image,
    anchor,
}: RichTextModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    const imageSrc =
        mobile && image?.mobileSrc ? image.mobileSrc : image?.desktopSrc;

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                {title && (
                    <Header
                        as={'h3'}
                        variables={(siteVars) => ({
                            fontSize: mobile ? pxToRem(20) : pxToRem(22),
                            ...siteVars?.textStyles.Heading.Medium65,
                            letterSpacing: '0.2em',
                        })}
                        style={{
                            textTransform: 'uppercase',
                            marginTop: 0,
                            marginBottom: mobile ? pxToRem(34) : pxToRem(66),
                        }}
                    >
                        {title}
                    </Header>
                )}

                {content && (
                    <div
                        className={css(S.RichContent)}
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    />
                )}
                <AboutCallToAction {...cta} buttonTheme={'dark'} />
                {imageSrc && (
                    <div className={css(S.FooterImage)}>
                        <Image
                            src={imageSrc}
                            width={mobile ? 378 : 1000}
                            height={mobile ? 189 : 500}
                            layout={'fill'}
                            objectFit={'cover'}
                            alt={''}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RichTextModule;

import {
    Image,
    Caption,
    Flex,
    Subheader,
    useStyles,
} from '@archipro-design/aria';
import * as S from './GuestHomeHeroModule.style';

import { type GuestHeroSlide as GuestHeroSlideType } from '@modules/elemental-page/type';
import AboutCallToAction from '@modules/elemental-page/component/about-cta/AboutCallToAction';

interface GuestHeroSlideProps {
    item: GuestHeroSlideType;
    darkTheme: boolean;
}

const GuestHeroSlide = ({ item, darkTheme }: GuestHeroSlideProps) => {
    const { css } = useStyles({ darkTheme });

    return (
        <div className={css(S.SlideContainer)}>
            <Image
                key={item.id}
                width={1920}
                height={855}
                layout={'fill'}
                objectFit={'cover'}
                src={item.backgroundImage?.desktopSrc || ''}
                alt={'img-alt'}
            />
            <div className={css(S.SlideContent)}>
                <Subheader className={css(S.Title)}>{item.title}</Subheader>
                {item.content && (
                    <Caption className={css(S.Content)}>{item.content}</Caption>
                )}
                <Flex className={css(S.SlideActions)}>
                    {item.primaryCta && (
                        <AboutCallToAction
                            {...item.primaryCta}
                            buttonTheme={'dark'}
                        />
                    )}
                    {item.secondaryCta && (
                        <AboutCallToAction
                            {...item.secondaryCta}
                            buttonTheme={'light'}
                        />
                    )}
                </Flex>
            </div>
        </div>
    );
};

export default GuestHeroSlide;

import {
    useStyles,
    Image,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import {
    BannerImageStyling,
    BannerImageMobileStyling,
} from './BannerModule.style';
import type { BannerModuleProps } from '@modules/elemental-page/type';

const BannerModule = ({ image, anchor }: BannerModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();
    return (
        <>
            {image?.desktopSrc && !mobile && (
                <div className={css(BannerImageStyling)} id={anchor}>
                    <Image
                        src={image.desktopSrc}
                        layout={'responsive'}
                        width={1920}
                        height={1096}
                        {...getCustomPlaceholderColor('light')}
                        alt={'Editing Product Overview'}
                    />
                </div>
            )}
            {image?.mobileSrc && mobile && (
                <div className={css(BannerImageMobileStyling)} id={anchor}>
                    <Image
                        src={image.mobileSrc}
                        layout={'responsive'}
                        width={414}
                        height={325}
                        {...getCustomPlaceholderColor('light')}
                        alt={'Editing Product Overview'}
                    />
                </div>
            )}
        </>
    );
};

export default BannerModule;

import type { StyleRule } from '@archipro-design/aria';
import { captionClassName, pxArrayToRem, pxToRem } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const GuestHomeHeroModule: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    position: 'relative',
    height: pxToRem(752),
    overflow: 'hidden',
    [`& .swiper-pagination`]: {
        [`& .swiper-pagination-bullet`]: {
            height: pxToRem(6),
            width: pxToRem(6),
            backgroundColor: '#BBB',
            [`&-active`]: {
                backgroundColor: guestHomeTheme(theme, darkTheme).main.text2,
            },
        },
    },
    [theme.screen.laptop]: {
        height: pxToRem(855),
        [`& .swiper-pagination`]: {
            transform: `translateY(${pxToRem(-360)})`,
            [`& .swiper-pagination-bullet`]: {
                height: pxToRem(12),
                width: pxToRem(12),
            },
        },
    },
});

export const SlideContainer: StyleRule = ({ theme }) => ({
    height: pxToRem(752),
    [`& .rfm-marquee-container`]: {
        // for vertical marquee set width as height
        // https://www.react-fast-marquee.com/documentation#direction
        width: pxToRem(631),
        [`& .rfm-child`]: {
            width: pxToRem(631),
        },
    },
    [theme.screen.laptop]: {
        width: '100%',
        height: pxToRem(855),
    },
});

export const Overlay: StyleRule = () => ({
    background: 'transparent',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
});

export const ImageContainer: StyleRule = () => ({
    width: pxToRem(414),
    height: pxToRem(631),
    position: 'relative',
});

export const SlideContent: StyleRule = ({ theme }) => ({
    zIndex: 1,
    width: '100%',
    height: 'auto',
    bottom: 0,
    left: 0,
    position: 'absolute',
    background: `linear-gradient(to bottom, transparent 0%, #F2F2F2 ${pxToRem(
        120
    )}, #F2F2F2 ${pxToRem(130)})`,
    padding: pxArrayToRem([80, 18, 55, 18]),
    textAlign: 'center',

    [theme.screen.laptop]: {
        position: 'relative',
        width: '100%',
        height: pxToRem(508),
        maxWidth: pxToRem(1200),
        margin: '0 auto',
        paddingTop: pxToRem(63),
        paddingBottom: pxToRem(63),
        background: 'transparent',
    },
});

export const HeroWelcomeSection: StyleRule = () => ({
    textAlign: 'center',
    marginBottom: pxToRem(26),
});

export const WelcomeMessage: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Heading.Medium65,
    color: theme.siteVariables.colors.primitive.black,
    textTransform: 'uppercase',
    marginBottom: pxToRem(12),
    [theme.screen.max.laptop]: {
        fontSize: theme.siteVariables.fontSizes.caption03s,
    },
});

export const Title: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Heading.Medium65,
    color: theme.siteVariables.colors.primitive.black,
    textTransform: 'uppercase',
    margin: 'auto',
    marginBottom: pxToRem(25),
    maxWidth: pxToRem(250),
    [theme.screen.max.laptop]: {
        fontSize: theme.siteVariables.fontSizes.caption03s,
    },
    [theme.screen.laptop]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        letterSpacing: pxToRem(-0.1),
        fontSize: pxToRem(62),
        maxWidth: pxToRem(850),
        height: pxToRem(124),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: pxToRem(39),
    },
});

export const Content: StyleRule = ({ theme }) => ({
    fontSize: theme.siteVariables.fontSizes.caption03s,
    lineHeight: '150%',
    marginBottom: pxToRem(29),
    maxHeight: pxToRem(180),
    overflow: 'hidden',
    [theme.screen.max.laptop]: {
        fontSize: theme.siteVariables.fontSizes.caption03s,
        lineHeight: '150%',
    },
    [theme.screen.laptop]: {
        fontSize: theme.siteVariables.fontSizes.caption01,
        lineHeight: '140%',
        marginBottom: pxToRem(38),
        maxHeight: pxToRem(84),
    },
});

export const SlideActions: StyleRule = ({ theme }) => ({
    gap: pxToRem(12),
    [`& a`]: {
        marginTop: 0,
        padding: pxArrayToRem([23, 40]),
        width: pxToRem(282),
        margin: 'auto',
        [`&:first-of-type`]: {
            backgroundColor: theme.siteVariables.colors.charcoal['250'],
        },
        [`&:last-of-type`]: {
            backgroundColor: '#dcdcdc',
        },
        [`& .${svgIconClassName}`]: {
            display: 'none',
        },
        [`&:hover`]: {
            [`&:first-of-type`]: {
                backgroundColor: theme.siteVariables.colors.charcoal['260'],
            },
            [`&:last-of-type`]: {
                backgroundColor: theme.siteVariables.colors.charcoal['250'],
                [`& .${captionClassName}`]: {
                    color: theme.siteVariables.colors.primitive.white,
                },
            },
        },
    },
    [theme.screen.max.laptop]: {
        [`& a`]: {
            padding: pxArrayToRem([24, 32]),
            [`& .${captionClassName}`]: {
                ...theme.siteVariables.textStyles.GTAmericaTrialFont.Bold75,
                fontSize: theme.siteVariables.fontSizes.caption03s,
                textTransform: 'uppercase',
            },
        },
    },
    [theme.screen.laptop]: {
        justifyContent: 'center',
        [`& a`]: {
            width: 'auto',
            margin: 0,
            [`&:last-of-type`]: {
                backgroundColor: theme.siteVariables.colors.primitive.white,
                border: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,
            },
            [`& .${captionClassName}`]: {
                ...theme.siteVariables.textStyles.GTAmericaTrialFont.Bold75,
                fontSize: theme.siteVariables.fontSizes.caption03s,
                textTransform: 'uppercase',
            },
        },
    },
});

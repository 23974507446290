import { Flex, useStyles } from '@archipro-design/aria';
import { useUser } from '@modules/user';
import { useLocation } from '@remix-run/react';
import * as S from './DemoLink.style';
import { useElementalPage } from '../../context/ElementalPageContext';
import EcommerceLink from '../ecommerce-link/EcommerceLink';
import { useTracker } from '@archipro-website/tracker';
import type { CallToAction } from '@modules/elemental-page/type';

const DemoLink = (cta: CallToAction) => {
    const user = useUser();
    const location = useLocation();
    const { css } = useStyles();
    const { log } = useTracker();

    const { dispatch } = useElementalPage();
    const onClick = () => {
        log('EcomLandingPage_BookingReveal', {
            url: new URL(window.location.href),
        });

        if (cta.anchor) {
            const anchorElement = document.getElementById(cta.anchor);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            dispatch({ type: 'toggleModal' });
        }
    };

    return (
        <Flex
            style={{ flexDirection: 'column' }}
            className={css(S.EcomDemoButtonsSection)}
        >
            <div>
                <EcommerceLink {...cta} onClick={onClick} />
            </div>
            {user.__typename !== 'Me' && (
                <a
                    className={css(S.SignInButton)}
                    href={`/login?redirect=${encodeURIComponent(
                        `${location.pathname}?openModal`
                    )}`}
                    onClick={() => {
                        log('EcomLandingPage_SignIn', {
                            url: new URL(window.location.href),
                        });
                    }}
                >
                    Or Sign in
                </a>
            )}
        </Flex>
    );
};

export default DemoLink;

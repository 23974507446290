import { Image, Paragraph, useStyles } from '@archipro-design/aria';
import * as S from '../product-editing-module/Block.style';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';
import type { BusinessTextBlock } from '@modules/elemental-page/type';

const Block = ({ title, summary, image }: BusinessTextBlock) => {
    const { css } = useStyles();

    return (
        <div className={css(S.Container)}>
            {summary && (
                <div className={css(S.DesktopParagraphContainer)}>
                    <Paragraph className={css(S.DesktopParagraph)}>
                        {summary}
                    </Paragraph>
                </div>
            )}
            {image?.desktopSrc && (
                <div className={css(S.DesktopImageStyle)}>
                    <Image
                        src={image.desktopSrc}
                        sizes={getImagesSizes(898)}
                        height={572}
                        width={898}
                        {...getCustomPlaceholderColor('dark')}
                        layout={'responsive'}
                        alt={title || summary || ''}
                    />
                </div>
            )}
            {image?.mobileSrc && (
                <div className={css(S.MobileImageStyle)}>
                    <Image
                        src={image.mobileSrc}
                        height={244}
                        width={383}
                        {...getCustomPlaceholderColor('dark')}
                        layout={'responsive'}
                        sizes={getImagesSizes(898)}
                        alt={title || summary || ''}
                    />
                </div>
            )}
            {summary && (
                <div className={css(S.MobileParagraphContainer)}>
                    <Paragraph>{summary}</Paragraph>
                </div>
            )}
        </div>
    );
};

export default Block;

import type { StyleRule } from '@archipro-design/aria';
import { paragraphClassName, pxToRem } from '@archipro-design/aria';

export const Container: StyleRule = ({ theme }) => {
    return {
        paddingTop: pxToRem(111),
        paddingBottom: pxToRem(104),
        [theme.screen.laptop]: {
            paddingTop: pxToRem(243),
            paddingBottom: pxToRem(217),
        },
        '& p': {
            margin: '0',
            '& span': {
                textDecorationThickness: '1px !important',
                textUnderlineOffset: `${pxToRem(4)} !important`,
                [theme.screen.laptop]: {
                    textDecorationThickness: '2px !important',
                    textUnderlineOffset: `${pxToRem(9)} !important`,
                },
            },
        },
    };
};

export const CustomHeading: StyleRule = ({ theme }) => {
    return {
        [theme.screen.laptop]: {
            marginBottom: pxToRem(222),
        },
    };
};

export const BlockContainer: StyleRule = ({ theme }) => {
    return {
        gap: theme.siteVariables.spaces['space-80'],
        flexDirection: 'column',
        marginTop: pxToRem(92),
        [theme.screen.laptop]: {
            gap: theme.siteVariables.spaces['space-28'],
            flexDirection: 'row',
            marginTop: pxToRem(240),
        },
    };
};

export const BlocksContainer: StyleRule = ({ theme }) => {
    return {
        display: 'grid',
        rowGap: pxToRem(82),
        [theme.screen.laptop]: {
            [`& .${paragraphClassName}`]: {
                whiteSpace: 'unset',
                ...theme.siteVariables.textStyles.Heading.Roman55,
            },
            gridTemplateColumns: '1fr 1fr',
            columnGap: pxToRem(25),
            rowGap: pxToRem(170),
        },
        marginTop: pxToRem(80),
    };
};

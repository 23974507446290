import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import type { ThemedElementColourType } from '@modules/elemental-page/type';
import * as S from './GuestHomeHeroModule.style';

import type { GuestHomeHeroModuleProps } from '@modules/elemental-page/type';
import GuestHeroSlide from '@modules/elemental-page/component/guest-home-hero-module/GuestHeroSlide';
import GuestHeroSlideMobile from '@modules/elemental-page/component/guest-home-hero-module/GuestHeroSlideMobile';
import ModuleSlider from '@modules/elemental-page/component/module-slider/ModuleSlider';

interface ThemedGuestHomeHeroModuleProps extends GuestHomeHeroModuleProps {
    id: string;
    theme: ThemedElementColourType;
}

const GuestHomeHeroModule = ({
    id,
    theme,
    showWelcomeMessage,
    slides,
}: ThemedGuestHomeHeroModuleProps) => {
    const darkTheme = theme === 'dark';
    const { desktop } = useAppDisplayModeContext();
    const { css } = useStyles({ darkTheme });

    const swiperSlides = slides.map((slide) => (
        <>
            {desktop ? (
                <GuestHeroSlide item={slide} darkTheme={darkTheme} />
            ) : (
                <GuestHeroSlideMobile
                    item={slide}
                    showWelcomeMessage={showWelcomeMessage}
                    darkTheme={darkTheme}
                />
            )}
        </>
    ));

    return (
        <div className={css(S.GuestHomeHeroModule)}>
            <ModuleSlider
                moduleId={id}
                slides={swiperSlides}
                darkTheme={darkTheme}
                slidesPerView={1}
                navigationType={'pagination'}
            />
        </div>
    );
};

export default GuestHomeHeroModule;

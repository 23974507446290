import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

interface EcommerceHeadingColor {
    color: 'white' | 'black';
}

export const LinkStyle: StyleRule<EcommerceHeadingColor> = ({
    theme,
    color,
}) => {
    const colorFont =
        color === 'black'
            ? theme.siteVariables.colors.charcoal['250']
            : theme.siteVariables.colors.white['300'];

    const colorFontMobile =
        color === 'black'
            ? theme.siteVariables.colors.charcoal['250']
            : theme.siteVariables.colors.primitive.white;
    return {
        paddingTop: 0,
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: theme.siteVariables.fontSizes.label02,
        lineHeight: theme.siteVariables.lineHeightSmall,
        textDecoration: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderBottom: `${pxToRem(2)} solid ${colorFontMobile}`,
        cursor: 'pointer',
        paddingBottom: pxToRem(5),
        color: colorFontMobile,

        [theme.screen.laptop]: {
            fontSize: pxToRem(39),
            lineHeight: pxToRem(54),
            borderBottom: `${pxToRem(2)} solid transparent`,
            transition: 'border 0.3s ease', // TODO: create transition value in theme
            paddingLeft: pxToRem(1),
            color: colorFont,

            ':hover': {
                borderColor: colorFont,
            },
        },
    };
};

export const LinkIcon: StyleRule<EcommerceHeadingColor> = ({
    theme,
    color,
}) => {
    return {
        height: `${pxToRem(15)} !important`, // overwrite aria height
        width: `${pxToRem(16)} !important`, // overwrite aria width
        marginLeft: pxToRem(10),
        marginBottom: pxToRem(3), // pixel perfect
        marginTop: pxToRem(2),
        '> svg': {
            height: '100%',
            width: '100%',
            '> path': {
                fill:
                    color === 'black'
                        ? theme.siteVariables.colors.charcoal['250']
                        : theme.siteVariables.colors.primitive.white,
            },
        },
        [theme.screen.laptop]: {
            height: `${pxToRem(29)} !important`, // overwrite aria height
            width: `${pxToRem(33)} !important`, // overwrite aria width
            marginLeft: pxToRem(23),
            marginTop: pxToRem(5),

            ':hover': {
                borderColor:
                    color === 'black'
                        ? theme.siteVariables.colors.charcoal['250']
                        : theme.siteVariables.colors.primitive.white,
            },

            '> svg': {
                height: '100%',
                width: '100%',
                '> path': {
                    fill:
                        color === 'black'
                            ? theme.siteVariables.colors.charcoal['250']
                            : theme.siteVariables.colors.white['300'],
                },
            },
        },
    };
};

export const DesktopButton: StyleRule = ({ theme }) => {
    return {
        display: 'none',
        [theme.screen.laptop]: {
            display: 'inline-flex',
            marginBottom: pxToRem(16),
        },
    };
};

export const MobileButton: StyleRule = ({ theme }) => {
    return {
        display: 'inline-flex',
        marginBottom: pxToRem(21),
        [theme.screen.laptop]: {
            display: 'none',
        },
    };
};

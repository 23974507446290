import {
    Header,
    Grid,
    useAppDisplayModeContext,
    pxToRem,
    useStyles,
} from '@archipro-design/aria';
import * as S from './DirectoryModule.style';
import type { DirectoryModuleProps } from '@modules/elemental-page/type';
import SectionBlock from '@modules/elemental-page/component/directory-module/SectionBlock';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { CustomArrowLeft, CustomArrowRight } from '@archipro-design/icons';
import React from 'react';

const DirectoryModule = ({
    title,
    anchor,
    sectionBlocks,
}: DirectoryModuleProps) => {
    const { css } = useStyles();
    const { mobile } = useAppDisplayModeContext();

    return (
        <div id={anchor} className={css(S.Module)}>
            <div className={css(S.Container)}>
                {title && (
                    <Header
                        as={'h3'}
                        variables={(siteVars) => ({
                            fontSize: mobile ? pxToRem(20) : pxToRem(22),
                            ...siteVars?.textStyles.Heading.Medium65,
                            letterSpacing: '0.2em',
                        })}
                        style={{
                            textTransform: 'uppercase',
                            marginTop: 0,
                            marginBottom: mobile ? pxToRem(34) : pxToRem(66),
                        }}
                    >
                        {title}
                    </Header>
                )}

                {sectionBlocks && !!sectionBlocks.length && (
                    <>
                        {mobile ? (
                            <>
                                <Swiper
                                    slidesPerView={1}
                                    navigation={{
                                        nextEl: `.directory-slider-button-next`,
                                        prevEl: `.directory-slider-button-prev`,
                                    }}
                                    allowSlideNext
                                    allowSlidePrev
                                    allowTouchMove
                                    className={css(S.Slider)}
                                >
                                    {sectionBlocks.map((block, i) => (
                                        <SwiperSlide
                                            className={css(S.Slide)}
                                            key={`milestone-slide-${i}`}
                                        >
                                            <SectionBlock
                                                key={`directory-section-block-${block.id}`}
                                                {...block}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className={css(S.SliderActions)}>
                                    <button
                                        className={classnames(
                                            css(S.NavigateButton),
                                            'directory-slider-button-prev'
                                        )}
                                    >
                                        <CustomArrowLeft />
                                    </button>
                                    <button
                                        className={classnames(
                                            css(S.NavigateButton),
                                            'directory-slider-button-next'
                                        )}
                                    >
                                        <CustomArrowRight />
                                    </button>
                                </div>
                            </>
                        ) : (
                            <Grid columns={3} className={css(S.SectionGrid)}>
                                {sectionBlocks.map((block) => (
                                    <SectionBlock
                                        key={`directory-section-block-${block.id}`}
                                        {...block}
                                    />
                                ))}
                            </Grid>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default DirectoryModule;

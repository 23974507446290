import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import type { AboutButtonTheme } from '@modules/elemental-page/component/about-cta/AboutCallToAction';

export const Link: StyleRule<{ buttonTheme: AboutButtonTheme }> = ({
    theme,
    buttonTheme,
}) => {
    return {
        display: 'inline-flex',
        padding: pxArrayToRem([12, 23]),
        justifyContent: 'center',
        alignItems: 'center',
        gap: pxToRem(8),
        marginTop: pxToRem(60),
        backgroundColor:
            buttonTheme === 'dark'
                ? theme.siteVariables.colors.primitive.black
                : theme.siteVariables.colors.primitive.white,
    };
};

export const LinkText: StyleRule = ({ theme }) => {
    return {
        ...theme.siteVariables.textStyles.Heading.Roman55,
        textTransform: 'uppercase',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    };
};
export const LinkArrow: StyleRule<{ buttonTheme: AboutButtonTheme }> = ({
    theme,
    buttonTheme,
}) => {
    return {
        fill:
            buttonTheme === 'dark'
                ? theme.siteVariables.colors.primitive.white
                : theme.siteVariables.colors.primitive.black,
        [`& svg path`]: {
            fill:
                buttonTheme === 'dark'
                    ? theme.siteVariables.colors.primitive.white
                    : theme.siteVariables.colors.primitive.black,
        },
    };
};

import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    SubheaderClassName,
    captionClassName,
} from '@archipro-design/aria';
import { guestHomeTheme } from '@modules/home/util/guest-home-theme';

export const MilestoneModule: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => ({
    position: 'relative',
    backgroundColor: guestHomeTheme(theme, darkTheme).main.background,
    padding: pxArrayToRem([0, 18]),
    [theme.screen.laptop]: {
        padding: pxArrayToRem([0, 80]),
    },
});

export const Title: StyleRule<{ darkTheme: boolean }> = ({
    theme,
    darkTheme,
}) => {
    return {
        textTransform: 'uppercase',
        textAlign: 'center',
        margin: 'auto',
        ...theme.siteVariables.textStyles.Heading.Medium65,
        fontSize: pxToRem(20),
        color: guestHomeTheme(theme, darkTheme).main.text3,
        lineHeight: '113%',
        marginBottom: pxToRem(49),
        [theme.screen.max.laptop]: {
            ...theme.siteVariables.textStyles.Heading.Medium65,
            fontSize: pxToRem(20),
            lineHeight: '113%',
        },
        [theme.screen.laptop]: {
            maxWidth: pxToRem(1000),
            marginBottom: pxToRem(75),
            fontSize: theme.siteVariables.fontSizes.heading05,
            color: guestHomeTheme(theme, darkTheme).main.text2,
        },
    };
};

export const Milestone: StyleRule = ({ theme }) => {
    return {
        width: '100%',
        textAlign: 'center',
        marginBottom: pxToRem(37),
        [`& .${SubheaderClassName}`]: {
            ...theme.siteVariables.textStyles.Display.Medium65,
            fontSize: pxToRem(50),
            textTransform: 'uppercase',
            marginBottom: pxToRem(3),
        },
        [`& .${captionClassName}`]: {
            ...theme.siteVariables.textStyles.Text.Roman55,
            fontSize: theme.siteVariables.fontSizes.caption03s,
            lineHeight: '150%',
        },

        [theme.screen.laptop]: {
            textAlign: 'left',
            [`& .${SubheaderClassName}`]: {
                fontSize: pxToRem(110),
                marginBottom: pxToRem(11),
            },
            [`& .${captionClassName}`]: {
                fontSize: theme.siteVariables.fontSizes.subheading03,
            },
        },
    };
};

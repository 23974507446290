import { pxToRem } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import { imageClassName, videoPlayerClassName } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

const DesktopVideoMaxWidth = pxToRem(1512);
export const BaseSection: StyleRule = ({ theme }) => {
    return {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: pxToRem(100),
        paddingBottom: 0,
        [theme.screen.laptop]: {
            paddingTop: pxToRem(172),
            paddingBottom: pxToRem(240),
        },
    };
};

export const ModuleBackgroundContainer = () => {
    return {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };
};

export const DesktopBackground: StyleRule = ({ theme }) => {
    return {
        display: 'none',
        [theme.screen.tablet]: {
            display: 'block',
        },
    };
};

export const MobileBackground: StyleRule = ({ theme }) => {
    return {
        [theme.screen.tablet]: {
            display: 'none',
        },
    };
};

export const ModuleContentContainer = () => {
    return {
        zIndex: 1,
    };
};

const customContentFontColour = '#D2D2D2'; //figma colour not found in aria-Colours
const customHeadingFontColour = '#E8E9EB'; //figma colour not found in aria-Colours

export const ModuleHeader: StyleRule = ({ theme }) => {
    return {
        whiteSpace: 'break-spaces',
        color: customContentFontColour,
        marginBottom: pxToRem(18),
        fontSize: pxToRem(56),
        lineHeight: theme.siteVariables.lineHeightDefault,
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        [theme.screen.laptop]: {
            color: customHeadingFontColour,
            marginBottom: pxToRem(104),
            padding: 0,
            fontSize: theme.siteVariables.fontSizes.heading01,
        },
    };
};

export const ModuleContent: StyleRule = ({ theme }) => {
    return {
        margin: 0,
        color: customContentFontColour,
        marginBottom: pxToRem(89),
        fontSize: theme.siteVariables.fontSizes.caption01,
        lineHeight: theme.siteVariables.lineHeightSmall,
        paddingLeft: 0,
        [theme.screen.laptop]: {
            flexBasis: '50%',
            marginBottom: pxToRem(166),
            fontSize: pxToRem(40),
            lineHeight: theme.siteVariables.lineHeightSmaller,
            paddingLeft: pxToRem(8),
        },
    };
};

export const VideoContainer: StyleRule = ({ theme }) => {
    return {
        width: '100vw',
        [`& .${imageClassName}`]: {
            width: '100%',
            height: '100%',
        },
        [`& .${svgIconClassName} svg`]: {
            height: pxToRem(34),
            width: pxToRem(34),
        },
        [theme.screen.laptop]: {
            maxWidth: DesktopVideoMaxWidth,
            marginLeft: 'auto',
            marginRight: 0,
            [`& .${imageClassName}`]: {
                maxWidth: DesktopVideoMaxWidth,
                height: '100%',
            },
            [`& .${svgIconClassName} svg`]: {
                height: pxToRem(190),
                width: pxToRem(190),
                marginLeft: pxToRem(50),
            },
        },
    };
};

export const AspectRatioBox = () => {
    return {
        paddingTop: '56.25%',
        position: 'relative',
        [`& .${videoPlayerClassName}`]: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
        },
    };
};

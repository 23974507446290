import { pxToRem } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

interface HeightProp {
    desktop: number;
    mobile: number;
}

export const ECOMMERCE_LOGO_HEIGHT = {
    default: {
        desktop: 111,
        mobile: 53,
    },
    small: {
        desktop: 84,
        mobile: 41,
    },
};

export const MarqueeWrapper: StyleRule<{ height: HeightProp }> = ({
    theme,
    height,
}) => {
    return {
        height: pxToRem(height.mobile),
        overflow: 'hidden',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        [theme.screen.laptop]: {
            height: pxToRem(height.desktop),
        },
    };
};

export const LogoContainer: StyleRule<{ height: HeightProp }> = ({
    theme,
    height,
}) => {
    return {
        display: 'inline-block',
        marginRight: pxToRem(45),
        height: pxToRem(height.mobile),

        '& img': {
            height: '100%',
            width: 'auto',
        },

        [theme.screen.laptop]: {
            height: pxToRem(height.desktop),
            marginRight: pxToRem(87),
        },
    };
};

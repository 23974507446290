import * as S from './FeatureModule.style';
import {
    Image,
    useStyles,
    Flex,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import BaseSection from '../base-section/BaseSection';
import EcommerceHeading from '../ecommerce-heading/EcommerceHeading';
import { AcceptIcon } from '@fluentui/react-icons-northstar';
import type {
    ThemedElementColourType,
    FeaturesModuleProps,
} from '@modules/elemental-page/type';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getCustomPlaceholderColor } from '../../util/get-custom-placeholder-color';

interface ThemedFeaturesModuleProps extends FeaturesModuleProps {
    theme?: ThemedElementColourType;
}

const FeaturesModule = ({
    title,
    primaryImage,
    secondaryImage,
    features,
    theme,
    anchor,
}: ThemedFeaturesModuleProps) => {
    const { css } = useStyles({ theme });
    const { mobile } = useAppDisplayModeContext();

    return (
        <BaseSection background={theme} style={S.BaseSection} id={anchor}>
            {!!title && (
                <EcommerceHeading
                    title={title}
                    color={'white'}
                    style={S.ModuleHeading}
                />
            )}

            {primaryImage?.desktopSrc &&
                (mobile ? (
                    <div className={css(S.PrimaryImageMobileContainer)}>
                        <Image
                            src={
                                primaryImage.mobileSrc ??
                                primaryImage.desktopSrc
                            }
                            width={382}
                            height={331}
                            layout="responsive"
                            {...getCustomPlaceholderColor('light')}
                            sizes={getImagesSizes(1417)}
                            alt={'Products directory overview'}
                        />
                    </div>
                ) : (
                    <div className={css(S.PrimaryImageDesktopContainer)}>
                        <Image
                            src={primaryImage.desktopSrc}
                            width={1417}
                            height={1229}
                            layout="responsive"
                            {...getCustomPlaceholderColor('light')}
                            sizes={getImagesSizes(1417)}
                            alt={'Products directory overview'}
                        />
                    </div>
                ))}
            <Flex
                fill={true}
                className={css(S.FeatureListSection)}
                hAlign={'start'}
            >
                <ul className={css(S.FeatureList)}>
                    {features &&
                        features.length > 0 &&
                        features.map((feature, index) => {
                            return (
                                <li
                                    className={css(S.FeatureListItem)}
                                    key={index}
                                >
                                    <AcceptIcon className={css(S.TickIcon)} />
                                    {feature.title}
                                </li>
                            );
                        })}
                </ul>
                {secondaryImage?.desktopSrc && !mobile && (
                    <div className={css(S.SecondaryImageContainer)}>
                        <Image
                            src={secondaryImage.desktopSrc}
                            width={373}
                            height={820}
                            className={css(S.SecondaryImage)}
                            {...getCustomPlaceholderColor('light')}
                            sizes="20vw"
                            alt={'Checkout Overview'}
                        />
                    </div>
                )}
            </Flex>
        </BaseSection>
    );
};

export default FeaturesModule;
